import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ModalType {
    AddNewUserModal = 'AddNewUserModal',
}

export interface ModalState {
    type: ModalType;
}

const initialState: ModalState[] = [];

export const modals = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        openModal(state: ModalState[], action: PayloadAction<ModalType>) {
            const type = action.payload;
            const modalState = state.find(m => m.type === type);
            return modalState ? state : [...state, { type }];
        },
        closeModal(state: ModalState[], action: PayloadAction<ModalType>) {
            const type = action.payload;
            return state.filter(m => m.type !== type);
        },
    },
    extraReducers: {},
});

export const { openModal, closeModal } = modals.actions;

export default modals.reducer;
