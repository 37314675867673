import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useEffect } from 'react';

interface AutoSubmitProps {
    waitTime?: number;
}

export const AutoSubmit: React.FC<AutoSubmitProps> = props => {
    const { waitTime = 300 } = props;
    const { submitForm, values } = useFormikContext();
    const debouncedSubmit = useCallback(
        debounce(() => submitForm(), waitTime),
        [waitTime, submitForm]
    );

    useEffect(() => {
        debouncedSubmit();
    }, [debouncedSubmit, values]);

    return null;
};
