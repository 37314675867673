import { searchTextToBase64 } from 'utils/helpers';
import { api } from './shared/instance';
import {
    Order,
    OrderAutocompleteType,
    OrdersRequestInput,
    OrdersResponse,
    QualityControl,
    WidgetsDates,
    OpenOrder,
    QualityControlResults,
    OnTimeDelivery,
} from './shared/types';

export interface QualityControlInput {
    qualityProblem: boolean;
    sortNeed: boolean;
    itemsChecked: number;
    itemsRejected: number;
}

const baseUrl = 'QualityControl';

export const getOrdersRequest = async (input: OrdersRequestInput) => {
    if ('cardCode' in input) {
        const { cardCode, searchText, ...rest } = input;
        const searchTextBase64 = searchTextToBase64(searchText);

        return api.get<OrdersResponse>(`${baseUrl}/${cardCode}/Order`, {
            params: {
                ...rest,
                ...searchTextBase64,
            },
        });
    }

    throw new Error('No cardCode in input params');
};

export const downloadQualityControlFile = async (fileId: number, qualityControlId: number) => {
    return api.get<Blob>(`${baseUrl}/${qualityControlId}/File/${fileId}`, { responseType: 'blob' });
};

export const createQualityControlRequest = async (formData: FormData) => {
    return api.post<QualityControl>(`${baseUrl}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const getOrderRequest = async (orderId: number) => {
    return api.get<Order>(`${baseUrl}/Order/${orderId}`);
};

export const editQualityControlRequest = async (
    input: QualityControlInput,
    qualityControlId: number
) => {
    return api.patch(`${baseUrl}/${qualityControlId}`, {
        ...input,
    });
};

export const deleteQualityControlFile = async (fileId: number, qualityControlId: number) => {
    return api.delete(`${baseUrl}/${qualityControlId}/File/${fileId}`);
};

export const addQualityControlFiles = async (formDataFiles: FormData, qualityControlId: number) => {
    return api.post(`${baseUrl}/${qualityControlId}/File`, formDataFiles, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const getAutocompleteResultsRequest = async (
    searchQuery: string,
    autocompleteType: OrderAutocompleteType,
    cardCode: string
) => {
    return api.get<string[]>(`${baseUrl}/${cardCode}/Order/Autocomplete`, {
        params: {
            searchQuery,
            autocompleteType,
        },
    });
};

export const getOpenOrderRequest = async (dates: WidgetsDates) => {
    return api.get<OpenOrder>(`${baseUrl}/Order/Open`, {
        params: {
            ...dates,
        },
    });
};

export const getQualityControlResultsRequest = async (dates: WidgetsDates) => {
    return api.get<QualityControlResults>(`${baseUrl}/Order/QualityControlResults`, {
        params: {
            ...dates,
        },
    });
};

export const getOnTimeDeliveryRequest = async (dates: WidgetsDates) => {
    return api.get<OnTimeDelivery>(`${baseUrl}/Order/OnTimeDelivery`, {
        params: {
            ...dates,
        },
    });
};

export const exportToExcelRequest = async (input: OrdersRequestInput) => {
    if (!('cardCode' in input)) {
        throw new Error('No cardCode in params');
    }
    return api.get<Blob>(`${baseUrl}/${input.cardCode}/Order/Export`, {
        responseType: 'blob',
        params: {
            ...input,
        },
    });
};
