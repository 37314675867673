import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Auth } from 'api/auth';
import { AppError, BasicStateType } from 'store/types';
import { changePassword, login, refreshToken, resetPassword } from './operations';

export type AuthState = BasicStateType<Auth>;

const initialState: AuthState = {
    entities: null,
    error: null,
    loading: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
         logout: (state: AuthState) => {
            state.entities = null,
            state.error = null,
            state.loading = false
        }
    },
    extraReducers: {
        [login.pending.type]: (state: AuthState) => {
            state.entities = null;
            state.loading = true;
            state.error = null;
        },
        [login.fulfilled.type]: (state: AuthState, action: PayloadAction<Auth>) => {
            state.entities = action.payload;
            state.loading = false;
            state.error = null;
        },
        [login.rejected.type]: (state: AuthState, action: PayloadAction<AppError>) => {
            state.entities = null;
            state.loading = false;
            state.error = action.payload;
        },

        [changePassword.pending.type]: (state: AuthState) => {
            state.loading = true;
            state.error = null;
        },
        [changePassword.fulfilled.type]: (state: AuthState) => {
            if (state.entities) {
                state.entities.firstTimeLogin = false;
            }
            state.loading = false;
            state.error = null;
        },
        [changePassword.rejected.type]: (state: AuthState, action: PayloadAction<AppError>) => {
            state.loading = false;
            state.error = action.payload;
        },

        [resetPassword.pending.type]: (state: AuthState) => {
            state.loading = true;
            state.error = null;
        },
        [resetPassword.fulfilled.type]: (state: AuthState) => {
            state.loading = false;
            state.error = null;
        },
        [resetPassword.rejected.type]: (state: AuthState, action: PayloadAction<AppError>) => {
            state.entities = null;
            state.loading = false;
            state.error = action.payload;
        },
        [refreshToken.fulfilled.type]: (state: AuthState, action: PayloadAction<Auth>) => {
            state.entities = action.payload;
            state.loading = false;
            state.error = null;
        },
    },
});
export const { logout } = authSlice.actions;
export default authSlice.reducer;
