import { OpenOrder, WidgetsDates, WidgetSearchType } from 'api/shared/types';
import { AxiosResponse } from 'axios';
import { AutoSubmit } from 'components/shared/AutoSubmit/AutoSubmit';
import { DatePicker } from 'components/shared/DatePicker/DatePicker';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { Formik } from 'formik';
import React from 'react';
import { useAsync } from 'react-async';
import { Col, Form, Row } from 'react-bootstrap';
import {
    CenterRow,
    DarkMainColorLabel,
    LightMainColorLabel,
    WidgetContainer,
} from 'styles/styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setFilters } from 'store/filters/slice';

interface OpenOrderMenuProps {
    dates: WidgetsDates;
    setOnTimeDeliveryDates: React.Dispatch<React.SetStateAction<WidgetsDates>>;
    getOpenOrderRequest: (dates: WidgetsDates) => Promise<AxiosResponse<OpenOrder, any>>;
}

export const OpenOrderMenu: React.FC<OpenOrderMenuProps> = props => {
    const { t } = useTranslation();
    const { getOpenOrderRequest, dates, setOnTimeDeliveryDates } = props;
    const dispatch = useDispatch();

    const fetchOpenOrder = useAsync({
        deferFn: ([dateFrom, dateTo]) => getOpenOrderRequest({ dateFrom, dateTo }),
    });

    const openOrder = fetchOpenOrder.data?.data;

    const handleWidgetTypeClick = (value: string, type: WidgetSearchType) => {
        dispatch(setFilters({ openOrdersWidget: { value, type } }));
    };

    return (
        <WidgetContainer>
            <SpinnerMask show={fetchOpenOrder.isPending}>
                <Formik
                    initialValues={{ ...dates }}
                    onSubmit={async ({ dateFrom, dateTo }) => {
                        fetchOpenOrder.run(dateFrom, dateTo);
                        setOnTimeDeliveryDates({ dateFrom, dateTo });
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <CenterRow className='py-4'>
                                <Col md={6}>
                                    <DatePicker name='dateFrom' showClearIcon />
                                </Col>
                                <Col md={6}>
                                    <DatePicker name='dateTo' showClearIcon />
                                </Col>
                            </CenterRow>
                            <AutoSubmit />
                        </Form>
                    )}
                </Formik>
                <Row className='py-1'>
                    <Col md={9} className='flexEnd'>
                        <DarkMainColorLabel
                            className='cursor-pointer'
                            onClick={() =>
                                handleWidgetTypeClick(
                                    t('ordersWithExceededDeadline'),
                                    WidgetSearchType.OrdersWithExceededDeadline
                                )
                            }
                        >
                            {t('ordersWithExceededDeadline')}
                        </DarkMainColorLabel>
                    </Col>
                    <Col md={3}>
                        <LightMainColorLabel className='font-24'>
                            {openOrder?.ordersExceededDeadline ?? 0}
                        </LightMainColorLabel>
                    </Col>
                </Row>
                <Row className='py-1'>
                    <Col md={9} className='flexEnd'>
                        <DarkMainColorLabel
                            className='cursor-pointer'
                            onClick={() =>
                                handleWidgetTypeClick(
                                    t('ordersReadyAfter7Days'),
                                    WidgetSearchType.OrdersReadyAfter7days
                                )
                            }
                        >
                            {t('ordersReadyAfter7Days')}
                        </DarkMainColorLabel>
                    </Col>
                    <Col md={3}>
                        <LightMainColorLabel className='font-24'>
                            {openOrder?.ordersReadyAfter7Days ?? 0}
                        </LightMainColorLabel>
                    </Col>
                </Row>
                <Row className='py-1'>
                    <Col md={9} className='flexEnd'>
                        <DarkMainColorLabel
                            className='cursor-pointer'
                            onClick={() =>
                                handleWidgetTypeClick(
                                    t('ordersReadyAfter14Days'),
                                    WidgetSearchType.OrdersReadyAfter14Days
                                )
                            }
                        >
                            {t('ordersReadyAfter14Days')}
                        </DarkMainColorLabel>
                    </Col>
                    <Col md={3}>
                        <LightMainColorLabel className='font-24'>
                            {openOrder?.ordersReadyAfter14Days ?? 0}
                        </LightMainColorLabel>
                    </Col>
                </Row>
                <Row className='py-1'>
                    <Col md={9} className='flexEnd'>
                        <DarkMainColorLabel
                            className='cursor-pointer'
                            onClick={() =>
                                handleWidgetTypeClick(
                                    t('ordersReadyAfter21Days'),
                                    WidgetSearchType.OrdersReadyAfter21days
                                )
                            }
                        >
                            {t('ordersReadyAfter21Days')}
                        </DarkMainColorLabel>
                    </Col>
                    <Col md={3}>
                        <LightMainColorLabel className='font-24'>
                            {openOrder?.ordersReadyAfter21Days ?? 0}
                        </LightMainColorLabel>
                    </Col>
                </Row>
            </SpinnerMask>
        </WidgetContainer>
    );
};
