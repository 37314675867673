import React, { useEffect, useRef } from 'react';
import { OrderComment } from 'api/shared/types';
import styled from 'styled-components';
import { getByRootItemId } from 'utils/helpers';
import { Comment } from './Comment/Comment';
import { Stack } from 'react-bootstrap';

interface CommentsListProps {
    comments: OrderComment[];
    orderId: number;
    reloadComments: () => void;
}

const StyledDiv = styled.div`
    display: flex;
    overflow: auto;
    max-height: 55vh;
    padding: 10px;
`;

export const CommentsList: React.FC<CommentsListProps> = props => {
    const { comments, orderId, reloadComments } = props;
    const rootComments = comments.filter(c => c.parentId === 0);
    const containerRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
        }
    }, [containerRef, comments]);

    return (
        <StyledDiv ref={containerRef}>
            {comments.length > 0 ? (
                <Stack className='py-2'>
                    {rootComments.map(parentComment => {
                        const childComments = getByRootItemId(comments, parentComment.id);
                        return (
                            <div key={parentComment.id} className='d-flex flex-column'>
                                <Comment
                                    key={parentComment.id}
                                    comment={parentComment}
                                    orderId={orderId}
                                    reloadComments={reloadComments}
                                />
                                {childComments.length > 0 ? (
                                    <div className='d-flex flex-column ps-5 pt-3'>
                                        {childComments.map(s => (
                                            <Comment
                                                key={s.id}
                                                comment={s}
                                                orderId={orderId}
                                                reloadComments={reloadComments}
                                            />
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </Stack>
            ) : (
                <div></div>
            )}
        </StyledDiv>
    );
};
