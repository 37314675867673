import { schema } from 'normalizr';

export const qualityControlEntity = new schema.Entity(
    'qualityControls',
    {},
    {
        processStrategy: (item, parent) => ({
            ...item,
            orderItemId: parent.id,
        }),
    }
);
export const realizationEntity = new schema.Entity(
    'realizations',
    {},
    {
        processStrategy: (item, parent) => ({
            ...item,
            orderItemId: parent.id,
        }),
    }
);

export const orderItemEntity = new schema.Entity(
    'orderItems',
    {
        qualityControls: [qualityControlEntity],
        realizations: [realizationEntity],
    },
    {
        processStrategy: (item, parent) => ({
            ...item,
            orderId: parent.id,
        }),
    }
);

export const orderEntity = new schema.Entity(
    'orders',
    {
        orderItems: [orderItemEntity],
    },
    {
        idAttribute: '_id',
    }
);
