import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOrdersRequest, getSuppliersRequest } from 'api/employee';
import { AxiosError } from 'axios';
import { prepareErrorType } from 'components/shared/LocalError/helpers';
import { AppError } from 'store/types';
import { normalize } from 'normalizr';
import { orderEntity } from '../schemas';
import { OrdersRequestInput, Supplier } from 'api/shared/types';

export const getOrders = createAsyncThunk(
    'employee/getOrders',
    async (input: OrdersRequestInput, { rejectWithValue }) => {
        try {
            const { data } = await getOrdersRequest(input);
            const normalized = normalize(
                data.items.map((e, _id) => ({ ...e, _id })),
                [orderEntity]
            );
            return {
                totalOrders: data.total,
                data: normalized.entities,
            };
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    },
    {
        condition: (
            arg: OrdersRequestInput,
            { getState, extra }: Pick<any, 'getState' | 'extra'>
        ) => {
            return 'cardCode' in arg;
        },
    }
);

export const getSuppliers = createAsyncThunk<Supplier[]>(
    'employee/getSuppliers',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await getSuppliersRequest();
            return data;
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    },
    {
        condition: (arg, { getState, extra }: Pick<any, 'getState' | 'extra'>) => {
            const suppliers = getState().employee.suppliers.entities;
            return suppliers.length === 0;
        },
    }
);
