import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    NormalizedOrderItems,
    NormalizedOrders,
    NormalizedQualityControls,
    NormalizedStateData,
} from 'store/shared/types';
import { AppError, BasicStateType } from 'store/types';
import { createQualityControl, editQualityControl, getOrders } from './operations';

export type QualityControllerState = {
    orders: BasicStateType<NormalizedOrders>;
    orderItems: BasicStateType<NormalizedOrderItems>;
    qualityControls: BasicStateType<NormalizedQualityControls>;
    totalOrders: number;
};

const initialState: QualityControllerState = {
    totalOrders: 0,
    orders: {
        entities: null,
        loading: false,
        error: null,
    },
    orderItems: {
        entities: null,
        loading: false,
        error: null,
    },
    qualityControls: {
        entities: null,
        loading: false,
        error: null,
    },
};

export const qualityControllerStateSlice = createSlice({
    name: 'qualityController',
    initialState,
    reducers: {
        addQualityControl: (state: QualityControllerState, action: PayloadAction<number>) => {
            const orderItemId = action.payload;
            const orderItem = state.orderItems.entities?.[orderItemId];
            if (!state.qualityControls.entities) {
                state.qualityControls.entities = {};
            }
            const qualityControls = state.qualityControls.entities;
            if (orderItem) {
                const addedQualityControls = Object.keys(qualityControls).filter(
                    i => parseInt(i) < 0
                );
                const newId =
                    addedQualityControls.length === 0 ? -1 : -(addedQualityControls.length + 1);
                orderItem.qualityControls.push(newId);
                qualityControls[newId] = {
                    id: newId,
                    itemsChecked: 0,
                    itemsCheckedPercent: 0,
                    itemsRejected: 0,
                    orderItemId: orderItem.id,
                    qualityControlFiles: [],
                    qualityProblem: false,
                    sortNeed: false,
                    createdAt: '',
                };
            }
        },
        removeQualityControl: (
            state: QualityControllerState,
            action: PayloadAction<{ orderItemId: number; qualityControlId: number }>
        ) => {
            const { orderItemId, qualityControlId } = action.payload;
            const qualityControls = state.qualityControls.entities;
            const orderItem = state.orderItems.entities?.[orderItemId];
            if (qualityControls && orderItem) {
                delete qualityControls[qualityControlId];
                orderItem.qualityControls = orderItem.qualityControls.filter(
                    e => e !== qualityControlId
                );
            }
        },
    },
    extraReducers: {
        [getOrders.pending.type]: (state: QualityControllerState) => {
            state.orders.loading = true;
            state.orders.error = null;
        },
        [getOrders.fulfilled.type]: (
            state: QualityControllerState,
            action: PayloadAction<{
                totalOrders: number;
                data: NormalizedStateData;
            }>
        ) => {
            const { data, totalOrders } = action.payload;
            state.totalOrders = totalOrders;
            state.orders.entities = data.orders ?? null;
            state.orderItems.entities = data.orderItems ?? null;
            state.qualityControls.entities = data.qualityControls ?? null;
            state.orders.loading = false;
            state.orders.error = null;
        },
        [getOrders.rejected.type]: (
            state: QualityControllerState,
            action: PayloadAction<AppError>
        ) => {
            state.orders.entities = {};
            state.orderItems.entities = {};
            state.qualityControls.entities = {};
            state.orders.loading = false;
            state.orders.error = action.payload;
        },

        [createQualityControl.pending.type]: (state: QualityControllerState) => {
            state.qualityControls.loading = true;
            state.qualityControls.error = null;
        },
        [createQualityControl.fulfilled.type]: (
            state: QualityControllerState,
            action: PayloadAction<{
                newQualityControls: NormalizedQualityControls;
                newOrderItems: NormalizedOrderItems;
            }>
        ) => {
            const { newQualityControls, newOrderItems } = action.payload;
            state.qualityControls.entities = {
                ...state.qualityControls.entities,
                ...newQualityControls,
            };
            state.orderItems.entities = {
                ...state.orderItems.entities,
                ...newOrderItems,
            };
            state.qualityControls.loading = false;
            state.qualityControls.error = null;
        },
        [createQualityControl.rejected.type]: (
            state: QualityControllerState,
            action: PayloadAction<AppError>
        ) => {
            state.qualityControls.loading = false;
            state.qualityControls.error = action.payload;
        },

        [editQualityControl.pending.type]: (state: QualityControllerState) => {
            state.qualityControls.loading = true;
            state.qualityControls.error = null;
        },
        [editQualityControl.fulfilled.type]: (
            state: QualityControllerState,
            action: PayloadAction<{
                newQualityControls: NormalizedQualityControls;
            }>
        ) => {
            const { newQualityControls } = action.payload;
            state.qualityControls.entities = {
                ...newQualityControls,
            };
            state.qualityControls.loading = false;
            state.qualityControls.error = null;
        },
        [editQualityControl.rejected.type]: (
            state: QualityControllerState,
            action: PayloadAction<AppError>
        ) => {
            state.qualityControls.loading = false;
            state.qualityControls.error = action.payload;
        },
    },
});

export const { addQualityControl, removeQualityControl } = qualityControllerStateSlice.actions;

export default qualityControllerStateSlice.reducer;
