import React, { useCallback, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import xls from 'assets/images/xls.svg';
import { IconButton } from 'components/shared/IconButton/IconButton';
import { DropdownMenu } from 'components/shared/DropdownMenu/DropdownMenu';
import { OrdersTable } from './OrdersTable/OrdersTable';
import { CenterRow } from 'styles/styled-components';
import styled from 'styled-components';
import { Button } from 'components/shared/Button/Button';
import routes from 'routes-desktop.json';
import variables from 'styles/variables.module.scss';
import { useParams } from 'react-router-dom';
import { OrderAutocompleteType } from 'api/shared/types';
import {
    getAutocompleteResultsRequest,
    getOnTimeDeliveryRequest,
    getQualityControlResultsRequest,
    getOpenOrderRequest,
    exportToExcelRequest,
} from 'api/qualityController';
import { useAutocomplete } from 'hooks/useAutocomplete';
import { OrdersFilters } from 'components/desktop/OrdersFilters/OrdersFilters';
import { OrdersFiltersList } from 'components/desktop/OrdersFiltersList/OrdersFiltersList';

import { QualityControlResultsMenu } from 'components/desktop/Widgets/QualityControlResultsMenu/QualityControlResultsMenu';
import { OpenOrderMenu } from 'components/desktop/Widgets/OpenOrderMenu/OpenOrderMenu';
import { OnTimeDeliveryMenu } from 'components/desktop/Widgets/OnTimeDeliveryMenu/OnTimeDeliveryMenu';
import { useTranslation } from 'react-i18next';
import { useWidgetsDates } from 'hooks/useWidgetsDates';
import { useOrdersRequestInput } from 'hooks/useOrdersRequestInput';
import { downloadFile } from 'utils/helpers';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { addError } from 'store/errors/slice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/types';
import { selectQualityControllerState } from 'store/qualityController/selectors';

const StyledContainer = styled(Container)`
    padding: 1% 2%;
`;

const StyledLabel = styled.span`
    font-size: 1.3vw;
    font-weight: 400;
    line-height: 1.5vw;
    color: ${variables.darkMainColor};
`;

export const QualityControllerDashboard: React.FC = props => {
    const { t } = useTranslation();
    const { supplierCardCode = '' } = useParams();
    const [orderAutocompleteType, setOrderAutocompleteType] = useState<OrderAutocompleteType>(
        OrderAutocompleteType.Orders
    );

    const [loadingXLS, setLoading] = useState(false);

    const {
        onTimeDeliveryDates,
        setOnTimeDeliveryDates,
        openOrderDates,
        setOpenOrderDates,
        qualityControlResultsDates,
        setQualityControlResultsDates,
    } = useWidgetsDates();

    const { ordersRequestInput, setOrdersRequestInput } = useOrdersRequestInput(openOrderDates);
    const { totalOrders } = useSelector(selectQualityControllerState);

    const getAutocompleteResults = useCallback(
        async (searchQuery: string) => {
            const { data } = await getAutocompleteResultsRequest(
                searchQuery,
                orderAutocompleteType,
                supplierCardCode
            );
            return data;
        },
        [orderAutocompleteType, supplierCardCode]
    );

    const autocompleteProps = useAutocomplete(getAutocompleteResults);

    const handleXlsExport = async () => {
        try {
            if (ordersRequestInput) {
                setLoading(true);
                const { data } = await exportToExcelRequest({
                    ...ordersRequestInput,
                    limit: totalOrders,
                });
                downloadFile(data, 'orders-export.xlsx', data.type);
                setLoading(false);
            }
        } catch (error: unknown) {
            setLoading(false);
        }
    };

    return (
        <StyledContainer fluid>
            <SpinnerMask show={loadingXLS}>
                <Row className='pb-4'>
                    <Col md={3}>
                        <DropdownMenu label={t('openOrders')}>
                            <OpenOrderMenu
                                dates={openOrderDates}
                                setOnTimeDeliveryDates={setOpenOrderDates}
                                getOpenOrderRequest={getOpenOrderRequest}
                            />
                        </DropdownMenu>
                    </Col>
                    <Col md={3}>
                        <DropdownMenu label={t('onTimeDelivery')}>
                            <OnTimeDeliveryMenu
                                dates={onTimeDeliveryDates}
                                setOnTimeDeliveryDates={setOnTimeDeliveryDates}
                                getOnTimeDeliveryRequest={getOnTimeDeliveryRequest}
                            />
                        </DropdownMenu>
                    </Col>
                    <Col md={3}>
                        <DropdownMenu label={t('qualityControlResults')}>
                            <QualityControlResultsMenu
                                dates={qualityControlResultsDates}
                                setOnTimeDeliveryDates={setQualityControlResultsDates}
                                getQualityControlResultsRequest={getQualityControlResultsRequest}
                            />
                        </DropdownMenu>
                    </Col>
                    <Col md={2}>
                        <Button
                            type='link'
                            variant='link'
                            pathTo={routes.qualityController.children.suppliers.fullPath}
                        >
                            {t('listOfSuppliers')}
                        </Button>
                    </Col>
                </Row>
                <CenterRow className='pb-4' $height=''>
                    <Col md={{ offset: 3, span: 6 }}>
                        <StyledLabel className='uppercase-text'>
                            {t('qualityControl')} / {supplierCardCode}
                        </StyledLabel>
                    </Col>
                    <Col md={{ offset: 1, span: 1 }}>
                        <OrdersFilters
                            autocomplete={autocompleteProps}
                            orderAutocompleteType={orderAutocompleteType}
                            setOrderAutocompleteType={setOrderAutocompleteType}
                        />
                    </Col>
                    <Col md={1}>
                        <IconButton
                            ariaLabel='export-excel'
                            image={xls}
                            onClick={handleXlsExport}
                            size='xl'
                            dataCy='exportXLSButton'
                        />
                    </Col>
                </CenterRow>
                <Row className='pb-1'>
                    <OrdersFiltersList />
                </Row>
                <CenterRow $height='60vh'>
                    <OrdersTable
                        ordersRequestInput={ordersRequestInput}
                        setOrdersRequestInput={setOrdersRequestInput}
                    />
                </CenterRow>
            </SpinnerMask>
        </StyledContainer>
    );
};
