import { useHomePathByRole } from 'hooks/useHomePathByRole';
import React from 'react';
import {
    ErrorBoundary as ReactErrorBoundary,
    FallbackProps as ReactFallbackProps,
} from 'react-error-boundary';
import { StyledBtnErrorDiv, StyledErrorLayout, StyledErrorText } from 'styles/styled-components';
import { Button } from '../Button/Button';

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

type ErrorFallbackProps = ReactFallbackProps;

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
    const handleOnClick = () => {
        resetErrorBoundary();
    };
    return (
        <StyledErrorLayout>
            <StyledErrorText>Sorry, something went wrong.</StyledErrorText>
            <StyledBtnErrorDiv>
                <Button type='button' variant='outline-link' onClick={handleOnClick}>
                    BACK TO HOME
                </Button>
            </StyledBtnErrorDiv>
        </StyledErrorLayout>
    );
};

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = props => {
    const { children } = props;
    return <ReactErrorBoundary FallbackComponent={ErrorFallback}>{children}</ReactErrorBoundary>;
};
