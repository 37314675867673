import { Button } from 'components/shared/Button/Button';
import { RadioButtons } from 'components/shared/RadioButtons/RadioButtons';
import { SearchInput } from 'components/shared/SearchInput/SearchInput';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getSapSuppliers } from 'store/admin/operations';
import { AppDispatch, AppState, BasicArrayStateType } from 'store/types';
import { CenterRow, GrayLabel } from 'styles/styled-components';
import { StyledButtonToolbar, ViewMode } from '../AddNewUserModal';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { AdminSapSupplier } from 'api/shared/types';

interface ContractorsFilesModalBodyProps {
    setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
    setSupplier: React.Dispatch<React.SetStateAction<AdminSapSupplier | undefined>>;
}

export const ContractorsFilesModalBody: React.FC<ContractorsFilesModalBodyProps> = props => {
    const { t } = useTranslation();
    const { setViewMode, setSupplier } = props;
    const dispatch = useDispatch<AppDispatch>();
    const { entities: sapSuppliers, loading } = useSelector<
        AppState,
        BasicArrayStateType<AdminSapSupplier>
    >(state => state.admin.sapSuppliers);
    const [suppliers, setSuppliers] = useState<[] | AdminSapSupplier[]>([]);

    useEffect(() => {
        if (sapSuppliers.length === 0) {
            dispatch(getSapSuppliers());
        }
    }, []);

    useEffect(() => {
        setSuppliers(sapSuppliers);
    }, [sapSuppliers]);

    const handleSearch = (value: string) => {
        const filteredSuppliers = sapSuppliers.filter(s =>
            `${s.cardName} (${s.cardCode})`?.toLowerCase().includes(value.toLowerCase())
        );
        setSuppliers(filteredSuppliers);
    };

    const handleOnSubmit = async (values: { supplier: string }) => {
        setSupplier(JSON.parse(values.supplier) as AdminSapSupplier);
        setViewMode({ type: 'CONTRACTORS_NAMES' });
    };

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                supplier: yup.string().required(t('theFieldIsRequired')),
            }),
        []
    );

    return (
        <Container>
            <SpinnerMask show={loading}>
                <CenterRow className='pb-3'>
                    <GrayLabel>{t('bindWithSAPContractor')}</GrayLabel>
                </CenterRow>
                <Formik
                    initialValues={{ supplier: '' }}
                    onSubmit={handleOnSubmit}
                    validationSchema={validationSchema}
                >
                    {({ handleSubmit, errors, values, setFieldValue }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <RadioButtons
                                name='supplier'
                                radios={suppliers.map(s => ({
                                    content: `${s.cardName} (${s.cardCode})`,
                                    value: JSON.stringify({
                                        cardCode: s.cardCode,
                                        cardName: s.cardName,
                                    }),
                                }))}
                                vertical
                                error={errors.supplier}
                                value={values.supplier}
                                onChoose={value => setFieldValue('supplier', value)}
                            />
                            <StyledButtonToolbar className='py-4'>
                                <Button variant='primary' type='submit'>
                                    {t('save')}
                                </Button>
                            </StyledButtonToolbar>
                        </Form>
                    )}
                </Formik>
                <div className='pb-2'>
                    <SearchInput onSearch={handleSearch} />
                </div>
            </SpinnerMask>
        </Container>
    );
};
