import React from 'react';
import { AppError, AuthErrorType, ErrorType } from 'store/types';
import styled from 'styled-components';
import lock from 'assets/images/lock.svg';
import errorsMsgs from 'assets/resources/errors.json';
import { isArray } from 'lodash';
import { isDefined } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

const ErrorLabel = styled.div`
    text-align: center;
    color: rgba(255, 68, 79, 1);
    padding: 5px 0px;
`;

interface ErrorMessageProps {
    appError: AppError | null;
}

export const LocalError: React.FC<ErrorMessageProps> = props => {
    const { t } = useTranslation();
    const { appError } = props;
    if (!appError) return null;
    if (isArray(appError.type)) {
        const errorTypes = appError.type
            .map(et => {
                const errorType = Object.keys(errorsMsgs).find(e => e === et) as
                    | ErrorType
                    | undefined;
                return errorType;
            })
            .filter(isDefined);

        return (
            <div className='py-3'>
                {errorTypes.map(e => (
                    <ErrorLabel key={e}>{errorsMsgs[e]}</ErrorLabel>
                ))}
            </div>
        );
    }
    const errorType = Object.keys(errorsMsgs).find(e => e === appError.type) as
        | ErrorType
        | undefined;
    if (errorType) return <ErrorLabel>{errorsMsgs[errorType]}</ErrorLabel>;
    switch (appError.type) {
        case AuthErrorType.WRONG_PASSWORD:
            return <ErrorLabel>{t('incorrectCredentials')}</ErrorLabel>;
        case AuthErrorType.BLOCKED_ACCOUNT:
            return (
                <ErrorLabel>
                    <img src={lock} width='28.75px' height='38.33px' />
                    <div> {t('twoManyLoginAttempts')}</div>
                </ErrorLabel>
            );
        default:
            return null;
    }
};
