import { Button } from 'components/shared/Button/Button';
import { Input } from 'components/shared/Input/Input';
import React, { useMemo } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { StyledButtonToolbar, ViewMode } from '../AddNewUserModal';
import * as yup from 'yup';
import addUser from 'assets/images/add-user.svg';
import styles from './BasicDataModalBody.module.scss';
import { Roles } from 'api/auth';
import { useSelector } from 'react-redux';
import { AppState } from 'store/types';
import { rolesSelector } from 'store/admin/selectors';
import { Role } from 'api/admin';
import { Formik } from 'formik';
import { RadioButtons } from 'components/shared/RadioButtons/RadioButtons';
import { useTranslation } from 'react-i18next';
import { AddNewUserBasicForm } from 'api/shared/types';

interface BasicDataModalBodyProps {
    setBasicPersonalData: React.Dispatch<React.SetStateAction<AddNewUserBasicForm | undefined>>;
    setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
}

export const BasicDataModalBody: React.FC<BasicDataModalBodyProps> = props => {
    const { t } = useTranslation();
    const { setBasicPersonalData, setViewMode } = props;
    const roles = useSelector<AppState, Array<Role> | []>(rolesSelector);
    const handleOnSubmit = async (values: AddNewUserBasicForm) => {
        const role = roles.find(r => r.id === values.roleId);
        setBasicPersonalData(values);
        if (role?.name === Roles.GtvEmployee || role?.name === Roles.QualityController) {
            setViewMode({ type: 'SUPPLIERS' });
        } else if (role?.name === Roles.Supplier) {
            setViewMode({ type: 'CONTRACTORS_FILES' });
        }
    };

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                login: yup.string().required(t('theFieldIsRequired')),
                username: yup.string().required(t('theFieldIsRequired')),
                email: yup
                    .string()
                    .email(t('incorrectEmailFormat'))
                    .required(t('theFieldIsRequired')),
                roleId: yup.number().min(1, t('theFieldIsRequired')),
            }),
        []
    );

    return (
        <>
            <Container>
                <Row>
                    <Col md={12} className={styles.newUser}>
                        <span className={styles.icon}>
                            <img src={addUser} />
                        </span>
                        <span className={styles.text}>{t('newUser')}</span>
                    </Col>
                </Row>
            </Container>
            <Formik
                initialValues={{
                    username: '',
                    login: '',
                    password: '',
                    email: '',
                    roleId: 0,
                }}
                onSubmit={handleOnSubmit}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, errors, values, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className='py-2'>
                            <Input name='username' label={t('userName')} dataCy={'username'}/>
                        </div>
                        <div className='py-2'>
                            <Input name='login' label={t('login')} dataCy={'login'}/>
                        </div>
                        <div className='py-2'>
                            <Input name='email' label={t('email')} dataCy={'email'}/>
                        </div>
                        <RadioButtons
                            label={t('userRole')}
                            name='roleId'
                            radios={roles
                                .filter(r => r.name !== Roles.Admin)
                                .map(({ id, name }) => ({ value: id, content: name}))}
                            error={errors.roleId}
                            value={values.roleId}
                            onChoose={value => setFieldValue('roleId', value)}
                        />
                        <StyledButtonToolbar className='py-4'>
                            <Button variant='primary' type='submit'>
                                {t('save')}
                            </Button>
                        </StyledButtonToolbar>
                    </Form>
                )}
            </Formik>
        </>
    );
};
