import { AdminUser, Role } from 'api/admin';
import { AdminSapSupplier } from 'api/shared/types';
import { AppError, AppState } from 'store/types';
import { isDefined, isNotNull } from 'utils/helpers';

export const adminUsersSelector = (state: AppState): Array<AdminUser> | [] =>
    state.admin.users.entities;

export const rolesSelector = (state: AppState): Array<Role> | [] => state.admin.roles.entities;

export const sapSuppliersSelector = (state: AppState): Array<AdminSapSupplier> | [] =>
    state.admin.sapSuppliers.entities;

export const selectAdminErrors = (state: AppState): AppError[] =>
    Object.values(state.admin)
        .map<AppError | null>(v => v.error)
        .filter(isNotNull)
        .filter(isDefined);
