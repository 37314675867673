import { Modal } from 'components/shared/Modal/Modal';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CenterRow, DarkMainColorLabel, GrayLabel } from 'styles/styled-components';
import { OrderChangesTable } from './OrderChangesTable/OrderChangesTable';

interface OrderChangesModalProps {
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;

    orderId: number;
    orderNumber: string;
}

export const OrderChangesModal: React.FC<OrderChangesModalProps> = props => {
    const { show, setShowModal, orderId, orderNumber } = props;
    const { t } = useTranslation();

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <Modal show={show} handleClose={handleCloseModal} size='xl'>
            <Container>
                <CenterRow className='pb-5'>
                    <div>
                        <GrayLabel className='font-24'>{t('orderChangeLog')}</GrayLabel>
                        {` `}
                        <DarkMainColorLabel className='font-24 fw-bold'>
                            {orderNumber}
                        </DarkMainColorLabel>
                    </div>
                </CenterRow>
                <Row>
                    <OrderChangesTable orderId={orderId} />
                </Row>
            </Container>
        </Modal>
    );
};
