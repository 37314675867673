import React from 'react';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import whiteArrow from 'assets/images/white-arrow.svg';
import { Button } from 'react-bootstrap';

export const StyledSearchButton = styled(Button)`
    border-radius: 0 44px 44px 0;
    border: 2px solid ${variables.darkMainColor};
    background: url(${whiteArrow}) no-repeat center;
    background-size: 20px;
    background-color: ${variables.darkMainColor};
    height: 100%;
    width: 100%;
`;

interface SearchButtonProps {
    onSubmit: () => void;
    disabled?: boolean;
}

export const SearchButton: React.FC<SearchButtonProps> = props => {
    const { onSubmit, disabled } = props;
    return <StyledSearchButton onClick={onSubmit} disabled={disabled} aria-label='filter' />;
};
