import { Formik } from 'formik';
import React, { useMemo } from 'react';
import { Col, Form } from 'react-bootstrap';
import bin from 'assets/images/bin.svg';
import * as yup from 'yup';
import { EndRow } from 'styles/styled-components';
import { RealizationLabel } from '../../RealizationList';
import { Input } from 'components/shared/Input/Input';
import { Button } from 'components/shared/Button/Button';
import { IconButton } from 'components/shared/IconButton/IconButton';
import { DatePicker } from 'components/shared/DatePicker/DatePicker';
import { useDispatch } from 'react-redux';
import {
    CreateOrderRealization,
    createRealization,
    editRealization,
} from 'store/supplier/operations';
import { AppDispatch } from 'store/types';
import { useTranslation } from 'react-i18next';
import { removeRealization } from 'store/supplier/slice';

interface RealizationToAddProps {
    index: number;
    initialValues: CreateOrderRealization;
    handleRemoveRealization: (realizationId: number) => void;
    isEdit?: boolean;
}

export const RealizationToAdd: React.FC<RealizationToAddProps> = props => {
    const { index, initialValues, handleRemoveRealization, isEdit } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const handleOnSubmit = async (values: CreateOrderRealization) => {
        if (isEdit) {
            await dispatch(editRealization(values));
        } else {
            dispatch(
                removeRealization({ orderItemId: values.orderItemId, realizationId: values.id })
            );
            await dispatch(createRealization(values));
        }
    };

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                quantity: yup.number().min(1, t('theFieldIsRequired')),
                readiness: yup.string().nullable().required(t('theFieldIsRequired')),
            }),
        []
    );

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
        >
            {({ handleSubmit }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <EndRow>
                        <Col md={2}>
                            <RealizationLabel className='uppercase-text'>
                                {t('shipment')} {index}
                            </RealizationLabel>
                        </Col>
                        <Col md={2}>
                            <Input
                                label={t('quantityOfShipment')}
                                name='quantity'
                                type='number'
                                variant='transparent'
                            />
                        </Col>
                        <Col md={2}>
                            <DatePicker 
                                label={t('readinessDateOfShipment')} 
                                name='readiness' 
                                dataCy='dateReadiness'
                            />
                        </Col>
                        <Col md={2}>
                            <DatePicker
                                label={t('expectedReadinessDeliveryOfShipment')}
                                name='expectedReadiness'
                                dataCy='dateExpected'
                            />
                        </Col>
                        <Col md={2}>
                            <DatePicker
                                label={t('realReadinessDeliveryOfShipment')}
                                name='realReadiness'
                                dataCy='dateReal'
                            />
                        </Col>
                        <Col md={1}>
                            <Button type='submit'>{t('save')}</Button>
                        </Col>
                        <Col md={1}>
                            <IconButton
                                image={bin}
                                onClick={() => handleRemoveRealization(initialValues.id)}
                            />
                        </Col>
                    </EndRow>
                </Form>
            )}
        </Formik>
    );
};
