import { createAsyncThunk } from '@reduxjs/toolkit';
import { Supplier, UserPersonalData } from 'api/shared/types';
import {
    editPersonalDataRequest,
    getAvatarRequest,
    getPersonalDataRequest,
    getUserSuppliersRequest,
    updateAvatarRequest,
} from 'api/user';
import { AxiosError } from 'axios';
import { prepareErrorType } from 'components/shared/LocalError/helpers';
import { AppError } from 'store/types';

export const getSuppliers = createAsyncThunk<Supplier[]>(
    'user/getSuppliers',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await getUserSuppliersRequest();
            return data;
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    },
    {
        condition: (arg, { getState, extra }: Pick<any, 'getState' | 'extra'>) => {
            const suppliers = getState().user.suppliers.entities;
            return suppliers.length === 0;
        },
    }
);

export const getPersonalData = createAsyncThunk<UserPersonalData>(
    'user/personalData',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await getPersonalDataRequest();
            const { data: avatar } = await getAvatarRequest();
            return { ...data, avatar };
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);

export const updateAvatar = createAsyncThunk(
    'user/updateAvatar',
    async (newAvatar: FormData, { rejectWithValue }) => {
        try {
            const { data } = await updateAvatarRequest(newAvatar);
            return data;
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);

export const editPersonalData = createAsyncThunk(
    'user/editPersonalData',
    async (
        data: Pick<UserPersonalData, 'preferedLanguage' | 'login' | 'fullUserName'>,
        { rejectWithValue }
    ) => {
        try {
            await editPersonalDataRequest(data);
            return data;
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);
