import { searchTextToBase64 } from 'utils/helpers';
import { api } from './shared/instance';
import {
    OnTimeDelivery,
    Order,
    OrderAutocompleteType,
    OrderRealization,
    OrdersRequestInput,
    OrdersResponse,
    QualityControlResults,
    WidgetsDates,
    OpenOrder,
} from './shared/types';

export type CreateOrderRealizationInput = Omit<OrderRealization, 'id'>;

const baseUrl = 'Supplier';

export const getOrdersRequest = async (input: OrdersRequestInput) => {
    if ('cardCode' in input) {
        throw new Error('Invalid cardCode in params');
    }
    const searchTextBase64 = searchTextToBase64(input.searchText);

    return api.get<OrdersResponse>(`${baseUrl}/Order`, {
        params: {
            ...input,
            ...searchTextBase64,
        },
    });
};

export const createRealizationRequest = async (input: CreateOrderRealizationInput) => {
    return api.post(`${baseUrl}/Realization`, input);
};

export const editRealizationRequest = async ({ id, ...rest }: OrderRealization) => {
    return api.patch(`${baseUrl}/Realization/${id}`, { ...rest });
};

export const deleteRealizationRequest = async (realizationId: number) => {
    return api.delete(`${baseUrl}/Realization/${realizationId}`);
};

export const getRealizationsRequest = async (orderItemId: number) => {
    return api.get<Array<OrderRealization>>(`${baseUrl}/Realization/OrderItem/${orderItemId}`);
};

export const getOrderRequest = async (orderId: number) => {
    return api.get<Order>(`${baseUrl}/Order/${orderId}`);
};

export const getAutocompleteResultsRequest = async (
    searchQuery: string,
    autocompleteType: OrderAutocompleteType
) => {
    return api.get<string[]>(`${baseUrl}/Order/Autocomplete`, {
        params: {
            searchQuery,
            autocompleteType,
        },
    });
};

export const getQualityControlResultsRequest = async (dates: WidgetsDates) => {
    return api.get<QualityControlResults>(`${baseUrl}/Order/QualityControlResults`, {
        params: {
            ...dates,
        },
    });
};

export const getOnTimeDeliveryRequest = async (dates: WidgetsDates) => {
    return api.get<OnTimeDelivery>(`${baseUrl}/Order/OnTimeDelivery`, {
        params: {
            ...dates,
        },
    });
};

export const getOpenOrderRequest = async (dates: WidgetsDates) => {
    return api.get<OpenOrder>(`${baseUrl}/Order/Open`, {
        params: {
            ...dates,
        },
    });
};

export const exportToExcelRequest = async (input: OrdersRequestInput) => {
    if ('cardCode' in input) {
        throw new Error('Invalid cardCode in params');
    }
    return api.get<Blob>(`${baseUrl}/Order/Export`, {
        responseType: 'blob',
        params: {
            ...input,
        },
    });
};
