import React from 'react';
import { StyledStack } from 'styles/styled-components';
import { FilterTag } from '../FilterTag/FilterTag';
import { v4 as uuidv4 } from 'uuid';

interface FilterTagListProps {
    filters: { content: React.ReactNode; onExit: () => void }[];
}

export const FilterTagList: React.FC<FilterTagListProps> = props => {
    const { filters } = props;
    return (
        <StyledStack direction='horizontal' gap={2} className='hstack-wrap'>
            {filters.map(f => (
                <FilterTag key={uuidv4()} onExit={f.onExit}>
                    {f.content}
                </FilterTag>
            ))}
        </StyledStack>
    );
};
