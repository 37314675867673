import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppError, BasicStateType } from 'store/types';
import {
    NormalizedOrderItems,
    NormalizedOrderRealizations,
    NormalizedOrders,
} from 'store/shared/types';
import { createRealization, deleteRealization, editRealization, getOrders } from './operations';

type NormalizedStateData = {
    orders: NormalizedOrders;
    orderItems: NormalizedOrderItems;
    realizations: NormalizedOrderRealizations;
};

export type SupplierState = {
    orders: BasicStateType<NormalizedOrders>;
    orderItems: BasicStateType<NormalizedOrderItems>;
    realizations: BasicStateType<NormalizedOrderRealizations>;
    totalOrders: number;
};

const initialState: SupplierState = {
    totalOrders: 0,
    orders: {
        entities: null,
        loading: false,
        error: null,
    },
    orderItems: {
        entities: null,
        loading: false,
        error: null,
    },
    realizations: {
        entities: null,
        loading: false,
        error: null,
    },
};

export const supplierSlice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {
        addRealization: (state: SupplierState, action: PayloadAction<number>) => {
            const orderItemId = action.payload;
            const orderItem = state.orderItems.entities?.[orderItemId];
            if (!state.realizations.entities) {
                state.realizations.entities = {};
            }
            const realizations = state.realizations.entities;
            if (orderItem) {
                const addedRealizations = Object.keys(realizations).filter(i => parseInt(i) < 0);
                const newId = addedRealizations.length === 0 ? -1 : -(addedRealizations.length + 1);
                orderItem.qualityControls.push(newId);
                realizations[newId] = {
                    id: newId,
                    quantity: 0,
                    readiness: null,
                    expectedReadiness: null,
                    realReadiness: null,
                    orderItemId: orderItem.id,
                };
            }
        },
        removeRealization: (
            state: SupplierState,
            action: PayloadAction<{ orderItemId: number; realizationId: number }>
        ) => {
            const { orderItemId, realizationId } = action.payload;
            const realizations = state.realizations.entities;
            const orderItem = state.orderItems.entities?.[orderItemId];
            if (realizations && orderItem) {
                delete realizations[realizationId];
                orderItem.realizations = orderItem.realizations.filter(e => e !== realizationId);
            }
        },
    },
    extraReducers: {
        [getOrders.pending.type]: (state: SupplierState) => {
            state.orders.loading = true;
            state.orders.error = null;
        },
        [getOrders.fulfilled.type]: (
            state: SupplierState,
            action: PayloadAction<{
                totalOrders: number;
                data: NormalizedStateData;
            }>
        ) => {
            const { data, totalOrders } = action.payload;
            state.totalOrders = totalOrders;
            state.orders.entities = data.orders ?? null;
            state.orderItems.entities = data.orderItems ?? null;
            state.realizations.entities = data.realizations ?? null;
            state.orders.loading = false;
            state.orders.error = null;
        },
        [getOrders.rejected.type]: (state: SupplierState, action: PayloadAction<AppError>) => {
            state.orders.entities = null;
            state.orderItems.entities = null;
            state.realizations.entities = null;
            state.orders.loading = false;
            state.orders.error = action.payload;
        },

        [createRealization.pending.type]: (state: SupplierState) => {
            state.realizations.loading = true;
            state.realizations.error = null;
        },
        [createRealization.fulfilled.type]: (
            state: SupplierState,
            action: PayloadAction<{
                newRealizations: NormalizedOrderRealizations;
                newOrderItems: NormalizedOrderItems;
            }>
        ) => {
            const { newRealizations, newOrderItems } = action.payload;

            state.realizations.entities = {
                ...state.realizations.entities,
                ...newRealizations,
            };
            state.orderItems.entities = {
                ...state.orderItems.entities,
                ...newOrderItems,
            };
            state.realizations.loading = false;
            state.realizations.error = null;
        },
        [createRealization.rejected.type]: (
            state: SupplierState,
            action: PayloadAction<AppError>
        ) => {
            state.realizations.loading = false;
            state.realizations.error = action.payload;
        },

        [deleteRealization.pending.type]: (state: SupplierState) => {
            state.realizations.loading = true;
            state.realizations.error = null;
        },
        [deleteRealization.fulfilled.type]: (
            state: SupplierState,
            action: PayloadAction<{
                realizationId: number;
                newOrderItems: NormalizedOrderItems;
            }>
        ) => {
            const { realizationId, newOrderItems } = action.payload;

            delete state.realizations.entities?.[realizationId];
            state.orderItems.entities = {
                ...state.orderItems.entities,
                ...newOrderItems,
            };

            state.realizations.loading = false;
            state.realizations.error = null;
        },
        [deleteRealization.rejected.type]: (
            state: SupplierState,
            action: PayloadAction<AppError>
        ) => {
            state.realizations.loading = false;
            state.realizations.error = action.payload;
        },

        [editRealization.pending.type]: (state: SupplierState) => {
            state.realizations.loading = true;
            state.realizations.error = null;
        },
        [editRealization.fulfilled.type]: (
            state: SupplierState,
            action: PayloadAction<{
                newRealizations: NormalizedOrderRealizations;
                newOrderItems: NormalizedOrderItems;
            }>
        ) => {
            const { newRealizations, newOrderItems } = action.payload;
            state.realizations.entities = {
                ...state.realizations.entities,
                ...newRealizations,
            };
            state.orderItems.entities = {
                ...state.orderItems.entities,
                ...newOrderItems,
            };

            state.realizations.loading = false;
            state.realizations.error = null;
        },
        [editRealization.rejected.type]: (
            state: SupplierState,
            action: PayloadAction<AppError>
        ) => {
            state.realizations.loading = false;
            state.realizations.error = action.payload;
        },
    },
});

export const { addRealization, removeRealization } = supplierSlice.actions;

export default supplierSlice.reducer;
