import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { authSelector } from 'store/auth/selectors';
interface AuthElementProps {
    children: React.ReactNode;
}

export const AuthElement: React.FC<AuthElementProps> = props => {
    const { children } = props;
    const auth = useSelector(authSelector);
    return auth ? <> {children}</> : <Navigate to='/login' replace />;
};
