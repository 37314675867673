import { api } from './shared/instance';
import { AddNewUserForm } from './shared/types';

export interface LoginData {
    login: string;
    password: string;
}

export enum Roles {
    Admin = 'Admin',
    Supplier = 'Supplier',
    GtvEmployee = 'GtvEmployee',
    QualityController = 'QualityController',
}

export interface Auth {
    token: string;
    expiration: string;
    firstTimeLogin: boolean;
    roles: Array<Roles>;
    refreshToken: string;
    userId: number;
}

export interface ResetPasswordInput {
    token: string;
    password: string;
    passwordConfirm: string;
}

export interface RefreshTokenInput {
    token: string;
    refreshToken: string;
}

const baseUrl = 'Auth';

export const loginRequest = async (loginData: LoginData) => {
    return api.post<Auth>(`${baseUrl}/login`, loginData);
};

export const addNewUserRequest = async (data: AddNewUserForm) => {
    return api.post<Auth>(`${baseUrl}`, data);
};

export const resetPasswordRequest = async (input: ResetPasswordInput) => {
    return api.post(`${baseUrl}/Password/Reset`, input);
};

export const refreshTokenRequest = async (input: RefreshTokenInput) => {
    return api.post<Auth>(`${baseUrl}/RefreshToken`, input);
};

export const passwordRequestReset = async (value: string) => {
    return api.post(`${baseUrl}/Password/RequestReset`, { value });
};
