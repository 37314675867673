import DesktopLayout from 'components/desktop/Layout/DesktopLayout';
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { logout } from 'store/auth/slice';
import { useDispatch } from 'react-redux';

const App: React.FC = () => {
    const dispatch = useDispatch();
    const timeout = 1000 * 60 * 15;
    const handleOnIdle = () => {
        dispatch(logout());
    };

    useIdleTimer({
        timeout,
        onIdle: handleOnIdle,
    });

    return <DesktopLayout />;
};

export default App;
