import React, { useEffect, useMemo } from 'react';
import { Container, Form, Row } from 'react-bootstrap';
import { CenterRow, DarkMainColorLabel } from 'styles/styled-components';
import styled from 'styled-components';
import { Input } from 'components/shared/Input/Input';
import variables from 'styles/variables.module.scss';
import { Button } from 'components/shared/Button/Button';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { editSystemParamsRequest, getSystemParamsRequest, SystemParams } from 'api/admin';
import { useAsync } from 'react-async';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/types';
import * as yup from 'yup';

const StyledContainer = styled.div`
    border-radius: 15px;
    border: 2px solid ${variables.darkMainColor};
    height: 80%;
    padding: 10% 20%;
`;

const StyledLayout = styled(Container)`
    height: 60vh;
    padding-top: 2vh;
`;

const BtnContainer = styled.div`
    width: 200px;
`;

const InputContainer = styled.div`
    width: 150px;
`;

export const SystemParameters: React.FC = () => {
    const { t } = useTranslation();

    const editSystemParams = useAsync({
        deferFn: ([input]) => editSystemParamsRequest(input),
    });

    const getSystemParams = useAsync({
        promiseFn: getSystemParamsRequest,
    });

    const systemParams = getSystemParams.data?.data;
    const initialFormValues = systemParams
        ? systemParams
        : {
              emailNotificationsSendingTime: undefined,
              maximumIncorrectLoginsNumber: undefined,
          };

    const handleOnSubmit = (values: SystemParams) => {
        editSystemParams.run(values);
    };

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                emailNotificationsSendingTime: yup.number().required(t('theFieldIsRequired')),
                maximumIncorrectLoginsNumber: yup.number().required(t('theFieldIsRequired')),
            }),
        []
    );

    return (
        <StyledLayout>
            <SpinnerMask show={editSystemParams.isPending || getSystemParams.isPending}>
                <Formik
                    initialValues={initialFormValues}
                    onSubmit={handleOnSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {({ handleSubmit }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <StyledContainer>
                                <div className='mb-4'>
                                    <Row className='text-center mb-2'>
                                        <DarkMainColorLabel>
                                            {t('emailNotificationsSendingTime')}
                                        </DarkMainColorLabel>
                                    </Row>
                                    <CenterRow>
                                        <InputContainer>
                                            <Input
                                                name='emailNotificationsSendingTime'
                                                placeholder={t('numberOfDays')}
                                                type='number'
                                                variant='transparent'
                                            />
                                        </InputContainer>
                                    </CenterRow>
                                </div>
                                <div>
                                    <Row className='text-center mb-2'>
                                        <DarkMainColorLabel>
                                            {t('maximumIncorrectLoginsNumber')}
                                        </DarkMainColorLabel>
                                    </Row>
                                    <CenterRow>
                                        <InputContainer>
                                            <Input
                                                name='maximumIncorrectLoginsNumber'
                                                placeholder={t('quantity')}
                                                type='number'
                                                variant='transparent'
                                            />
                                        </InputContainer>
                                    </CenterRow>
                                </div>
                            </StyledContainer>
                            <CenterRow className='pt-3'>
                                <BtnContainer>
                                    <Button variant='primary' type='submit'>
                                        {t('save')}
                                    </Button>
                                </BtnContainer>
                            </CenterRow>
                        </Form>
                    )}
                </Formik>
            </SpinnerMask>
        </StyledLayout>
    );
};
