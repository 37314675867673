import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { IconButton } from 'components/shared/IconButton/IconButton';
import { Modal } from 'components/shared/Modal/Modal';
import { CenterRow, DarkMainColorLabel, GrayLabel } from 'styles/styled-components';
import { Button } from 'components/shared/Button/Button';
import { logout } from 'store/auth/slice';
import { LanguageEnum } from 'api/shared/types';
import { useAsync } from 'react-async';
import { passwordRequestReset } from 'api/auth';
import { selectPersonalDataState } from 'store/user/selectors';
import { editPersonalData, getPersonalData, updateAvatar } from 'store/user/operations';
import { AppDispatch } from 'store/types';
import { MessageModal } from 'components/shared/MessageModal/MessageModal';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';

import close from 'assets/images/close.svg';
import powerOff from 'assets/images/power-off.svg';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { useTranslation } from 'react-i18next';
import { useChosenLanguage } from 'hooks/useChosenLanguage';
import { ChooseLanguage } from '../ChooseLanguage/ChooseLanguage';
import { AvatarEditor } from 'components/shared/AvatarEditor/AvatarEditor';

interface UserInfoModalProps {
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const GrayContainer = styled(Container)`
    height: 140px;
    background: #ecebeb;
    border-radius: 24px 24px 0px 0px;
`;

const LanguageRow = styled(CenterRow)`
    height: 125px;
    border-radius: 15px;
    border: 1px solid ${variables.darkMainColor};
`;

export const UserInfoModal: React.FC<UserInfoModalProps> = props => {
    const { t } = useTranslation();
    const { show, setShowModal } = props;
    const dispatch = useDispatch<AppDispatch>();
    const { loading, entities: personalData } = useSelector(selectPersonalDataState);
    const [isInvalidImage, setIsInvalidImage] = useState(false);
    const { lngEnum } = useChosenLanguage();
    const [chosenLanguage, setChosenLanguage] = useState<LanguageEnum>();
    const [avatar, setAvatar] = useState<string>();
    const [avatarFile, setAvatarFile] = useState<File>();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const passwordReset = useAsync({
        deferFn: ([email]) => passwordRequestReset(email),
        onResolve: () => {
            setShowConfirmationModal(true);
        },
        onReject: () => {
            setShowConfirmationModal(false);
        },
    });

    useEffect(() => {
        dispatch(getPersonalData());
    }, []);

    useEffect(() => {
        setAvatar(personalData?.avatar);
    }, [personalData]);

    useEffect(() => {
        setChosenLanguage(lngEnum);
    }, [lngEnum]);

    const handleCloseModal = () => {
        setChosenLanguage(lngEnum);
        setAvatar(personalData?.avatar);
        setAvatarFile(undefined);
        setShowModal(false);
        setIsInvalidImage(false);
    };

    const handleResetPassword = () => {
        passwordReset.run(personalData?.email);
    };

    const handleSave = async () => {
        if (chosenLanguage) {
            dispatch(
                editPersonalData({
                    preferedLanguage: chosenLanguage,
                    login: personalData?.login ?? '',
                    fullUserName: personalData?.fullUserName ?? '',
                })
            );
        }

        if (avatarFile) {
            const newAvatar = new FormData();
            newAvatar.append('file', avatarFile);
            dispatch(updateAvatar(newAvatar));
        }

        handleCloseModal();
    };

    const handleLogOut = () => {
        dispatch(logout());
    };

    const handleChooseLanguage = (language: LanguageEnum) => {
        setChosenLanguage(language);
    };

    return (
        <>
            <MessageModal
                message={t('resetLink')}
                show={showConfirmationModal}
                setShowModal={setShowConfirmationModal}
            />
            <Modal
                show={show}
                handleClose={handleCloseModal}
                hideHeader
                bodyPadding='0'
                size='sm'
                centered
                direction='right'
            >
                <SpinnerMask show={loading || passwordReset.isPending}>
                    <AvatarEditor
                        setAvatar={setAvatar}
                        setAvatarFile={setAvatarFile}
                        setIsInvalidImage={setIsInvalidImage}
                        avatar={avatar}
                    />
                    <GrayContainer fluid>
                        <Row className='p-3'>
                            <Col md={{ offset: 11, span: 1 }}>
                                <IconButton image={close} onClick={handleCloseModal} />
                            </Col>
                        </Row>

                        <Row className='text-center'>
                            <DarkMainColorLabel>{t('profile')}</DarkMainColorLabel>
                        </Row>
                    </GrayContainer>
                    <Container className='py-5'>
                        {isInvalidImage && (
                            <Row className='text-center'>
                                <Form.Control.Feedback type='invalid'>
                                    {t('incorrectImageFormat')}
                                </Form.Control.Feedback>
                            </Row>
                        )}
                        <Row className='text-center fs-5'>
                            <DarkMainColorLabel>{personalData?.fullUserName}</DarkMainColorLabel>
                        </Row>
                        <Row className='text-center pt-2'>
                            <DarkMainColorLabel>{personalData?.login}</DarkMainColorLabel>
                        </Row>
                        <CenterRow className='pt-2 px-7'>
                            <Button variant='outline-link' onClick={handleResetPassword}>
                                {t('resetPassword')}
                            </Button>
                        </CenterRow>
                        <Row className='text-center pt-5'>
                            <GrayLabel>{t('chooseLanguage')}</GrayLabel>
                        </Row>
                        <LanguageRow className='mx-4'>
                            <ChooseLanguage
                                chosenLanguage={chosenLanguage}
                                handleChooseLanguage={handleChooseLanguage}
                            />
                        </LanguageRow>
                        <CenterRow className='mt-5 px-7'>
                            <Button variant='primary' onClick={handleSave}>
                                {t('save')}
                            </Button>
                        </CenterRow>
                        <CenterRow className='mt-5 px-8'>
                            <IconButton image={powerOff} onClick={handleLogOut} isRow>
                                <DarkMainColorLabel>{t('logOut')}</DarkMainColorLabel>
                            </IconButton>
                        </CenterRow>
                    </Container>
                </SpinnerMask>
            </Modal>
        </>
    );
};
