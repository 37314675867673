import React from 'react';
import styled from 'styled-components';
import { DarkMainColorLabel } from 'styles/styled-components';
import bin from 'assets/images/bin.svg';
import { IconButton } from '../IconButton/IconButton';
import { FileType } from 'api/shared/types';

const ImageFileName = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 5px;
`;

interface FileToDeleteProps {
    fileName: string;
    handleDeleteFile: () => void;
    fileIcon: string;
}

export const FileToDelete: React.FC<FileToDeleteProps> = props => {
    const { fileName, handleDeleteFile, fileIcon } = props;
    return (
        <DarkMainColorLabel
            className='pb-2 d-flex justify-content-between align-items-center'
            style={{ width: '300px' }}
        >
            <span className='d-flex align-items-center'>
                <img
                    src={fileIcon}
                    alt='File Icon'
                    width='30px'
                    height='30px'
                    style={{ paddingRight: '5px' }}
                />
                <ImageFileName>{fileName}</ImageFileName>
            </span>
            <IconButton image={bin} onClick={handleDeleteFile} size='xs' />
        </DarkMainColorLabel>
    );
};
