import React from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';

const StyledRoundedRadio = styled(Form.Check)`
    font-weight: 500;
    .form-check-input:checked {
        background-color: ${variables.darkMainColor};
        border-color: ${variables.darkMainColor};
    }
`;

interface RoundedRadioProps {
    name: string;
    value: number | boolean;
    onChange: (value: number) => void;
    disabled?: boolean;
    label?: string;
    checked: boolean;
    dataCy?: string;
}

export const RoundedRadio: React.FC<RoundedRadioProps> = props => {
    const { disabled, label, name, value, onChange, checked, dataCy } = props;
    return (
        <StyledRoundedRadio
            inline
            id={label}
            disabled={disabled}
            type='radio'
            label={label}
            name={name}
            value={value}
            checked={checked}
            data-cy={dataCy}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                    onChange(parseInt(event.target.value));
                }
            }}
        />
    );
};
