import React, { CSSProperties, useCallback } from 'react';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { OrderChange } from 'api/shared/types';
import { useAsync } from 'react-async';
import { getOrderChangesRequest } from 'api/employee';
import { ISOToFormattedStringDateTime } from 'utils/helpers';
import {
    DarkMainColorLabel,
    LightMainColorLabel,
    LightTransparentMainColorLabel,
} from 'styles/styled-components';
import variables from 'styles/variables.module.scss';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const headerDefaultStyle: CSSProperties = {
    textAlign: 'center',
    color: variables.lightBlackColor,
    fontWeight: 500,
    backgroundColor: 'white',
};

const getColumns = (): Array<ColumnDescription<OrderChange>> => [
    {
        dataField: 'batchNumber',
        text: `${i18n.t('shipment')}`,
        headerStyle: () => {
            return {
                width: '10%',
                ...headerDefaultStyle,
            };
        },
        formatter: (cell: boolean, row: OrderChange) => (
            <div className='text-center'>
                <DarkMainColorLabel className='fw-bold'>{row.batchNumber}</DarkMainColorLabel>
            </div>
        ),
    },
    {
        dataField: 'index',
        text: `${i18n.t('index')}`,
        headerStyle: () => {
            return {
                width: '12%',
                ...headerDefaultStyle,
            };
        },
        formatter: (cell: boolean, row: OrderChange) => (
            <div className='text-center'>
                <DarkMainColorLabel className='fw-bold'>{row.index}</DarkMainColorLabel>
            </div>
        ),
    },
    {
        dataField: 'field',
        text: `${i18n.t('changedField')}`,
        headerStyle: () => headerDefaultStyle,
        formatter: (cell: boolean, row: OrderChange) => (
            <div className='text-center'>
                <DarkMainColorLabel className='fw-bold'>{row.field}</DarkMainColorLabel>
            </div>
        ),
    },
    {
        dataField: 'oldValue',
        text: `${i18n.t('valueBeforeChange')}`,
        headerStyle: () => headerDefaultStyle,
        formatter: (cell: boolean, row: OrderChange) => (
            <div className='text-center'>
                <LightTransparentMainColorLabel>{row.oldValue}</LightTransparentMainColorLabel>
            </div>
        ),
    },
    {
        dataField: 'newValue',
        text: `${i18n.t('valueAfterChange')}`,
        headerStyle: () => headerDefaultStyle,
        formatter: (cell: boolean, row: OrderChange) => (
            <div className='text-center'>
                <LightMainColorLabel>{row.newValue}</LightMainColorLabel>
            </div>
        ),
    },
    {
        dataField: 'userName',
        text: `${i18n.t('changeAuthor')}`,
        headerStyle: () => headerDefaultStyle,
        formatter: (cell: boolean, row: OrderChange) => (
            <div className='text-center'>
                <DarkMainColorLabel>{row.userName}</DarkMainColorLabel>
            </div>
        ),
    },
    {
        dataField: 'createTime',
        text: `${i18n.t('changeDate')}`,
        headerStyle: () => headerDefaultStyle,
        formatter: (cell: boolean, row: OrderChange) => (
            <div className='text-center'>
                <DarkMainColorLabel>
                    {ISOToFormattedStringDateTime(row.createTime)}
                </DarkMainColorLabel>
            </div>
        ),
    },
];

const SIZE_PER_PAGE = 10;

interface OrderChangesTableProps {
    orderId: number;
}

export const OrderChangesTable: React.FC<OrderChangesTableProps> = ({ orderId }) => {
    const { t } = useTranslation();
    const getOrderChangesMemo = useCallback(() => getOrderChangesRequest(orderId), [orderId]);
    const getOrderChanges = useAsync({
        promiseFn: getOrderChangesMemo,
    });

    const orderChanges = getOrderChanges.data?.data ?? [];

    return (
        <SpinnerMask show={getOrderChanges.isPending}>
            <BootstrapTable
                keyField='id'
                columns={getColumns()}
                data={orderChanges.map(e => ({ ...e, id: `${e.createTime}_${e.field}` }))}
                bordered={false}
                noDataIndication={() => t('noData')}
                pagination={paginationFactory({
                    sizePerPage: SIZE_PER_PAGE,
                    hidePageListOnlyOnePage: true,
                    hideSizePerPage: true,
                })}
            />
        </SpinnerMask>
    );
};
