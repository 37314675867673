import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/esm/types';
import variables from 'styles/variables.module.scss';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledButton = styled(BootstrapButton)<{ variant: ButtonVariant }>`
    display: flex;
    background-color: ${props =>
        props.variant === 'primary'
            ? variables.darkMainColor
            : props.variant === 'outline-primary' || props.variant === 'link'
            ? '#FFF'
            : props.variant === 'outline-link'
            ? 'transparent'
            : variables.darkMainColor};
    color: ${props =>
        props.variant === 'outline-primary' || props.variant === 'link'
            ? variables.darkMainColor
            : props.variant === 'primary'
            ? '#FFF'
            : props.variant === 'outline-link'
            ? variables.lightMainColor
            : variables.darkMainColor};
    width: 100%;
    border-radius: 34px;
    max-height: 100%;
    height: ${variables.defaultElementHeight};
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: ${props =>
        props.variant === 'outline-link'
            ? `2px solid ${variables.lightMainColor}`
            : `2px solid ${variables.darkMainColor}`};
    &:focus {
        background-color: ${props => {
            if (props.variant === 'link' || props.variant === 'outline-link')
                return variables.lightTransparentMainColor;
        }};
    }
`;

type ButtonCommonProps = {
    children: React.ReactNode;
    variant?: ButtonVariant | 'outline-link';
    disabled?: boolean;
};
type ButtonProps =
    | (ButtonCommonProps & {
          type?: 'button';
          onClick: React.MouseEventHandler<HTMLButtonElement>;
      })
    | (ButtonCommonProps & {
          type: 'submit';
      })
    | (ButtonCommonProps & {
          type: 'link';
          variant: 'link' | 'outline-link';
          pathTo: string;
      });

export const Button: React.FC<ButtonProps> = props => {
    const { variant = 'primary', type = 'button', ...restProps } = props;
    let btnProps: Record<string, unknown> = { variant, type, ...restProps };
    if (props.type === 'link') {
        const { pathTo, children, type: t, ...restLinkProps } = props;
        btnProps = {
            type: 'button',
            as: NavLink,
            to: pathTo,
            ...restLinkProps,
        };
    }
    return <StyledButton {...btnProps}>{props.children}</StyledButton>;
};
