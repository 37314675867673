import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import user from 'store/user/slice';
import auth, { logout } from 'store/auth/slice';
import admin from 'store/admin/slice';
import supplier from 'store/supplier/slice';
import qualityController from 'store/qualityController/slice';
import filters from 'store/filters/slice';
import employee from 'store/employee/slice';
import errors from 'store/errors/slice';
import modals from 'store/modals/slice';

const appReducer = combineReducers({
    auth,
    admin,
    supplier,
    qualityController,
    user,
    filters,
    employee,
    errors,
    modals,
});

export const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === logout.type) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
    devTools: true,
});

export default store;
