import React, { useMemo } from 'react';
import { Button } from 'components/shared/Button/Button';
import { Input } from 'components/shared/Input/Input';
import { Formik } from 'formik';
import { ButtonToolbar, Container, Form } from 'react-bootstrap';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from 'store/types';
import { MainPage } from '../MainPage/MainPage';
import { resetPassword } from 'store/auth/operations';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectAuthState } from 'store/auth/selectors';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import routes from 'routes-desktop.json';
import { useTranslation } from 'react-i18next';
import { LocalError } from 'components/shared/LocalError/LocalError';
import { AuthState } from 'store/auth/slice';

const StyledLayout = styled(Container)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    position: absolute;
    width: 28%;
    height: 40%;
    left: 22%;
    top: 34%;
    background: #fefefe;
    border-radius: 34px;
    border: 2px solid ${variables.darkMainColor};
    padding: 0.5rem 3vw;
    height: fit-content;
`;

const StyledForm = styled(Form)`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const ResetPassword: React.FC = () => {
    const { t } = useTranslation();
    const { error } = useSelector<AppState, AuthState>(selectAuthState);
    const dispatch: AppDispatch = useDispatch();
    const { loading } = useSelector(selectAuthState);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const handleOnSubmit = async ({
        passwordConfirm,
        password,
    }: {
        password: string;
        passwordConfirm: string;
    }) => {
        const response = await dispatch(
            resetPassword({ password, passwordConfirm, token: searchParams.get('token') ?? '' })
        );
        if (!(response?.payload as any)?.error) {
            navigate(routes.login.path, {
                replace: true,
            });
        }
    };

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                password: yup
                    .string()
                    .required(t('theFieldIsRequired'))
                    .min(8, t('PasswordTooShort')),
                passwordConfirm: yup
                    .string()
                    .required(t('pleaseRetypeYourPassword'))
                    .min(8, t('PasswordTooShort'))
                    .oneOf([yup.ref('password')], t('yourPasswordsDoNotMatch')),
            }),
        []
    );

    return (
        <MainPage>
            <SpinnerMask show={loading}>
                <StyledLayout>
                    <Formik
                        initialValues={{
                            password: '',
                            passwordConfirm: '',
                        }}
                        onSubmit={handleOnSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ handleSubmit }) => (
                            <StyledForm noValidate onSubmit={handleSubmit}>
                                <div className='py-2'>
                                    <Input
                                        name='password'
                                        label={t('newPassword')}
                                        type='password'
                                    />
                                </div>
                                <div className='py-2'>
                                    <Input
                                        name='passwordConfirm'
                                        label={t('repeatNewPassword')}
                                        type='password'
                                    />
                                </div>
                                <LocalError appError={error} />
                                <ButtonToolbar className='px-5'>
                                    <Button variant='primary' type='submit'>
                                        {t('passwordReset')}
                                    </Button>
                                </ButtonToolbar>
                            </StyledForm>
                        )}
                    </Formik>
                </StyledLayout>
            </SpinnerMask>
        </MainPage>
    );
};
