import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import styled from 'styled-components';

const StyledBootstrapModal = styled(BootstrapModal)<{ $direction: 'center' | 'right' }>`
    ${({ $direction }) =>
        $direction === 'right' &&
        `.modal-dialog {
        margin: 0;
        margin-left: auto;
        margin-right: 3.5rem;
    }`}
`;

const Body = styled(BootstrapModal.Body)<{ $bodyPadding?: string }>`
    padding: ${({ $bodyPadding }) => ($bodyPadding ? $bodyPadding : '0 1rem')};
    position: relative;
`;

const Header = styled(BootstrapModal.Header)`
    border: none;
    padding: 1rem 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
`;

const Footer = styled(BootstrapModal.Footer)`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    height: 100px;
`;

interface ModalProps {
    headerChildren?: React.ReactNode;
    children: React.ReactNode;
    footerChildren?: React.ReactNode;
    handleClose?: () => void;
    show: boolean;
    size?: 'sm' | 'lg' | 'xl';
    centered?: boolean;
    hideHeader?: boolean;
    hideBody?: boolean;
    bodyPadding?: string;
    direction?: 'center' | 'right';
}

export const Modal: React.FC<ModalProps> = props => {
    const {
        headerChildren,
        children,
        footerChildren,
        handleClose,
        show,
        size,
        centered,
        hideHeader,
        bodyPadding,
        direction = 'center',
    } = props;
    return (
        <StyledBootstrapModal
            show={show}
            onHide={handleClose}
            size={size}
            centered={centered}
            $direction={direction}
        >
            {!hideHeader && <Header closeButton>{headerChildren}</Header>}
            <Body $bodyPadding={bodyPadding}>{children}</Body>
            {footerChildren && <Footer>{footerChildren}</Footer>}
        </StyledBootstrapModal>
    );
};
