import { OrderRealization } from 'api/shared/types';
import { Output } from 'components/shared/Output/Output';
import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { EndRow } from 'styles/styled-components';
import { ISOToFormattedStringDate } from 'utils/helpers';
import { RealizationLabel } from '../RealizationList';
import edit from 'assets/images/edit.svg';
import { IconButton } from 'components/shared/IconButton/IconButton';
import { AppDispatch } from 'store/types';
import { useDispatch } from 'react-redux';
import { CreateOrderRealization, deleteRealization } from 'store/supplier/operations';
import { RealizationToAdd } from '../RealizationToAddList/RealizationToAdd/RealizationToAdd';
import { useTranslation } from 'react-i18next';
import addBatch from 'assets/images/add-batch.svg';

interface AddedRealizationProps {
    index: number;
    lastRealizationIndex: number;
    orderId: number;
    realization: OrderRealization;
    handleAddRealization: () => void;
    realizationsToAdd: OrderRealization[];
}

export const AddedRealization: React.FC<AddedRealizationProps> = props => {
    const {
        index,
        realization,
        orderId,
        realizationsToAdd,
        handleAddRealization,
        lastRealizationIndex,
    } = props;
    const { t } = useTranslation();
    const [isEditRealization, setIsEditRealization] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const handleRemoveRealization = async (realizationId: number) => {
        await dispatch(deleteRealization({ realizationId, orderId }));
    };

    const handleEditRealization = () => {
        setIsEditRealization(true);
    };

    const addedRealizationCmp = (
        <EndRow className='py-4'>
            <Col md={1}>
                <RealizationLabel className='uppercase-text'>
                    {t('shipment')} {index}
                </RealizationLabel>
            </Col>
            <Col md={2}>
                <Output label={t('quantityOfShipment')} content={realization.quantity} />
            </Col>
            <Col md={2}>
                <Output
                    label={t('readinessDateOfShipment')}
                    content={ISOToFormattedStringDate(realization.readiness)}
                />
            </Col>
            <Col md={3}>
                <Output
                    label={t('expectedReadinessDeliveryOfShipment')}
                    content={ISOToFormattedStringDate(realization.expectedReadiness)}
                />
            </Col>
            <Col md={2}>
                <Output
                    label={t('realReadinessDeliveryOfShipment')}
                    content={ISOToFormattedStringDate(realization.realReadiness)}
                />
            </Col>
            <Col md={1}>
                <IconButton image={edit} onClick={handleEditRealization} dataCy={`EditRealizationButton-${index}`}/>
            </Col>
            <Col md={1}>
                {realizationsToAdd.length === 0 && index === lastRealizationIndex && (
                    <IconButton image={addBatch} onClick={handleAddRealization} size='lg' dataCy={`AddRealizationButton`} />
                )}
            </Col>
        </EndRow>
    );
    return (
        <>
            {isEditRealization ? (
                <EndRow className='py-4'>
                    <RealizationToAdd
                        index={index}
                        initialValues={{ ...realization, orderId }}
                        handleRemoveRealization={() => handleRemoveRealization(realization.id)}
                        isEdit
                    />
                </EndRow>
            ) : (
                addedRealizationCmp
            )}
        </>
    );
};
