import { AdminUser } from 'api/admin';
import { LocalError } from 'components/shared/LocalError/LocalError';
import { Button } from 'components/shared/Button/Button';
import { CheckboxList } from 'components/shared/CheckboxList/CheckboxList';
import { SearchInput } from 'components/shared/SearchInput/SearchInput';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addNewUser, editUser, getSuppliers } from 'store/admin/operations';
import { AppDispatch, AppState, BasicArrayStateType } from 'store/types';
import { CenterRow, GrayLabel } from 'styles/styled-components';
import * as yup from 'yup';
import { StyledButtonToolbar } from '../AddNewUserModal/AddNewUserModal';
import {
    AddNewUserBasicForm,
    AdminSapSupplier,
    EditAdminUserBasicForm,
    Supplier,
} from 'api/shared/types';
import { useTranslation } from 'react-i18next';

export interface AddNewUserSuppliersForm {
    suppliersList: Array<string>;
}

interface SuppliersModalBodyProps {
    basicPersonalData?: AddNewUserBasicForm | EditAdminUserBasicForm;
    handleCloseModal: () => void;
}

export const SuppliersModalBody: React.FC<SuppliersModalBodyProps> = props => {
    const { basicPersonalData, handleCloseModal } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { entities: dbSuppliers, loading } = useSelector<AppState, BasicArrayStateType<Supplier>>(
        state => state.admin.suppliers
    );
    const { error } = useSelector<AppState, BasicArrayStateType<AdminUser>>(
        state => state.admin.users
    );
    const [suppliers, setSuppliers] = useState<[] | Supplier[]>([]);

    useEffect(() => {
        if (dbSuppliers.length === 0) {
            dispatch(getSuppliers());
        }
    }, []);

    useEffect(() => {
        setSuppliers(dbSuppliers);
    }, [dbSuppliers]);

    const handleSearch = (value: string) => {
        const filteredSuppliers = dbSuppliers.filter(s =>
            s.name?.toLowerCase().includes(value.toLowerCase())
        );
        setSuppliers(filteredSuppliers);
    };

    const handleOnSubmit = async (values: AddNewUserSuppliersForm) => {
        if (basicPersonalData && 'password' in basicPersonalData) {
            const response = await dispatch(
                addNewUser({
                    ...basicPersonalData,
                    suppliersList: values.suppliersList.map(v => JSON.parse(v) as AdminSapSupplier),
                    contactPersonId: 0,
                })
            );
            if (!(response.payload as any).error) {
                handleCloseModal();
            }
        } else if (basicPersonalData && 'isActive' in basicPersonalData) {
            const response = await dispatch(
                editUser({
                    ...basicPersonalData,
                    suppliers: suppliers.filter(
                        s =>
                            !!values.suppliersList
                                .map(v => JSON.parse(v) as AdminSapSupplier)
                                .find(v => v.cardName === s.name)
                    ),
                })
            );

            if (!(response.payload as any).error) {
                handleCloseModal();
            }
        }
    };

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                suppliersList: yup
                    .array()
                    .of(
                        yup.object().shape({
                            cardCode: yup.string(),
                            cardName: yup.string(),
                        })
                    )
                    .min(1, t('theFieldIsRequired')),
            }),
        []
    );

    return (
        <Container>
            <SpinnerMask show={loading}>
                <CenterRow className='pb-3'>
                    <GrayLabel>{t('selectAvailableSuppliers')}</GrayLabel>
                </CenterRow>
                <Formik
                    initialValues={{ suppliersList: [] }}
                    onSubmit={handleOnSubmit}
                    validationSchema={validationSchema}
                >
                    {({ handleSubmit }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <CheckboxList
                                name='suppliersList'
                                checkboxes={suppliers.map(s => ({
                                    content: s.name,
                                    value: JSON.stringify({
                                        cardCode: s.cardCode,
                                        cardName: s.name,
                                    }),
                                }))}
                            />
                            <StyledButtonToolbar className='py-4'>
                                <Button variant='primary' type='submit'>
                                    {t('save')}
                                </Button>
                            </StyledButtonToolbar>
                        </Form>
                    )}
                </Formik>
                <LocalError appError={error} />
                <div className='pb-2'>
                    <SearchInput onSearch={handleSearch} />
                </div>
            </SpinnerMask>
        </Container>
    );
};
