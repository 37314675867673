import React from 'react';
import {
    AsyncTypeahead as BootstrapAsyncTypeahead,
    TypeaheadModel,
} from 'react-bootstrap-typeahead';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import search from 'assets/images/search.svg';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';

export const StyledAutocomplete = styled(BootstrapAsyncTypeahead)`
    height: 3rem;

    border-radius: 44px 0 0 44px;
    border: 2px solid;
    border-color: ${variables.darkMainColor};
    padding-left: 10%;
    background: url(${search}) no-repeat left;
    background-size: 35px;
    background-position: 3%;
    font-weight: 500;
    .rbt-aux {
        color: ${variables.darkMainColor};
    }

    .rbt-token {
        background-color: ${variables.lightTransparentMainColor};
        color: ${variables.lightMainColor};
        border-radius: 10px;
        button {
            background-color: transparent;
            border: none;
        }
    }

    .rbt-input-main,
    .rbt,
    .rbt-input-multi {
        display: flex;
        outline: none;
        border: none;
        box-shadow: none;
        align-items: center;
        color: ${variables.lightMainColor};
        height: 100%;
    }

    .rbt-menu.dropdown-menu.show {
        border: none !important;
        border-radius: 15px !important;
        transform: translate(42px, 54px) !important;
        width: calc(100% - 54px) !important;
        max-height: 350px !important;

        .dropdown-item {
            color: ${variables.lightBlackColor} !important;
            border-radius: 44px !important;
            &:hover {
                background-color: ${variables.lightTransparentMainColor};
            }
        }
    }
`;

interface AutocompleteProps {
    onSearch: (query: string) => Promise<void>;
    options: Array<string>;
    loading: boolean;
    placeholder?: string;
    onChange: (selected: string[]) => void;
    multiple?: boolean;
}

export const Autocomplete: React.FC<AutocompleteProps> = props => {
    const { t } = useTranslation();
    const { loading, placeholder = t('search'), onSearch, options, onChange, multiple } = props;
    const filterBy = () => true;
    const getValue = (text: string) => {
        if (!text) return [];
        return [text];
    };
    return (
        <StyledAutocomplete
            multiple={multiple}
            id='autocomplete'
            filterBy={multiple ? undefined : filterBy}
            isLoading={loading}
            minLength={3}
            onSearch={onSearch}
            options={options}
            placeholder={placeholder}
            autoFocus
            caseSensitive={false}
            onChange={(changedSelected: TypeaheadModel[]) => {
                onChange(changedSelected.map((s: TypeaheadModel) => s.toString()));
            }}
            onInputChange={text => {
                if (!multiple) {
                    const value = getValue(text);
                    onChange(value);
                }
            }}
            inputProps={{
                onPaste: e => {
                    if (!multiple) {
                        const pastedTxt = e.clipboardData?.getData('Text')?.trim();
                        const value = getValue(pastedTxt);
                        onChange(value);
                    }
                },
            }}
            searchText=''
            useCache={false}
        />
    );
};
