import { Button } from 'components/shared/Button/Button';
import { Modal } from 'components/shared/Modal/Modal';
import React, { useState } from 'react';
import { ButtonToolbar, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { CenterRow, GrayLabel } from 'styles/styled-components';
import variables from 'styles/variables.module.scss';
import { availableMimeTypes } from 'utils/consts';
import { useTranslation } from 'react-i18next';
import {
    FileListContainerType,
    FileListUploader,
} from 'components/shared/FileListUploader/FileListUploader';

interface AddFilesModalProps {
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSaveFiles: (files: File[]) => Promise<void> | void;
    title: string;
}

const StyledButtonToolbar = styled(ButtonToolbar)`
    width: 100%;
    padding: 0px 20%;
`;

export const AddFilesModal: React.FC<AddFilesModalProps> = props => {
    const { t } = useTranslation();
    const { show, onSaveFiles, setShowModal, title } = props;
    const [filesToSend, setFilesToSend] = useState<File[]>([]);

    const handleSaveFiles = () => {
        onSaveFiles(filesToSend);
        setShowModal(false);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setFilesToSend([]);
    };

    return (
        <Modal show={show} size='sm' handleClose={handleModalClose}>
            <Container>
                <CenterRow className='pb-4'>
                    <GrayLabel>{title}</GrayLabel>
                </CenterRow>
                {availableMimeTypes.map(m => (
                    <FileListUploader
                        key={m}
                        mimeTypes={m}
                        filesToAdd={filesToSend}
                        setFilesToAdd={setFilesToSend}
                        type={FileListContainerType.HorizontalStack}
                    />
                ))}
                <CenterRow className='pt-4'>
                    <StyledButtonToolbar className='py-4'>
                        <Button
                            variant='primary'
                            type='button'
                            onClick={handleSaveFiles}
                            disabled={filesToSend.length === 0}
                        >
                            {t('save')}
                        </Button>
                    </StyledButtonToolbar>
                </CenterRow>
            </Container>
        </Modal>
    );
};
