import { Cell, Column, Row as TableRow } from 'react-table';
import variables from 'styles/variables.module.scss';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import { FiltersState } from 'store/filters/slice';
import {
    OrderAutocompleteType,
    OrderItem,
    OrdersRequestInput,
    OrdersRequestOptionalInput,
    QualityControl,
} from 'api/shared/types';
import { compareAsc, compareDesc, isBefore, parseISO } from 'date-fns';

type OrderTableItem = {
    number: string;
    index: string;
    supplierOrderNumber: string;
};

export interface OrdersTableProps {
    ordersRequestInput?: OrdersRequestInput;
    setOrdersRequestInput: React.Dispatch<React.SetStateAction<OrdersRequestInput | undefined>>;
}

export const SIZE_PER_PAGE = 25;

export const isInHalfOfProductsCount = <T extends OrderTableItem>(
    row: TableRow<T>,
    ordersTable: T[]
): boolean => {
    const item = row?.original;
    const orderItems = ordersTable.filter(o => o.number === item.number);
    if (orderItems) {
        const length = orderItems.length;
        const index = orderItems.findIndex(o => o.index === item.index);
        return length === 1 || Math.round(length / 2) === index;
    }
    return false;
};

export const isRowSpanColumn = <T extends OrderTableItem>(cell: Cell<T, any>) =>
    cell.column.id === 'number' || cell.column.id === 'supplierOrderNumber';

export const isEmptyCell = <T extends OrderTableItem>(cell: Cell<T, any>, ordersTable: T[]) => {
    return isRowSpanColumn(cell) && !isInHalfOfProductsCount(cell.row, ordersTable);
};

export const isTheLastProduct = <T extends OrderTableItem>(
    row: TableRow<T>,
    rows: TableRow<T>[]
): boolean => {
    const item = row?.original;
    const ordersTable = rows.map(r => r.original);
    const orderItems = ordersTable.filter(o => o.number === item.number);
    const lastItem = last(orderItems);
    return lastItem === item;
};

export const getFontColorPerColumn = <T extends OrderTableItem>(
    id: string,
    columns: Column<T>[]
) => {
    if (id) {
        if (id === columns[1].id) return variables.lightMainColor;
        if (id === columns[3].id) return variables.lightGrayColor;
    }
    return variables.darkMainColor;
};

export const getFontWeightPerColumn = <T extends OrderTableItem>(
    id: string,
    columns: Column<T>[]
): number => {
    if (id) {
        if (id === columns[0].id) return 700;
        if (id === columns[1].id) return 700;
        if (id === columns[2].id) return 600;
        if (id === columns[4].id) return 500;
        if (id === columns[8].id) return 500;
        if (id === columns[9].id) return 500;
        if (id === columns[columns.length - 1].id) return 700;
    }
    return 400;
};

export const getFontSizePerColumn = <T extends OrderTableItem>(
    id: string,
    columns: Column<T>[]
) => {
    if (id) {
        if (id === columns[0].id) return '1.1vw';
        if (id === columns[1].id) return '1.1vw';
        if (id === columns[4].id) return '1.2vw';
        if (id === columns[8].id) return '1.2vw';
        if (id === columns[columns.length - 1].id) return '1vw';
    }
    return '1vw';
};

export const getFilters = (
    filtersState: FiltersState
): OrdersRequestOptionalInput | Record<string, never> => {
    if (!isEmpty(pickBy(filtersState, Boolean))) {
        const filters: OrdersRequestOptionalInput = {
            searchText: filtersState.phrase?.value,
            notCompleted: !!filtersState.areOrdersCompleted,
            orderNumber: filtersState.phrase?.type === OrderAutocompleteType.Orders,
            supplierOrderNumber: filtersState.phrase?.type === OrderAutocompleteType.Orders,
            productIndex: filtersState.phrase?.type === OrderAutocompleteType.ProductIndex,
            productName: filtersState.phrase?.type === OrderAutocompleteType.ProductName,
        };

        if (filtersState.openOrdersWidget) {
            filters.widgetSearchType = filtersState.openOrdersWidget.type;
        }

        return filters;
    }

    return {};
};

export const getItemsCheckedPercent = (
    qualityControl: QualityControl,
    orderItem: OrderItem
): number =>
    orderItem.quantity == 0 ? 0 : (qualityControl.itemsChecked / orderItem.quantity) * 100;
