import { AxiosError } from 'axios';
import store from 'store';
import errorsMsgs from 'assets/resources/errors.json';

export enum AuthErrorType {
    WRONG_PASSWORD = 'WRONG_PASSWORD',
    BLOCKED_ACCOUNT = 'BLOCKED_ACCOUNT',
}

export type ErrorType = keyof typeof errorsMsgs;

export const noneErrorType = 'NONE';
export type AppErrorType = ErrorType | AuthErrorType | 'NONE';

export interface AppError {
    error: unknown | AxiosError | Error;
    type: AppErrorType | AppErrorType[];
}

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type BasicStateType<T> =
    //initial
    | {
          entities: null;
          loading: false;
          error: null;
      }
    //pending
    | {
          entities: null | T;
          loading: true;
          error: null;
      }
    //fulfilled
    | {
          entities: T;
          loading: false;
          error: null;
      }
    //rejected
    | {
          entities: null;
          loading: false;
          error: AppError;
      };

export type BasicArrayStateType<T> =
    //initial
    | {
          entities: [];
          loading: false;
          error: null;
      }
    //pending
    | {
          entities: T[];
          loading: true;
          error: null;
      }
    //fulfilled
    | {
          entities: T[];
          loading: false;
          error: null;
      }
    //rejected
    | {
          entities: [];
          loading: false;
          error: AppError;
      };
