import { Roles } from 'api/auth';
import { useRole } from 'hooks/useRole';
import React from 'react';

interface IfRoleProps {
    children: React.ReactNode;
    roles: Roles[];
    isNot?: boolean;
}

export const IfRole: React.FC<IfRoleProps> = props => {
    const { children, roles, isNot = false } = props;
    const { role, hasRole } = useRole(roles);

    if (isNot) {
        if (hasRole) {
            return null;
        } else {
            return <>{children}</>;
        }
    }

    if (hasRole) {
        return <>{children}</>;
    }

    return null;
};
