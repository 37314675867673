import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { EndRow, StyledLine } from 'styles/styled-components';
import { CreateOrderRealization } from 'store/supplier/operations';
import { v4 as uuidv4 } from 'uuid';
import { RealizationToAdd } from './RealizationToAdd/RealizationToAdd';
import { IconButton } from 'components/shared/IconButton/IconButton';
import addBatch from 'assets/images/add-batch.svg';
import { OrderRealization } from 'api/shared/types';
import { useDispatch } from 'react-redux';
import { removeRealization } from 'store/supplier/slice';
import { AppDispatch } from 'store/types';

interface RealizationToAddListProps {
    index: number;
    realizationsToAdd: OrderRealization[];
    orderId: number;
    handleAddRealization: () => void;
    orderItemId: number;
}

export const RealizationToAddList: React.FC<RealizationToAddListProps> = props => {
    const { index, realizationsToAdd, orderId, handleAddRealization, orderItemId } = props;
    const dispatch = useDispatch<AppDispatch>();

    const handleRemoveRealization = (realizationId: number) => {
        dispatch(removeRealization({ orderItemId, realizationId }));
    };

    return (
        <>
            {realizationsToAdd.map((r, i) => (
                <React.Fragment key={uuidv4()}>
                    <EndRow className='py-4'>
                        <Col md={11}>
                            <RealizationToAdd
                                initialValues={{ ...r, orderId }}
                                handleRemoveRealization={() => handleRemoveRealization(r.id)}
                                index={index + i}
                            />
                        </Col>
                        <Col md={1}>
                            {i === realizationsToAdd.length - 1 && (
                                <IconButton
                                    image={addBatch}
                                    onClick={handleAddRealization}
                                    size='lg'
                                />
                            )}
                        </Col>
                    </EndRow>
                    {i < realizationsToAdd.length - 1 && <StyledLine />}
                </React.Fragment>
            ))}
        </>
    );
};
