import React, { useEffect, useState } from 'react';
import { AppDispatch, AppError, AppState } from 'store/types';
import { Modal } from '../Modal/Modal';
import styled from 'styled-components';
import error from 'assets/images/error.svg';
import close from 'assets/images/close.svg';
import { Col, Container } from 'react-bootstrap';
import { CenterRow } from 'styles/styled-components';
import { IconButton } from '../IconButton/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { isGlobalErrorOccurredSelector, selectErrors } from 'store/selectors';
import { removeError } from 'store/errors/slice';
import { last } from 'lodash';

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const ErrorLabel = styled.span`
    text-align: left;
    font-family: 'Cairo';
    color: rgba(255, 68, 79, 1);
`;

export const GlobalError: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const isGlobalErrorOccurred = useSelector<AppState, boolean>(isGlobalErrorOccurredSelector);
    const errors = useSelector<AppState, AppError[]>(selectErrors);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(isGlobalErrorOccurred);
    }, [isGlobalErrorOccurred]);

    const handleClose = () => {
        if (errors.length > 0) {
            const appError = last(errors);
            if (appError) {
                dispatch(removeError(appError));
            }
        }
        setShow(false);
    };

    return (
        <Modal show={show} centered size='sm' hideHeader>
            <Container fluid>
                <CenterRow style={{ padding: '30px 0' }}>
                    <Col md={3}>
                        <img src={error} className='img-responsive' />
                    </Col>
                    <Col md={8}>
                        <StyledDiv>
                            <ErrorLabel>ERROR</ErrorLabel>
                            <ErrorLabel>Something went wrong.</ErrorLabel>
                            <ErrorLabel>
                                Please try again or contact the administrator at:
                                suppliers_website@gtv.com.pl
                            </ErrorLabel>
                        </StyledDiv>
                    </Col>
                    <Col md={1}>
                        <IconButton image={close} onClick={handleClose} />
                    </Col>
                </CenterRow>
            </Container>
        </Modal>
    );
};
