import { Roles } from 'api/auth';
import routes from 'routes-desktop.json';
import { useRole } from './useRole';

export const useHomePathByRole = () => {
    const { role } = useRole();
    if (role) {
        switch (role) {
            case Roles.Admin:
                return routes.admin.children.users.fullPath;
            case Roles.Supplier:
                return routes.supplier.path;
            case Roles.QualityController:
                return routes.qualityController.children.suppliers.fullPath;
            case Roles.GtvEmployee:
                return routes.employee.children.suppliers.fullPath;
        }
    }

    return routes.login.path;
};
