import { getUserModificationDetailsRequest, UserModification } from 'api/admin';
import { Modal } from 'components/shared/Modal/Modal';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import React, { useEffect } from 'react';
import { useAsync } from 'react-async';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CenterRow, DarkMainColorLabel, GrayLabel, StyledStack } from 'styles/styled-components';
import { UserChangesTable } from '../UserChangesTable/UserChangesTable';
import styled from 'styled-components';

interface UserModificationsDetailModalProps {
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;

    userId: number;
    userChange: UserModification;
}

const StyledDiv = styled.div`
    display: flex;
    overflow: auto;
    max-height: 400px;
`;

export const UserModificationsDetailModal: React.FC<UserModificationsDetailModalProps> = props => {
    const { show, setShowModal, userId, userChange } = props;
    const { t } = useTranslation();

    const getUserModificationDetails = useAsync({
        deferFn: ([uId, oId]) => getUserModificationDetailsRequest(uId, oId),
    });

    useEffect(() => {
        if (show) {
            getUserModificationDetails.run(userId, userChange.orderId);
        }
    }, [show]);

    const userModificationDetails = getUserModificationDetails.data?.data ?? [];

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <Modal show={show} handleClose={handleCloseModal} size='lg'>
            <SpinnerMask show={getUserModificationDetails.isPending}>
                <Container>
                    <CenterRow>
                        <GrayLabel>{t('listOfUserChanges')}</GrayLabel>
                    </CenterRow>

                    <Row className='pt-3'>
                        <UserChangesTable userChanges={[userChange]} />
                    </Row>
                </Container>
                <StyledDiv>
                    <StyledStack className='pt-3 px-2'>
                        {userModificationDetails.map(umd => (
                            <div key={`${umd.field} ${umd.oldValue}`} className='mb-2'>
                                <DarkMainColorLabel>{`${umd.description} ${umd.oldValue}`}</DarkMainColorLabel>
                                <div>
                                    {' '}
                                    <DarkMainColorLabel>{`> ${umd.description} ${umd.newValue}`}</DarkMainColorLabel>
                                </div>
                            </div>
                        ))}
                    </StyledStack>
                </StyledDiv>
            </SpinnerMask>
        </Modal>
    );
};
