import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterType, OrderAutocompleteType, WidgetSearchType } from 'api/shared/types';
import { isArray, isEmpty, isNumber } from 'lodash';

export interface FiltersState {
    areOrdersCompleted?: FilterType<boolean>;
    phrase?: FilterType<OrderAutocompleteType>;
    openOrdersWidget?: FilterType<WidgetSearchType>;
}

const initialState: FiltersState = {
    areOrdersCompleted: undefined,
    phrase: undefined,
    openOrdersWidget: undefined,
};

export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setFilters(state: FiltersState, action: PayloadAction<FiltersState>) {
            const { areOrdersCompleted, phrase, openOrdersWidget } = action.payload;

            state.areOrdersCompleted = areOrdersCompleted;
            state.phrase = phrase;
            state.openOrdersWidget = openOrdersWidget;
        },
        removeFilter(
            state: FiltersState,
            action: PayloadAction<{ filterKey: keyof FiltersState; valueIndex?: number }>
        ) {
            const { filterKey, valueIndex } = action.payload;
            const filter = state[filterKey];
            if (filter && isArray(filter.value)) {
                filter.value = filter.value.filter((v, index) => index !== valueIndex);
                if (isEmpty(filter.value)) delete state[filterKey];
            } else {
                delete state[filterKey];
            }
        },
    },
    extraReducers: {},
});

export const { setFilters, removeFilter } = filtersSlice.actions;

export default filtersSlice.reducer;
