import React from 'react';
import { OrderItem, QualityControl } from 'api/shared/types';
import { Modal } from 'components/shared/Modal/Modal';
import { CenterRow, GrayLabel, ScrollContainer } from 'styles/styled-components';
import { Container } from 'react-bootstrap';
import { QualityControlResult } from './QualityControlResult/QualityControlResult';
import { useTranslation } from 'react-i18next';

interface QualityControlResultsModalProps {
    qualityControls: QualityControl[];
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    orderItem: OrderItem;
}

export const QualityControlResultsModal: React.FC<QualityControlResultsModalProps> = props => {
    const { qualityControls, show, setShowModal, orderItem } = props;
    const { t } = useTranslation();
    const handleModalClose = () => {
        setShowModal(false);
    };
    return (
        <Modal show={show} size='xl' handleClose={handleModalClose}>
            <Container>
                <CenterRow className='pb-2'>
                    <GrayLabel>{t('qualityControlResults')}</GrayLabel>
                </CenterRow>
                <ScrollContainer className='my-2'>
                    {qualityControls.map(q => (
                        <QualityControlResult orderItem={orderItem} qualityControl={q} key={q.id} />
                    ))}
                </ScrollContainer>
            </Container>
        </Modal>
    );
};
