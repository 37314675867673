import { Button } from 'components/shared/Button/Button';
import { SearchInput } from 'components/shared/SearchInput/SearchInput';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addNewUser, getSapContactPersons } from 'store/admin/operations';
import { AppDispatch, AppState, BasicArrayStateType } from 'store/types';
import { CenterRow, DarkMainColorLabel, GrayLabel } from 'styles/styled-components';
import { StyledButtonToolbar } from '../AddNewUserModal';
import * as yup from 'yup';
import { AdminContactPerson, AdminUser } from 'api/admin';
import { RadioButtons } from 'components/shared/RadioButtons/RadioButtons';
import { LocalError } from 'components/shared/LocalError/LocalError';
import { useTranslation } from 'react-i18next';
import { AddNewUserBasicForm, AdminSapSupplier } from 'api/shared/types';

interface ContractorsNamesModalBodyProps {
    basicPersonalData?: AddNewUserBasicForm;
    handleCloseModal: () => void;
    supplier: AdminSapSupplier | undefined;
}

export const ContractorsNamesModalBody: React.FC<ContractorsNamesModalBodyProps> = props => {
    const { basicPersonalData, handleCloseModal, supplier } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { entities: contactPersons, loading } = useSelector<
        AppState,
        BasicArrayStateType<AdminContactPerson>
    >(state => state.admin.contactPersons);
    const { error } = useSelector<AppState, BasicArrayStateType<AdminUser>>(
        state => state.admin.users
    );
    const [sapContactPersons, setSapContactPersons] = useState<[] | AdminContactPerson[]>([]);

    useEffect(() => {
        if (supplier) {
            dispatch(getSapContactPersons(supplier.cardCode));
        }
    }, []);

    useEffect(() => {
        setSapContactPersons(contactPersons);
    }, [contactPersons]);

    const handleSearch = (value: string) => {
        const filteredSuppliers = contactPersons.filter(s =>
            `${s.firstName} ${s.lastName}`?.toLowerCase().includes(value.toLowerCase())
        );
        setSapContactPersons(filteredSuppliers);
    };

    const handleOkClick = () => {
        handleCloseModal();
    };

    const handleOnSubmit = async (values: { contactPersonId: number }) => {
        if (basicPersonalData && supplier) {
            const response = await dispatch(
                addNewUser({
                    ...basicPersonalData,
                    suppliersList: [supplier],
                    contactPersonId: values.contactPersonId,
                })
            );
            if (!(response.payload as any).error) {
                handleCloseModal();
            }
        }
    };

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                contactPersonId: yup.number().min(1, t('theFieldIsRequired')),
            }),
        []
    );

    return (
        <Container>
            <SpinnerMask show={loading}>
                <CenterRow className='pb-3'>
                    <GrayLabel>{t('bindWithSAPContractor')}</GrayLabel>
                </CenterRow>
                {contactPersons.length > 0 ? (
                    <>
                        <Formik
                            initialValues={{ contactPersonId: 0 }}
                            onSubmit={handleOnSubmit}
                            validationSchema={validationSchema}
                        >
                            {({ handleSubmit, errors, values, setFieldValue }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <RadioButtons
                                        name='contactPersonId'
                                        radios={sapContactPersons.map(s => ({
                                            content: `${s.firstName} ${s.lastName}`,
                                            value: s.contactPersonId,
                                        }))}
                                        vertical
                                        error={errors.contactPersonId}
                                        value={values.contactPersonId}
                                        onChoose={value => setFieldValue('contactPersonId', value)}
                                    />
                                    <StyledButtonToolbar className='py-4'>
                                        <Button variant='primary' type='submit'>
                                            {t('save')}
                                        </Button>
                                    </StyledButtonToolbar>
                                </Form>
                            )}
                        </Formik>
                        <LocalError appError={error} />
                        <div className='pb-2'>
                            <SearchInput onSearch={handleSearch} />
                        </div>
                    </>
                ) : (
                    <>
                        <CenterRow className='py-4'>
                            <DarkMainColorLabel>{t('addContactPerson')}</DarkMainColorLabel>
                        </CenterRow>
                        <StyledButtonToolbar className='py-4'>
                            <Button variant='primary' type='button' onClick={handleOkClick}>
                                {t('ok')}
                            </Button>
                        </StyledButtonToolbar>
                    </>
                )}
            </SpinnerMask>
        </Container>
    );
};
