import React from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';

interface OutputProps {
    content: string | number;
    label: string;
}

const StyledLabel = styled(Form.Label)`
    font-size: 1vw;
    color: ${variables.darkMainColor};
    font-weight: 600;
`;

const StyledContent = styled.div`
    font-size: 1vw;
    color: ${variables.lightMainColor};
    font-weight: 700;
    min-height: ${variables.defaultLineHeight};
`;

export const Output: React.FC<OutputProps> = props => {
    const { content, label } = props;
    return (
        <Form.Group>
            <StyledLabel>{label}</StyledLabel>
            <StyledContent>{content}</StyledContent>
        </Form.Group>
    );
};
