import React, { useMemo, useState } from 'react';
import { Button } from 'components/shared/Button/Button';
import { Input } from 'components/shared/Input/Input';
import { Formik } from 'formik';
import { ButtonToolbar, Container, Form } from 'react-bootstrap';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import * as yup from 'yup';
import { MainPage } from '../MainPage/MainPage';
import { passwordRequestReset } from 'api/auth';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { MessageModal } from 'components/shared/MessageModal/MessageModal';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';

const StyledLayout = styled(Container)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    position: absolute;
    width: 28%;
    height: 35%;
    left: 22%;
    top: 50%;
    background: #fefefe;
    border-radius: 34px;
    border: 2px solid ${variables.darkMainColor};
    padding: 0 3vw;
`;

const StyledForm = styled(Form)`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 70%;
`;

export const ResetPasswordRequest: React.FC = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const passwordReset = useAsync({
        deferFn: ([email]) => passwordRequestReset(email),
        onResolve: () => {
            setShowModal(true);
        },
        onReject: () => {
            setShowModal(false);
        },
    });
    const handleOnSubmit = (values: { email: string }) => {
        passwordReset.run(values.email);
    };

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                email: yup
                    .string()
                    .email(t('incorrectEmailFormat'))
                    .required(t('theFieldIsRequired')),
            }),
        []
    );

    return (
        <MainPage>
            <MessageModal message={t('resetLink')} show={showModal} setShowModal={setShowModal} />
            <SpinnerMask show={passwordReset.isPending}>
                <StyledLayout>
                    <Formik
                        initialValues={{
                            email: '',
                        }}
                        onSubmit={handleOnSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ handleSubmit }) => (
                            <StyledForm noValidate onSubmit={handleSubmit}>
                                <div className='py-2'>
                                    <Input name='email' label={t('email')} />
                                </div>
                                <ButtonToolbar className='px-5'>
                                    <Button variant='primary' type='submit'>
                                        {t('resetPassword')}
                                    </Button>
                                </ButtonToolbar>
                            </StyledForm>
                        )}
                    </Formik>
                </StyledLayout>
            </SpinnerMask>
        </MainPage>
    );
};
