import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    loginRequest,
    LoginData,
    ResetPasswordInput,
    resetPasswordRequest,
    RefreshTokenInput,
    refreshTokenRequest,
} from 'api/auth';
import { ChangePasswordData, changePasswordRequest } from 'api/user';
import { AxiosError } from 'axios';
import { prepareErrorType } from 'components/shared/LocalError/helpers';
import { AppError } from 'store/types';

export const login = createAsyncThunk(
    'auth/login',
    async (loginData: LoginData, { rejectWithValue }) => {
        try {
            const response = await loginRequest(loginData);
            return response.data;
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);

export const changePassword = createAsyncThunk(
    'auth/changePassword',
    async (data: ChangePasswordData, { rejectWithValue }) => {
        try {
            const response = await changePasswordRequest(data);
            return response;
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);

export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async (input: ResetPasswordInput, { rejectWithValue }) => {
        try {
            await resetPasswordRequest(input);
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);

export const refreshToken = createAsyncThunk(
    'auth/refreshToken',
    async (input: RefreshTokenInput, { rejectWithValue }) => {
        try {
            const response = await refreshTokenRequest(input);
            return response.data;
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);
