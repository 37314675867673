import React from 'react';
import styled from 'styled-components';

import { Header } from 'components/desktop/Header/Header';
import { Outlet } from 'react-router-dom';

const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
`;

const StyledMain = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const QualityControllerHome: React.FC = () => {
    return (
        <StyledLayout>
            <Header />
            <StyledMain>
                <Outlet />
            </StyledMain>
        </StyledLayout>
    );
};
