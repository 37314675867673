import { api } from './shared/instance';
import { AddOrderComment, OrderComment, Supplier, UserPersonalData } from './shared/types';

export interface ChangePasswordData {
    password: string;
    passwordOld: string;
    passwordConfirm: string;
}

const baseUrl = 'me';

export const getPersonalDataRequest = async () => {
    return api.get<UserPersonalData>(`${baseUrl}`);
};

export const getAvatarRequest = async () => {
    return api.get<string>(`${baseUrl}/userAvatar`);
};

export const updateAvatarRequest = async (newAvatar: FormData) => {
    return api.patch<string>(`${baseUrl}/userAvatar`, newAvatar, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const changePasswordRequest = async (data: ChangePasswordData) => {
    return api.patch(`${baseUrl}/password`, data);
};

export const getUserSuppliersRequest = async () => {
    return api.get<Array<Supplier>>(`${baseUrl}/suppliers`);
};

export const editPersonalDataRequest = async (
    data: Pick<UserPersonalData, 'preferedLanguage' | 'login' | 'fullUserName'>
) => {
    return api.patch(`${baseUrl}/personalData`, data);
};

export const addCommentRequest = async (comment: AddOrderComment, orderId: number) => {
    return api.post<OrderComment>(`${baseUrl}/orderComment`, comment, { params: { orderId } });
};

export const editCommentRequest = async (content: string, orderCommentId: number) => {
    return api.patch(`${baseUrl}/orderComment/${orderCommentId}`, content, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const deleteCommentRequest = async (orderCommentId: number) => {
    return api.delete(`${baseUrl}/orderComment/${orderCommentId}`);
};

export const getOrderCommentsRequest = async (orderId: number) => {
    return api.get<OrderComment[]>(`${baseUrl}/orderComment/${orderId}`);
};

export const updateOrderCommentsReadStatusRequest = async (
    userId: number,
    orderId: number,
    commentsIds: number[]
) => {
    return api.patch(`${baseUrl}/orderCommentReadStatuses`, { userId, orderId, commentsIds });
};

export const getOrderCommentsStatsRequest = async (orderIds: number[]) => {
    return api.get<
        {
            commentsAmount: number;
            isCommentsRead: boolean;
            orderId: number;
        }[]
    >(`${baseUrl}/orderCommentStats?ordersIds=${orderIds}`);
};
