export interface FileType {
    id: number;
    fileName: string;
    fileType: string;
}

export interface QualityControl {
    id: number;
    qualityProblem: boolean;
    sortNeed: boolean;
    itemsChecked: number;
    itemsRejected: number;
    itemsCheckedPercent: number;
    orderItemId: number;
    qualityControlFiles: Array<FileType>;
    createdAt: string;
}

export interface OrderRealization {
    id: number;
    quantity: number;
    readiness: string | null;
    expectedReadiness: string | null;
    realReadiness: string | null;
    orderItemId: number;
}

export interface OrderComment {
    id: number;
    comment: string;
    avatar: string | null;
    login: string;
    parentId: number;
    createdAt: string;
    isVisible: boolean;
    userId: number;
}

export interface AddOrderComment {
    comment: string;
    parentId: number;
}

export interface OrderItem {
    id: number;
    index: string;
    name: string;
    quantity: number;
    expectedReadiness?: string;
    destination: string;
    qualityResult: boolean | null;
    orderFullfillment: number;
    orderFullfillmentPercent: string;
    realizations: Array<OrderRealization>;
    qualityControls: Array<QualityControl>;
    orderItemFiles: Array<FileType>;
}

export interface Order {
    _id: number;
    id: number;
    number: string;
    supplierOrderNumber: string;
    cardCode: string;
    currency: string;
    orderItems: Array<OrderItem>;
    commentsNumber: number;
}

export interface OrdersResponse {
    items: Array<Order>;
    total: 0;
    totalFiltered: 0;
}

export interface OrdersRequestOptionalInput {
    searchText?: string[] | string;
    orderNumber?: boolean;
    supplierOrderNumber?: boolean;
    productIndex?: boolean;
    productName?: boolean;
    notCompleted?: boolean;
    expectedReadinessDateFrom?: string;
    expectedReadinessDateTo?: string;
    widgetSearchType?: WidgetSearchType;
}

export interface OrdersRequestSortingInput {
    sortByDate?: 0 | 1;
    sortByNumber?: 0 | 1;
}

export type OrdersRequestInputCommon = {
    limit: number;
    offset: number;
} & OrdersRequestOptionalInput &
    OrdersRequestSortingInput;
export type OrdersRequestInput =
    | OrdersRequestInputCommon
    | (OrdersRequestInputCommon & { cardCode: string });

export interface Supplier {
    id: number;
    name: string;
    cardCode: string;
}

export enum OrderAutocompleteType {
    Orders = 1,
    ProductIndex,
    ProductName,
}

export enum WidgetSearchType {
    OrdersWithExceededDeadline = 1,
    OrdersReadyAfter7days,
    OrdersReadyAfter14Days,
    OrdersReadyAfter21days,
}

export interface QualityControlResults {
    qualityControlsApproved: number;
    qualityControlsRejected: number;
    qualityControlsAll: number;
    approvedRejectedPercentage: string;
}

export interface OnTimeDelivery {
    ordersDelayed: number;
    ordersOnTime: number;
    ordersTotal: number;
    ordersDelayedOrdersPercent: string;
}

export interface WidgetsDates {
    dateFrom: string | null;
    dateTo: string | null;
}

export interface OpenOrder {
    ordersExceededDeadline: number;
    ordersReadyAfter7Days: number;
    ordersReadyAfter14Days: number;
    ordersReadyAfter21Days: number;
}

export interface Notification {
    id: number;
    orderId: number;
    orderNumber: string;
    userId: number;
    supplierName: string;
}

export interface OrderChange {
    batchNumber: number;
    index: string;
    field: string;
    oldValue: string;
    newValue: string;
    userName: string;
    createTime: string;
}

export enum LanguageEnum {
    Polish = 1,
    English = 2,
    Chinese = 3,
}

export interface UserPersonalData {
    fullUserName: string;
    email: string;
    login: string;
    preferedLanguage: LanguageEnum;
    avatar?: string;
}

export type FilterType<T> = {
    value: string | string[];
    type: T;
};
export interface EditAdminUserForm {
    userId: number;
    userName: string;
    login: string;
    email: string;
    resetPassword: boolean;
    isActive: boolean;
    roleId: number;
    avatar?: File;
    lockoutEnabled: boolean;
    suppliers: Omit<Supplier, 'name'>[];
}

export interface AddNewUserBasicForm {
    username: string;
    login: string;
    password: string;
    email: string;
    roleId: number;
}

export interface AdminSapSupplier {
    cardCode: string;
    cardName: string;
}

export type AddNewUserForm = AddNewUserBasicForm & {
    suppliersList: Array<AdminSapSupplier>;
    contactPersonId: number;
};

export type EditAdminUserBasicForm = Omit<EditAdminUserForm, 'suppliers'>;

export interface ModalState {
    show: boolean;
    closeModal: () => void;
}
