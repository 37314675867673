import React from 'react';
import styled from 'styled-components';
import ReactPagination from 'react-bootstrap/Pagination';
import variables from 'styles/variables.module.scss';
import { isString, range } from 'lodash';

const CenterPagination = styled(ReactPagination)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 5px;
    flex-wrap: wrap;
`;

const StyledPaginationItem = styled(ReactPagination.Item)`
    .page-link {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f6f6f6;
        color: ${variables.darkMainColor};
    }

    .active {
        .page-link {
            background: ${variables.darkMainColor};
        }
    }

    &:first-child {
        .page-link {
            border-radius: 30px 0 0 30px;
        }
    }

    &:last-child {
        .page-link {
            border-radius: 0 30px 30px 0;
        }
    }
`;

const StyledPaginationNext = styled(ReactPagination.Next)`
    border-radius: 0 30px 30px 0;
    .page-link {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f6f6f6;
        color: ${variables.darkMainColor};
    }

    .active {
        .page-link {
            background: ${variables.darkMainColor};
        }
    }

    &:last-child {
        .page-link {
            border-radius: 0 30px 30px 0;
        }
    }
`;

const StyledEllipsis = styled(ReactPagination.Ellipsis)`
    .page-link {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f6f6f6;
        color: ${variables.darkMainColor};
    }
`;

const { floor, min, max } = Math;

const getPaginationRange = (count: number) => (page: number, total: number) => {
    const start = max(1, min(page - floor((count - 3) / 2), total - count + 2));
    const end = min(total, max(page + floor((count - 2) / 2), count - 1));
    return [
        ...(start > 2 ? [1, '...'] : start > 1 ? [1] : []),
        ...range(start, end + 1),
        ...(end < total - 1 ? ['...', total] : end < total ? [total] : []),
    ];
};

interface PaginationProps {
    handlePageChange: (pageNr: number) => void;
    currentPageIndex: number;
    pageCount: number;
}

export const Pagination: React.FC<PaginationProps> = props => {
    const { handlePageChange, currentPageIndex, pageCount } = props;
    const pagesArray = getPaginationRange(7)(currentPageIndex, pageCount);
    return (
        <CenterPagination>
            {pagesArray.map((value, index) => {
                return isString(value) ? (
                    <StyledEllipsis key={value + index} />
                ) : (
                    <StyledPaginationItem
                        key={value}
                        active={value === currentPageIndex}
                        onClick={() => handlePageChange(value)}
                    >
                        {value}
                    </StyledPaginationItem>
                );
            })}
            {pageCount > currentPageIndex && (
                <StyledPaginationNext onClick={() => handlePageChange(currentPageIndex + 1)} />
            )}
        </CenterPagination>
    );
};
