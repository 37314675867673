import { selectAdminErrors } from './admin/selectors';
import { selectAuthErrors } from './auth/selectors';
import { selectEmployeeErrors } from './employee/selectors';
import { selectQualityControllerErrors } from './qualityController/selectors';
import { selectSupplierErrors } from './supplier/selectors';
import { AppError, AppState } from './types';
import { selectUserErrors } from './user/selectors';

export const selectErrors = (state: AppState): AppError[] =>
    state.errors.filter(e => e.type === 'NONE');

const selectAllErrors = (state: AppState): AppError[] =>
    selectAdminErrors(state)
        .concat(selectAuthErrors(state))
        .concat(selectSupplierErrors(state))
        .concat(selectQualityControllerErrors(state))
        .concat(selectEmployeeErrors(state))
        .concat(selectUserErrors(state))
        .concat(selectErrors(state));

export const isGlobalErrorOccurredSelector = (state: AppState): boolean =>
    selectAllErrors(state).filter(e => e.type === 'NONE').length > 0;
