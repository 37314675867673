import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminContactPerson, AdminUser, Role } from 'api/admin';
import { Supplier, AdminSapSupplier } from 'api/shared/types';
import { AppError, BasicArrayStateType } from 'store/types';
import {
    getUsers,
    getRoles,
    getSapSuppliers,
    addNewUser,
    getSapContactPersons,
    getSuppliers,
    editUser,
} from './operations';

export interface AdminState {
    users: BasicArrayStateType<AdminUser>;
    roles: BasicArrayStateType<Role>;
    sapSuppliers: BasicArrayStateType<AdminSapSupplier>;
    contactPersons: BasicArrayStateType<AdminContactPerson>;
    suppliers: BasicArrayStateType<Supplier>;
}

const initialState: AdminState = {
    users: {
        entities: [],
        error: null,
        loading: false,
    },
    roles: {
        entities: [],
        error: null,
        loading: false,
    },
    sapSuppliers: {
        entities: [],
        error: null,
        loading: false,
    },
    contactPersons: {
        entities: [],
        error: null,
        loading: false,
    },
    suppliers: {
        entities: [],
        error: null,
        loading: false,
    },
};

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        resetUserError(state: AdminState) {
            state.users.error = null;
        },
    },
    extraReducers: {
        [getUsers.pending.type]: (state: AdminState) => {
            state.users.loading = true;
            state.users.error = null;
        },
        [getUsers.fulfilled.type]: (state: AdminState, action: PayloadAction<Array<AdminUser>>) => {
            state.users.entities = action.payload;
            state.users.loading = false;
            state.users.error = null;
        },
        [getUsers.rejected.type]: (state: AdminState, action: PayloadAction<AppError>) => {
            state.users.entities = [];
            state.users.loading = false;
            state.users.error = action.payload;
        },

        [addNewUser.pending.type]: (state: AdminState) => {
            state.users.loading = true;
            state.users.error = null;
        },
        [addNewUser.fulfilled.type]: (
            state: AdminState,
            action: PayloadAction<Array<AdminUser>>
        ) => {
            state.users.entities = action.payload;
            state.users.loading = false;
            state.users.error = null;
        },
        [addNewUser.rejected.type]: (state: AdminState, action: PayloadAction<AppError>) => {
            state.users.loading = false;
            state.users.error = action.payload;
        },

        [getRoles.pending.type]: (state: AdminState) => {
            state.roles.loading = true;
            state.roles.error = null;
        },
        [getRoles.fulfilled.type]: (state: AdminState, action: PayloadAction<Array<Role>>) => {
            state.roles.entities = action.payload;
            state.roles.loading = false;
            state.roles.error = null;
        },
        [getRoles.rejected.type]: (state: AdminState, action: PayloadAction<AppError>) => {
            state.roles.entities = [];
            state.roles.loading = false;
            state.roles.error = action.payload;
        },

        [getSapSuppliers.pending.type]: (state: AdminState) => {
            state.sapSuppliers.loading = true;
            state.sapSuppliers.error = null;
        },
        [getSapSuppliers.fulfilled.type]: (
            state: AdminState,
            action: PayloadAction<Array<AdminSapSupplier>>
        ) => {
            state.sapSuppliers.entities = action.payload;
            state.sapSuppliers.loading = false;
            state.sapSuppliers.error = null;
        },
        [getSapSuppliers.rejected.type]: (state: AdminState, action: PayloadAction<AppError>) => {
            state.sapSuppliers.entities = [];
            state.sapSuppliers.loading = false;
            state.sapSuppliers.error = action.payload;
        },

        [getSuppliers.pending.type]: (state: AdminState) => {
            state.suppliers.loading = true;
            state.suppliers.error = null;
        },
        [getSuppliers.fulfilled.type]: (
            state: AdminState,
            action: PayloadAction<Array<Supplier>>
        ) => {
            state.suppliers.entities = action.payload;
            state.suppliers.loading = false;
            state.suppliers.error = null;
        },
        [getSuppliers.rejected.type]: (state: AdminState, action: PayloadAction<AppError>) => {
            state.suppliers.entities = [];
            state.suppliers.loading = false;
            state.suppliers.error = action.payload;
        },

        [getSapContactPersons.pending.type]: (state: AdminState) => {
            state.contactPersons.loading = true;
            state.contactPersons.error = null;
        },
        [getSapContactPersons.fulfilled.type]: (
            state: AdminState,
            action: PayloadAction<Array<AdminContactPerson>>
        ) => {
            state.contactPersons.entities = action.payload;
            state.contactPersons.loading = false;
            state.contactPersons.error = null;
        },
        [getSapContactPersons.rejected.type]: (
            state: AdminState,
            action: PayloadAction<AppError>
        ) => {
            state.contactPersons.entities = [];
            state.contactPersons.loading = false;
            state.contactPersons.error = action.payload;
        },

        [editUser.pending.type]: (state: AdminState) => {
            state.users.loading = true;
            state.users.error = null;
        },
        [editUser.fulfilled.type]: (state: AdminState, action: PayloadAction<Array<AdminUser>>) => {
            state.users.entities = action.payload;
            state.users.loading = false;
            state.users.error = null;
        },
        [editUser.rejected.type]: (state: AdminState, action: PayloadAction<AppError>) => {
            state.users.loading = false;
            state.users.error = action.payload;
        },
    },
});

export const { resetUserError } = adminSlice.actions;

export default adminSlice.reducer;
