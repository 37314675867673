import { AddOrderComment } from 'api/shared/types';
import { Button } from 'components/shared/Button/Button';
import React, { ChangeEvent, useState } from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GrayTextArea } from 'styles/styled-components';

interface AddCommentProps {
    handleAddComment: (comment: AddOrderComment) => Promise<void>;
    parentId: number;
}

export const AddComment: React.FC<AddCommentProps> = props => {
    const { handleAddComment, parentId } = props;
    const [comment, setComment] = useState('');
    const { t } = useTranslation();
    const handleChangeComment = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setComment(event.target.value);
    };

    return (
        <div className='d-flex flex-column'>
            <GrayTextArea
                as='textarea'
                rows={3}
                value={comment}
                onChange={handleChangeComment}
            ></GrayTextArea>
            <ButtonToolbar className='my-2 px-8'>
                <Button variant='primary' onClick={() => handleAddComment({ comment, parentId })}>
                    {t('save')}
                </Button>
            </ButtonToolbar>
        </div>
    );
};
