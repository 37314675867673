import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { Order, OrderItem, OrderRealization, QualityControl } from 'api/shared/types';
import {
    NormalizedOrderItems,
    NormalizedOrderRealizations,
    NormalizedOrders,
    NormalizedQualityControls,
} from 'store/shared/types';
import { AppError, AppState, BasicStateType } from 'store/types';
import { isDefined, isNotNull } from 'utils/helpers';
import { EmployeeState } from './slice';

export const selectEmployeeErrors = (state: AppState): AppError[] =>
    Object.values(selectEmployeeState(state))
        .map<AppError | null>(v => (typeof v !== 'number' && 'error' in v ? v.error : null))
        .filter(isNotNull)
        .filter(isDefined);

export const selectEmployeeState = (state: AppState): EmployeeState => state.employee;

export const selectSuppliersState = (state: AppState) => selectEmployeeState(state).suppliers;

export const selectOrdersState = (state: AppState): BasicStateType<NormalizedOrders> =>
    selectEmployeeState(state).orders;

export const selectOrderItemsState = (state: AppState): BasicStateType<NormalizedOrderItems> =>
    selectEmployeeState(state).orderItems;

export const selectQualityControlsState = (
    state: AppState
): BasicStateType<NormalizedQualityControls> => selectEmployeeState(state).qualityControls;

const selectOrders = (state: AppState): NormalizedOrders | null =>
    selectOrdersState(state).entities;

const selectOrderItems = (state: AppState): NormalizedOrderItems | null =>
    selectOrderItemsState(state).entities;

const selectQualityControls = (state: AppState): NormalizedQualityControls | null =>
    selectQualityControlsState(state).entities;

const selectOrderRealizations = (state: AppState): NormalizedOrderRealizations | null =>
    selectOrderRealizationsState(state).entities;

const realizationsSelector = createDraftSafeSelector(
    selectOrderRealizations,
    (state: AppState, { orderItemId }: { orderItemId: number }) => orderItemId,
    (normalizedOrderRealizations, orderItemId) => {
        if (normalizedOrderRealizations === null) return [];
        return Object.values(normalizedOrderRealizations)
            .map<OrderRealization>(o => ({ ...o }))
            .filter(o => o.orderItemId === orderItemId);
    }
);

export const selectOrderRealizationsState = (
    state: AppState
): BasicStateType<NormalizedOrderRealizations> => selectEmployeeState(state).realizations;

const qualityControlsSelector = createDraftSafeSelector(
    selectQualityControls,
    (state: AppState, { orderItemId }: { orderItemId: number }) => orderItemId,
    (normalizedQualityControls, orderItemId) => {
        if (normalizedQualityControls === null) return [];
        return Object.values(normalizedQualityControls)
            .map<QualityControl>(o => ({ ...o }))
            .filter(o => o.orderItemId === orderItemId);
    }
);

export const qualityControlSelector = createDraftSafeSelector(
    selectQualityControls,
    (state: AppState, { qualityControlId }: { qualityControlId?: number }) => qualityControlId,
    (normalizedQualityControls, qualityControlId) => {
        if (normalizedQualityControls === null || !qualityControlId) return null;
        return (
            Object.values(normalizedQualityControls)
                .map<QualityControl>(o => ({ ...o }))
                .find(o => o.id === qualityControlId) ?? null
        );
    }
);

export const orderItemSelector = createDraftSafeSelector(
    selectOrderItems,
    (state: AppState, { orderItemId }: { orderItemId: number }) => ({ orderItemId, state }),
    (normalizedOrderItems, { orderItemId, state }) => {
        if (normalizedOrderItems === null) return null;
        const normalizedOrderItem = Object.values(normalizedOrderItems).find(
            o => o.id === orderItemId
        );
        if (normalizedOrderItem) {
            const { id, qualityControls, realizations, orderId: i, ...rest } = normalizedOrderItem;
            const orderItem: OrderItem = {
                ...rest,
                id,
                realizations: realizationsSelector(state, { orderItemId: id }),
                qualityControls: qualityControlsSelector(state, { orderItemId: id }),
            };
            return orderItem;
        }
        return null;
    }
);

const orderItemsSelector = createDraftSafeSelector(
    selectOrderItems,
    (state: AppState, { orderId }: { orderId: number }) => ({ orderId, state }),
    (normalizedOrderItems, { orderId, state }) => {
        if (normalizedOrderItems === null) return [];
        return Object.values(normalizedOrderItems)
            .filter(o => o.orderId === orderId)
            .map<OrderItem>(({ id, qualityControls, realizations, orderId: i, ...rest }) => ({
                ...rest,
                id,
                realizations: realizationsSelector(state, { orderItemId: id }),
                qualityControls: qualityControlsSelector(state, { orderItemId: id }),
            }));
    }
);

export const ordersSelector = createDraftSafeSelector(
    selectOrders,
    (state: AppState) => state,
    (normalizedOrders, state) => {
        if (normalizedOrders === null) return [];
        return Object.values(normalizedOrders).map<Order>(({ id, orderItems, ...rest }) => ({
            ...rest,
            id,
            orderItems: orderItemsSelector(state, { orderId: id }),
        }));
    }
);
