import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppError } from 'store/types';

const initialState: AppError[] = [];

export const errors = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        addError(state: AppError[], action: PayloadAction<AppError>) {
            state.push(action.payload);
            return state;
        },
        removeError(state: AppError[], action: PayloadAction<AppError>) {
            return [];
        },
    },
    extraReducers: {},
});

export const { addError, removeError } = errors.actions;

export default errors.reducer;
