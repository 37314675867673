export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_TIME_FORMAT = 'dd.MM.yyyy, HH:mm';

// mime types
export const textFileMimeTypes =
    'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const imageMimeTypes = 'image/jpeg,image/png,image/gif,image/bmp,image/x-png';
export const compressedMimeTypes =
    'application/zip,application/x-7z-compressed,application/x-zip-compressed';

export const availableMimeTypes = [imageMimeTypes, textFileMimeTypes, compressedMimeTypes] as const;

export const DEFAULT_LNG_SYMBOL = 'en';

export const MAX_FILE_SIZE_IN_MB = 50;
