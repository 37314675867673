import React, { useEffect, useState } from 'react';
import person from 'assets/images/person.svg';
import bell from 'assets/images/desktop_bell.svg';
import { IconButton } from 'components/shared/IconButton/IconButton';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import { IfRole } from 'components/shared/IfRole/IfRole';
import { Roles } from 'api/auth';
import { NotificationsModal } from '../NotificationsModal/NotificationsModal';
import { getNotificationsRequest } from 'api/notifications';
import { useAsync } from 'react-async';
import { UserInfoModal } from '../UserInfoModal/UserInfoModal';

const StyledHeader = styled.header`
    display: flex;
    justify-content: flex-end;
    background-image: ${variables.mainGradientColor};
    padding: 1vh 3vw;
`;

const OverlayNotificationsCount = styled.div`
    position: absolute;
    left: 69%;
    top: 1%;
    bottom: 43.3%;

    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
`;

export const Header: React.FC = () => {
    const [showFilesModal, setShowFilesModal] = useState(false);
    const [showUserInfoModal, setUserInfoModal] = useState(false);
    const [notificationsLength, setNotificationsLength] = useState(0);
    const { isPending, data, reload } = useAsync({
        promiseFn: getNotificationsRequest,
    });

    const notifications = data?.data ?? [];

    useEffect(() => {
        setNotificationsLength(notifications.length);
    }, [notifications]);

    const handlePersonClick = () => {
        setUserInfoModal(true);
    };

    const handleBellClick = () => {
        setShowFilesModal(true);
    };

    return (
        <StyledHeader>
            <IfRole roles={[Roles.GtvEmployee]}>
                <span className='px-4'>
                    <IconButton
                        ariaLabel='notifications'
                        image={bell}
                        onClick={handleBellClick}
                        size='lg'
                        disabled={isPending}
                        overlay={
                            <OverlayNotificationsCount>
                                {notificationsLength}
                            </OverlayNotificationsCount>
                        }
                    />
                    <NotificationsModal
                        show={showFilesModal}
                        setShowModal={setShowFilesModal}
                        notifications={notifications}
                        reloadNotifications={reload}
                        isNotificationsLoading={isPending}
                    />
                </span>
            </IfRole>
            <IconButton
                image={person}
                onClick={handlePersonClick}
                size='lg'
                ariaLabel='user-info'
            />
            <UserInfoModal show={showUserInfoModal} setShowModal={setUserInfoModal} />
        </StyledHeader>
    );
};
