import React, { CSSProperties, SyntheticEvent, useState } from 'react';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { DarkMainColorLabel } from 'styles/styled-components';
import variables from 'styles/variables.module.scss';
import i18n from 'i18next';
import { UserModification } from 'api/admin';
import { ISOToFormattedStringDate } from 'utils/helpers';
import { UserModificationsDetailModal } from '../UserModificationsDetailModal/UserModificationsDetailModal';
import { useTranslation } from 'react-i18next';

const headerDefaultStyle: CSSProperties = {
    textAlign: 'center',
    color: variables.lightMainColor,
    fontWeight: 400,
    backgroundColor: 'white',
};

const getColumns = (): Array<ColumnDescription<UserModification>> => {
    return [
        {
            dataField: 'changedAt',
            text: `${i18n.t('date')}`,
            headerStyle: () => headerDefaultStyle,
            formatter: (cell: boolean, row: UserModification) => (
                <div className='text-center'>
                    <DarkMainColorLabel>
                        {ISOToFormattedStringDate(row.changedAt)}
                    </DarkMainColorLabel>
                </div>
            ),
        },
        {
            dataField: 'orderNumber',
            text: `${i18n.t('orderNumber')}`,
            headerStyle: () => headerDefaultStyle,
            formatter: (cell: boolean, row: UserModification) => (
                <div className='text-center'>
                    <DarkMainColorLabel>{row.orderNumber}</DarkMainColorLabel>
                </div>
            ),
        },
        {
            dataField: 'supplierOrderNumber',
            text: `${i18n.t('supplierOrderNumber')}`,
            headerStyle: () => headerDefaultStyle,
            formatter: (cell: boolean, row: UserModification) => (
                <div className='text-center'>
                    <DarkMainColorLabel>{row.supplierOrderNumber}</DarkMainColorLabel>
                </div>
            ),
        },
        {
            dataField: 'index',
            text: `${i18n.t('index')}`,
            headerStyle: () => headerDefaultStyle,
            formatter: (cell: boolean, row: UserModification) => (
                <div className='text-center'>
                    <DarkMainColorLabel>{row.index}</DarkMainColorLabel>
                </div>
            ),
        },
    ];
};

const SIZE_PER_PAGE = 10;

interface OrderChangesTableProps {
    userChanges: Array<UserModification>;
    userId?: number;
}

export const UserChangesTable: React.FC<OrderChangesTableProps> = ({ userChanges, userId }) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [userModificationToShow, setUserModificationToShow] = useState<UserModification>();
    let additionalProps = {};
    if (userId) {
        additionalProps = { rowClasses: 'userChangesTable-row' };
    }
    return (
        <>
            <BootstrapTable
                keyField='id'
                columns={getColumns()}
                data={userChanges}
                bordered={false}
                rowEvents={{
                    onClick: (e: SyntheticEvent, row: UserModification) => {
                        setUserModificationToShow(row);
                        setShowModal(true);
                    },
                }}
                noDataIndication={() => t('noData')}
                pagination={paginationFactory({
                    sizePerPage: SIZE_PER_PAGE,
                    hidePageListOnlyOnePage: true,
                    hideSizePerPage: true,
                })}
                {...additionalProps}
            />
            {userId && userModificationToShow && (
                <UserModificationsDetailModal
                    show={showModal}
                    setShowModal={setShowModal}
                    userId={userId}
                    userChange={userModificationToShow}
                />
            )}
        </>
    );
};
