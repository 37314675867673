import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addError } from 'store/errors/slice';
import { AppDispatch } from 'store/types';

export const useAutocomplete = (
    getAutocompleteResults: (searchQuery: string) => Promise<string[]>
) => {
    const [autocompleteOptions, setAutocompleteOptions] = useState<string[]>([]);
    const [autocompleteLoading, setAutocompleteLoading] = useState<boolean>(false);
    const [phrases, setPhrases] = useState<string[]>();
    const dispatch = useDispatch<AppDispatch>();
    const handleSearch = useCallback(
        async (value: string) => {
            try {
                setAutocompleteLoading(true);
                const results = await getAutocompleteResults(value);
                setAutocompleteOptions(results);
                setAutocompleteLoading(false);
            } catch (error: unknown) {
                setAutocompleteLoading(false);
            }
        },
        [getAutocompleteResults]
    );

    const autocompleteProps = useMemo(
        () => ({
            onSearch: handleSearch,
            options: autocompleteOptions,
            loading: autocompleteLoading,
            selected: phrases,
            onChange: setPhrases,
        }),
        [autocompleteLoading, autocompleteOptions, phrases, handleSearch]
    );

    return autocompleteProps;
};
