import { OrderRealization } from 'api/shared/types';
import { Output } from 'components/shared/Output/Output';
import React from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EndRow } from 'styles/styled-components';
import { ISOToFormattedStringDate } from 'utils/helpers';
import { RealizationLabel } from '../RealizationList';

interface AddedRealizationProps {
    index: number;
    orderId: number;
    realization: OrderRealization;
}

export const AddedRealization: React.FC<AddedRealizationProps> = props => {
    const { index, realization } = props;
    const { t } = useTranslation();

    return (
        <EndRow className='py-4'>
            <Col md={1}>
                <RealizationLabel className='uppercase-text'>
                    {t('shipment')} {index}
                </RealizationLabel>
            </Col>
            <Col md={2}>
                <Output label={t('quantityOfShipment')} content={realization.quantity} />
            </Col>
            <Col md={2}>
                <Output
                    label={t('readinessDateOfShipment')}
                    content={ISOToFormattedStringDate(realization.readiness)}
                />
            </Col>
            <Col md={3}>
                <Output
                    label={t('expectedReadinessDeliveryOfShipment')}
                    content={ISOToFormattedStringDate(realization.expectedReadiness)}
                />
            </Col>
            <Col md={2}>
                <Output
                    label={t('realReadinessDeliveryOfShipment')}
                    content={ISOToFormattedStringDate(realization.realReadiness)}
                />
            </Col>
            <Col md={2}></Col>
        </EndRow>
    );
};
