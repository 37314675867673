import { useField } from 'formik';
import React from 'react';
import { Form, Row } from 'react-bootstrap';
import { StyledToggleButton, StyledToggleButtonGroup } from 'styles/styled-components';
import { useVirtualizer } from '@tanstack/react-virtual';

interface CheckboxListProps {
    checkboxes: Array<{ value: any; content: string }>;
    name: string;
}

export const CheckboxList: React.FC<CheckboxListProps> = props => {
    const { checkboxes, name } = props;
    const [field, meta, helpers] = useField(name);
    const handleChange = (value: unknown) => helpers.setValue(value);
    const parentRef = React.useRef();
    const rowVirtualizer = useVirtualizer({
        count: checkboxes.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 40,
    });
    return (
        <Form.Group as={Row}>
            <StyledToggleButtonGroup
                type='checkbox'
                value={field.value}
                name={name}
                onChange={handleChange}
                vertical
                ref={parentRef}
                style={{
                    height: `400px`,
                    overflow: 'auto',
                }}
            >
                {rowVirtualizer.getVirtualItems().map((virtualRow: any) => (
                    <StyledToggleButton
                        className='smallFont'
                        id={`toggleBtn-${checkboxes[virtualRow.index].value}`}
                        key={virtualRow.key}
                        value={checkboxes[virtualRow.index].value}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: `${virtualRow.size}px`,
                            transform: `translateY(${virtualRow.start}px)`,
                        }}
                    >
                        {checkboxes[virtualRow.index].content}
                    </StyledToggleButton>
                ))}
            </StyledToggleButtonGroup>
            {meta.error && (
                <Form.Control.Feedback type='invalid'>{meta.error}</Form.Control.Feedback>
            )}
        </Form.Group>
    );
};
