import React, { useMemo, useState } from 'react';
import { Modal } from 'components/shared/Modal/Modal';
import styled from 'styled-components';
import { Container, ButtonToolbar, Form, Row, Col } from 'react-bootstrap';
import { EditAdminUserBasicForm } from 'api/shared/types';
import { SuppliersModalBody } from '../SuppliersModalBody/SuppliersModalBody';
import { IconButton } from 'components/shared/IconButton/IconButton';
import back from 'assets/images/back.svg';
import { useDispatch, useSelector } from 'react-redux';
import { resetUserError } from 'store/admin/slice';
import { AdminUser, Role } from 'api/admin';
import { useTranslation } from 'react-i18next';
import { Input } from 'components/shared/Input/Input';
import { Button } from 'components/shared/Button/Button';
import * as yup from 'yup';
import { Formik } from 'formik';
import { rolesSelector } from 'store/admin/selectors';
import { AppDispatch, AppState } from 'store/types';
import { Roles } from 'api/auth';
import { editUser } from 'store/admin/operations';
import { AvatarEditor } from 'components/shared/AvatarEditor/AvatarEditor';
import { Toggle } from 'components/shared/Toggle/Toggle';
import { RoundedRadio } from 'components/shared/RoundedRadio/RoundedRadio';

interface EditUserModalProps {
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;

    user: AdminUser;
}

const StyledLayout = styled(Container)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
`;

export const StyledButtonToolbar = styled(ButtonToolbar)`
    width: 100%;
    padding: 0px 15%;
`;

export type ViewMode =
    | {
          type: 'FIRST_FORM';
      }
    | {
          type: 'SUPPLIERS';
      };

export const EditUserModal: React.FC<EditUserModalProps> = props => {
    const { show, setShowModal, user } = props;
    const [viewMode, setViewMode] = useState<ViewMode>({ type: 'FIRST_FORM' });
    const [basicPersonalData, setBasicPersonalData] = useState<EditAdminUserBasicForm>();
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const roles = useSelector<AppState, Array<Role> | []>(rolesSelector);
    const [isInvalidImage, setIsInvalidImage] = useState(false);
    const [avatar, setAvatar] = useState<string | undefined>(user.avatar);
    const [avatarFile, setAvatarFile] = useState<File>();

    const handleCloseModal = () => {
        setIsInvalidImage(false);
        setAvatar(user.avatar);
        setAvatarFile(undefined);
        dispatch(resetUserError());
        setShowModal(false);
        setViewMode({ type: 'FIRST_FORM' });
    };

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                login: yup.string().required(t('theFieldIsRequired')),
                userName: yup.string().required(t('theFieldIsRequired')),
                email: yup
                    .string()
                    .email(t('incorrectEmailFormat'))
                    .required(t('theFieldIsRequired')),
            }),
        []
    );

    const handleOnSubmit = async (values: EditAdminUserBasicForm) => {
        const role = roles.find(r => r.id === user.roleId);
        setBasicPersonalData({ ...values, avatar: avatarFile });
        if (role?.name === Roles.GtvEmployee || role?.name === Roles.QualityController) {
            setViewMode({ type: 'SUPPLIERS' });
        } else if (role?.name === Roles.Supplier || role?.name === Roles.Admin) {
            dispatch(editUser({ ...values, avatar: avatarFile, suppliers: [] }));
            handleCloseModal();
        }
    };

    let body = null;
    switch (viewMode.type) {
        case 'FIRST_FORM': {
            body = (
                <Container>
                    <AvatarEditor
                        setAvatar={setAvatar}
                        setAvatarFile={setAvatarFile}
                        setIsInvalidImage={setIsInvalidImage}
                        avatar={avatar}
                        position='relative'
                    />
                    {isInvalidImage && (
                        <Row className='text-center'>
                            <Form.Control.Feedback type='invalid'>
                                {t('incorrectImageFormat')}
                            </Form.Control.Feedback>
                        </Row>
                    )}
                    <Formik
                        initialValues={{
                            userId: user.id,
                            userName: user.userName,
                            login: user.login,
                            email: user.email,
                            isActive: user.isActive,
                            roleId: user.roleId,
                            resetPassword: false,
                            lockoutEnabled: user.lockoutEnabled,
                        }}
                        onSubmit={handleOnSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize
                    >
                        {({ handleSubmit, values, setFieldValue }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row className='py-2'>
                                    <Input name='userName' label={t('userName')} />
                                </Row>
                                <Row className='py-2'>
                                    <Input name='login' label={t('login')} />
                                </Row>
                                <Row className='py-2'>
                                    <Input name='email' label={t('email')} />
                                </Row>
                                <Row className='py-3'>
                                    <Toggle
                                        name='resetPassword'
                                        label={t('resetPassword')}
                                        value={values.resetPassword}
                                        onChange={() =>
                                            setFieldValue('resetPassword', !values.resetPassword)
                                        }
                                    />
                                </Row>
                                <Row className='py-2'>
                                    <Col className='no-padding'>
                                        <RoundedRadio
                                            name='lockoutEnabled'
                                            label={t('active')}
                                            value={false}
                                            onChange={() => setFieldValue('lockoutEnabled', false)}
                                            checked={!values.lockoutEnabled}
                                        />
                                    </Col>
                                    <Col className='no-padding'>
                                        <RoundedRadio
                                            name='lockoutEnabled'
                                            label={t('blocked')}
                                            value={true}
                                            onChange={() => setFieldValue('lockoutEnabled', true)}
                                            checked={values.lockoutEnabled}
                                        />
                                    </Col>
                                </Row>
                                <StyledButtonToolbar className='py-4'>
                                    <Button variant='primary' type='submit'>
                                        {t('save')}
                                    </Button>
                                </StyledButtonToolbar>
                            </Form>
                        )}
                    </Formik>
                </Container>
            );
            break;
        }
        case 'SUPPLIERS': {
            body = (
                <SuppliersModalBody
                    basicPersonalData={basicPersonalData}
                    handleCloseModal={handleCloseModal}
                />
            );
            break;
        }
    }
    const handleBackClick = () => {
        dispatch(resetUserError());
        switch (viewMode.type) {
            case 'SUPPLIERS':
                setViewMode({ type: 'FIRST_FORM' });
                break;
        }
    };
    const header =
        viewMode.type === 'FIRST_FORM' ? null : (
            <IconButton image={back} onClick={handleBackClick} />
        );
    return (
        <Modal show={show} handleClose={handleCloseModal} size='sm' headerChildren={header}>
            <StyledLayout>{body}</StyledLayout>
        </Modal>
    );
};
