import { FileType } from 'api/shared/types';
import { Modal } from 'components/shared/Modal/Modal';
import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { AttachmentSpan, CenterRow, GrayLabel, StyledStack } from 'styles/styled-components';
import attachment from 'assets/images/attachment.svg';
import download from 'assets/images/download.svg';
import { IconButton } from 'components/shared/IconButton/IconButton';

interface ShowFilesModalProps {
    show: boolean;
    files: FileType[];
    title: string;
    handleDownloadClick: () => Promise<void>;
    setShowFilesModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledLayout = styled(Container)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
`;

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    overflow: auto;
    max-height: 300px;
`;

export const ShowFilesModal: React.FC<ShowFilesModalProps> = props => {
    const { show, files, title, handleDownloadClick, setShowFilesModal } = props;

    const handleCloseModal = () => {
        setShowFilesModal(false);
    };

    return (
        <Modal show={show} handleClose={handleCloseModal}>
            <StyledLayout>
                <CenterRow className='pb-3'>
                    <GrayLabel>{title}</GrayLabel>
                </CenterRow>
                <StyledDiv>
                    <StyledStack className='pb-3' $maxHeight='300px'>
                        {files.map(f => (
                            <AttachmentSpan key={f.id} className='pb-2'>
                                <img src={attachment} alt='Attachment' width='30px' height='30px' />
                                {f.fileName}
                            </AttachmentSpan>
                        ))}
                    </StyledStack>
                </StyledDiv>
                <CenterRow className='pb-3'>
                    <IconButton image={download} onClick={handleDownloadClick} />
                </CenterRow>
            </StyledLayout>
        </Modal>
    );
};
