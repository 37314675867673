import { Supplier } from 'api/shared/types';
import { RadioButtons } from 'components/shared/RadioButtons/RadioButtons';
import { SearchInput } from 'components/shared/SearchInput/SearchInput';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, AppState, BasicArrayStateType } from 'store/types';
import styled from 'styled-components';
import { CenterRow } from 'styles/styled-components';
import routes from 'routes-desktop.json';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { getSuppliers } from 'store/employee/operations';
import { selectSuppliersState } from 'store/employee/selectors';

const StyledFirstRow = styled(Row)`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4vh;
    height: fit-content;
    background-color: rgba(246, 246, 246, 1);
`;

const SearchInputDiv = styled.div`
    display: flex;
    width: 30%;
`;

const SuppliersContainer = styled.div`
    width: 30%;
`;

export const EmployeeSuppliersList: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const { entities, loading } = useSelector<AppState, BasicArrayStateType<Supplier>>(
        selectSuppliersState
    );
    const navigate = useNavigate();
    const [cardCode, setCardCode] = useState<string | number>('');
    const fetchSuppliers = async () => {
        await dispatch(getSuppliers());
    };
    useEffect(() => {
        fetchSuppliers();
    }, []);

    const [suppliers, setSuppliers] = useState<[] | Supplier[]>([]);

    useEffect(() => {
        setSuppliers(entities);
    }, [entities]);

    const handleSearch = (value: string) => {
        const filteredSuppliers = entities.filter(s =>
            s.cardCode?.toLowerCase().includes(value.toLowerCase())
        );
        setSuppliers(filteredSuppliers);
    };
    const handleSupplierChoose = (value: string | number) => {
        setCardCode(value);
        navigate(`${routes.employee.children.dashboard.fullPath}/${value}`, {
            replace: true,
        });
    };
    return (
        <SpinnerMask show={loading}>
            <Container fluid>
                <StyledFirstRow>
                    <SearchInputDiv>
                        <SearchInput onSearch={handleSearch} iconAlignment='flex-end' />
                    </SearchInputDiv>
                </StyledFirstRow>
                <CenterRow $height='85%'>
                    <SuppliersContainer>
                        <RadioButtons
                            radios={suppliers.map(s => ({
                                value: s.cardCode,
                                content: s.cardCode,
                            }))}
                            onChoose={handleSupplierChoose}
                            name='suppliersList'
                            value={cardCode}
                        />
                    </SuppliersContainer>
                </CenterRow>
            </Container>
        </SpinnerMask>
    );
};
