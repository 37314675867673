import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import { IconButton } from '../IconButton/IconButton';
import close from 'assets/images/close.svg';

const StyledFilterTag = styled(Container)`
    border-radius: 30px;
    max-height: ${variables.defaultElementHeight} / 2;
    border: 1.5px solid ${variables.darkMainColor};
    padding: 3px 20px;
    width: fit-content;
    min-width: 200px;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
`;

interface FilterTagProps {
    children: React.ReactNode;
    onExit: () => void;
}

export const FilterTag: React.FC<FilterTagProps> = props => {
    const { children, onExit } = props;
    return (
        <StyledFilterTag>
            <Row>
                <Col md={10} className='long-text-ellipsis'>
                    {children}
                </Col>
                <Col md={2}>
                    <IconButton image={close} onClick={onExit} size='xs' />
                </Col>
            </Row>
        </StyledFilterTag>
    );
};
