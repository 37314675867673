import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Supplier, UserPersonalData } from 'api/shared/types';
import { AppError, BasicArrayStateType, BasicStateType } from 'store/types';
import { editPersonalData, getPersonalData, getSuppliers, updateAvatar } from './operations';

export type UserState = {
    suppliers: BasicArrayStateType<Supplier>;
    personalData: BasicStateType<UserPersonalData>;
};

const initialState: UserState = {
    suppliers: {
        entities: [],
        error: null,
        loading: false,
    },
    personalData: {
        entities: null,
        error: null,
        loading: false,
    },
};

export const userStateSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: {
        [getSuppliers.pending.type]: (state: UserState) => {
            state.suppliers.loading = true;
            state.suppliers.error = null;
        },
        [getSuppliers.fulfilled.type]: (state: UserState, action: PayloadAction<Supplier[]>) => {
            state.suppliers.entities = action.payload;
            state.suppliers.loading = false;
            state.suppliers.error = null;
        },
        [getSuppliers.rejected.type]: (state: UserState, action: PayloadAction<AppError>) => {
            state.suppliers.entities = [];
            state.suppliers.loading = false;
            state.suppliers.error = action.payload;
        },

        [getPersonalData.pending.type]: (state: UserState) => {
            state.personalData.loading = true;
            state.personalData.error = null;
        },
        [getPersonalData.fulfilled.type]: (
            state: UserState,
            action: PayloadAction<UserPersonalData>
        ) => {
            state.personalData.entities = action.payload;
            state.personalData.loading = false;
            state.personalData.error = null;
        },
        [getPersonalData.rejected.type]: (state: UserState, action: PayloadAction<AppError>) => {
            state.personalData.entities = null;
            state.personalData.loading = false;
            state.personalData.error = action.payload;
        },

        [updateAvatar.pending.type]: (state: UserState) => {
            state.personalData.loading = true;
            state.personalData.error = null;
        },
        [updateAvatar.fulfilled.type]: (state: UserState, action: PayloadAction<string>) => {
            if (state.personalData.entities) {
                state.personalData.entities.avatar = action.payload;
            }

            state.personalData.loading = false;
            state.personalData.error = null;
        },
        [updateAvatar.rejected.type]: (state: UserState, action: PayloadAction<AppError>) => {
            state.personalData.loading = false;
            state.personalData.error = action.payload;
        },

        [editPersonalData.pending.type]: (state: UserState) => {
            state.personalData.loading = true;
            state.personalData.error = null;
        },
        [editPersonalData.fulfilled.type]: (
            state: UserState,
            action: PayloadAction<Pick<UserPersonalData, 'preferedLanguage'>>
        ) => {
            if (state.personalData.entities) {
                state.personalData.entities.preferedLanguage = action.payload.preferedLanguage;
            }

            state.personalData.loading = false;
            state.personalData.error = null;
        },
        [editPersonalData.rejected.type]: (state: UserState, action: PayloadAction<AppError>) => {
            state.personalData.loading = false;
            state.personalData.error = action.payload;
        },
    },
});

export default userStateSlice.reducer;
