import React from 'react';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import { AppState } from 'store/types';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import logo from 'assets/images/logo.svg';
import sign from 'assets/images/sign.svg';
import { useSelector } from 'react-redux';

const StyledLayout = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    position: relative;
`;

const LogoLayout = styled.div`
    display: flex;
    width: 46%;
    color: '#FFF';
`;

const SignLayout = styled.div`
    display: flex;
    width: 54%;
    background-image: linear-gradient(
        to right,
        ${variables.lightMainColor},
        ${variables.darkMainColor}
    );
`;

const LogoSpan = styled.span`
    display: flex;
    margin: 5% 8%;
    width: 12vw;
    height: 10vh;
`;

const SignSpan = styled.span`
    display: flex;
    margin: 25% 25% 10% 0%;
    width: 75%;
    height: 60%;
`;

interface MainPageProps {
    children: React.ReactNode;
}

export const MainPage: React.FC<MainPageProps> = props => {
    const { children } = props;
    const { error, loading } = useSelector((state: AppState) => state.auth);

    return (
        <SpinnerMask show={loading}>
            <StyledLayout>
                {children}
                <LogoLayout>
                    <LogoSpan>
                        <img src={logo} />
                    </LogoSpan>
                </LogoLayout>
                <SignLayout>
                    <SignSpan>
                        <img src={sign} className='img-responsive' />
                    </SignSpan>
                </SignLayout>
            </StyledLayout>
        </SpinnerMask>
    );
};
