import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getUsersRequest,
    getRolesRequest,
    getSapSuppliersRequest,
    getSapContactPersonsRequest,
    getSuppliersRequest,
    AdminUser,
    editUserRequest,
    updateAvatarRequest,
} from 'api/admin';
import { addNewUserRequest } from 'api/auth';
import { AddNewUserForm, EditAdminUserForm } from 'api/shared/types';
import { AxiosError } from 'axios';
import { prepareErrorType } from 'components/shared/LocalError/helpers';
import { AppError } from 'store/types';

export const getUsers = createAsyncThunk('admin/users', async (_, { rejectWithValue }) => {
    try {
        const response = await getUsersRequest();
        return response.data;
    } catch (error: unknown | AxiosError | Error) {
        const appError: AppError = {
            error,
            type: prepareErrorType(error),
        };
        return rejectWithValue(appError);
    }
});

export const addNewUser = createAsyncThunk(
    'admin/addNewUser',
    async (data: AddNewUserForm, { rejectWithValue }) => {
        try {
            await addNewUserRequest(data);
            const response = await getUsersRequest();
            return response.data;
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);

export const editUser = createAsyncThunk(
    'admin/editUser',
    async ({ avatar, ...restData }: EditAdminUserForm, { rejectWithValue }) => {
        try {
            await editUserRequest(restData);
            if (avatar) {
                const newAvatar = new FormData();
                newAvatar.append('file', avatar);
                await updateAvatarRequest(newAvatar, restData.userId);
            }
            const response = await getUsersRequest();

            return response.data;
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);

export const getRoles = createAsyncThunk('admin/roles', async (_, { rejectWithValue }) => {
    try {
        const response = await getRolesRequest();
        return response.data;
    } catch (error: unknown | AxiosError | Error) {
        const appError: AppError = {
            error,
            type: prepareErrorType(error),
        };
        return rejectWithValue(appError);
    }
});

export const getSapSuppliers = createAsyncThunk(
    'admin/sapSuppliers',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getSapSuppliersRequest();
            return response.data;
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);

export const getSuppliers = createAsyncThunk('admin/suppliers', async (_, { rejectWithValue }) => {
    try {
        const response = await getSuppliersRequest();
        return response.data;
    } catch (error: unknown | AxiosError | Error) {
        const appError: AppError = {
            error,
            type: prepareErrorType(error),
        };
        return rejectWithValue(appError);
    }
});

export const getSapContactPersons = createAsyncThunk(
    'admin/contactPersons',
    async (cardCode: string, { rejectWithValue }) => {
        try {
            const response = await getSapContactPersonsRequest(cardCode);
            return response.data;
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);
