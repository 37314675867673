import React, { useEffect, useMemo, useState } from 'react';
import { Order } from 'api/shared/types';
import { AppDispatch, AppState } from 'store/types';
import { useDispatch, useSelector } from 'react-redux';
import { ordersSelector, selectEmployeeState, selectOrdersState } from 'store/employee/selectors';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { getOrders } from 'store/employee/operations';
import { Col, Container, Row } from 'react-bootstrap';
import {
    BlueDotCommentOverlay,
    CenterRow,
    DarkMainColorLabel,
    OrderCommentBlock,
    OrderHeader,
    OrderHeaderCell,
    RotatedImg,
    StyledOrderDataLayout,
} from 'styles/styled-components';
import { useExpanded, useFlexLayout, usePagination, useSortBy, useTable } from 'react-table';
import { IconButton } from 'components/shared/IconButton/IconButton';
import sortArrow from 'assets/images/sort-arrow.svg';
import { RealizationList } from './RealizationList/RealizationList';
import { Pagination } from 'components/shared/Pagination/Pagination';
import { getFlattenData, OrderTableItem, columns } from './helpers';
import desktopComment from 'assets/images/desktop-comment.svg';
import {
    getFontColorPerColumn,
    getFontSizePerColumn,
    getFontWeightPerColumn,
    isRowSpanColumn,
    isTheLastProduct,
    OrdersTableProps,
    SIZE_PER_PAGE,
} from 'utils/ordersTable';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/shared/Button/Button';
import { OrderChangesModal } from 'components/desktop/OrderChangesModal/OrderChangesModal';
import { CommentsModal } from 'components/desktop/Comments/CommentsModal';
import { useOrdersCommentsStats } from 'hooks/useOrdersCommentsStats';
import { useOrdersTableSorting } from 'hooks/useOrdersTableSorting';

const OrderTableCell = styled.div<{
    $columnId: string;
    $hideBorder?: boolean;
    $backgroundColor?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => getFontColorPerColumn(props.$columnId, columns)};
    font-weight: ${props => getFontWeightPerColumn(props.$columnId, columns)};
    font-size: ${props => getFontSizePerColumn(props.$columnId, columns)};
    margin: 0;
    padding: 0.5rem;
    border-bottom: ${prop => (prop.$hideBorder ? 'none' : `1px solid ${variables.lightGrayColor}`)};
    background: ${prop =>
        prop.$backgroundColor ? variables.lightTransparentMainColor : 'transparent'};
`;

const LogChangesContainer = styled.div`
    width: 95px;
    height: 30px;
`;

const LogChangesTxt = styled.span`
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
`;

export const OrdersTable: React.FC<OrdersTableProps> = ({
    ordersRequestInput,
    setOrdersRequestInput,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();
    const orders = useSelector<AppState, Array<Order> | []>(ordersSelector);
    const { totalOrders } = useSelector(selectEmployeeState);
    const { loading } = useSelector(selectOrdersState);
    const pageCount = Math.ceil(totalOrders / SIZE_PER_PAGE);
    const { supplierCardCode = '' } = useParams();
    const [showOrdersChangesModal, setShowOrdersChangesModal] = useState(false);

    const [ordersChanges, setOrdersChanges] = useState<
        { orderId: number; orderNumber: string } | undefined
    >();
    const [comments, setComments] = useState<{
        number: string;
        orderId: number;
    } | null>(null);
    const [showCommentsModal, setShowCommentsModal] = useState(false);

    const ordersTable = useMemo(() => getFlattenData(orders), [orders]);

    const [getCommentsStats, reloadCommentsStats] = useOrdersCommentsStats(orders.map(o => o.id));

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        gotoPage,
        state: { pageIndex, sortBy },
    } = useTable<OrderTableItem>(
        {
            columns,
            data: ordersTable,
            initialState: { pageIndex: 0, sortBy: [{ id: 'number', desc: true }] },
            manualPagination: true,
            manualSortBy: true,
            pageCount,
            autoResetExpanded: false,
            autoResetPage: false,
            autoResetSortBy: false,
        },
        useSortBy,
        useExpanded,
        usePagination,
        useFlexLayout
    );

    useOrdersTableSorting(sortBy, setOrdersRequestInput, pageIndex);

    useEffect(() => {
        if (ordersRequestInput) {
            dispatch(getOrders(ordersRequestInput));
            gotoPage(ordersRequestInput.offset / SIZE_PER_PAGE);
        }
    }, [ordersRequestInput]);

    const handlePageChange = async (pageNr: number) => {
        setOrdersRequestInput(state => ({
            ...state,
            limit: SIZE_PER_PAGE,
            offset: (pageNr - 1) * SIZE_PER_PAGE,
            supplierCardCode,
        }));
    };

    const handleCommentClick = ({ number, orderId }: OrderTableItem) => {
        setComments({ number, orderId });
        setShowCommentsModal(true);
    };

    const handleChangesLogClick = ({ orderId, number }: OrderTableItem) => {
        setShowOrdersChangesModal(true);
        setOrdersChanges({ orderId, orderNumber: number });
    };

    return (
        <StyledOrderDataLayout>
            {ordersChanges && (
                <OrderChangesModal
                    setShowModal={setShowOrdersChangesModal}
                    show={showOrdersChangesModal}
                    {...ordersChanges}
                />
            )}
            {comments && (
                <CommentsModal
                    setShowModal={setShowCommentsModal}
                    show={showCommentsModal}
                    {...comments}
                    allCommentsRead={getCommentsStats(comments.orderId)?.isCommentsRead ?? true}
                    reloadCommentsStats={reloadCommentsStats}
                />
            )}
            <SpinnerMask show={loading}>
                {orders.length > 0 ? (
                    <>
                        <CenterRow>
                            <Container
                                {...getTableProps()}
                                fluid
                                style={{ maxHeight: '60vh', overflowY: 'auto' }}
                            >
                                <OrderHeader>
                                    {headerGroups.map(headerGroup => (
                                        <Col {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <OrderHeaderCell
                                                    {...column.getHeaderProps(
                                                        column.getSortByToggleProps()
                                                    )}
                                                >
                                                    {column.render('Header')}
                                                    <span>
                                                        {column.isSorted ? (
                                                            <RotatedImg
                                                                src={sortArrow}
                                                                $rotation={
                                                                    column.isSortedDesc ? 180 : 0
                                                                }
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </span>
                                                </OrderHeaderCell>
                                            ))}
                                        </Col>
                                    ))}
                                </OrderHeader>
                                <div {...getTableBodyProps()}>
                                    {rows.map((row, rowIndex) => {
                                        prepareRow(row);
                                        return (
                                            <React.Fragment key={`${row.id}-dummyRow`}>
                                                <div {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <OrderTableCell
                                                                $columnId={
                                                                    cell.column.id?.toString() ?? ''
                                                                }
                                                                $hideBorder={
                                                                    isTheLastProduct(row, rows) ||
                                                                    isRowSpanColumn(cell)
                                                                }
                                                                $backgroundColor={
                                                                    !isRowSpanColumn(cell) &&
                                                                    row.isExpanded
                                                                }
                                                                {...cell.getCellProps()}
                                                            >
                                                                {cell.render('Cell', {
                                                                    ordersRequestInput,
                                                                })}
                                                            </OrderTableCell>
                                                        );
                                                    })}
                                                </div>
                                                {row.isExpanded && (
                                                    <RealizationList
                                                        key={`${row.id}-realizationForm`}
                                                        orderItemId={row.original.orderItemId}
                                                        orderId={row.original.orderId}
                                                        realizations={row.original.realizations}
                                                    />
                                                )}
                                                {isTheLastProduct(row, rows) && (
                                                    <OrderCommentBlock
                                                        key={`${row.id}-commentBlock`}
                                                        fluid
                                                    >
                                                        <Row>
                                                            <Col md={{ span: 1, offset: 2 }}>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <IconButton
                                                                        image={desktopComment}
                                                                        onClick={() =>
                                                                            handleCommentClick(
                                                                                row.original
                                                                            )
                                                                        }
                                                                        overlay={
                                                                            getCommentsStats(
                                                                                row.original.orderId
                                                                            )
                                                                                .isCommentsRead ? undefined : (
                                                                                <BlueDotCommentOverlay />
                                                                            )
                                                                        }
                                                                        dataCy='commentsButton'
                                                                    />

                                                                    {getCommentsStats(
                                                                        row.original.orderId
                                                                    ).commentsAmount && (
                                                                        <span>
                                                                            {
                                                                                getCommentsStats(
                                                                                    row.original
                                                                                        .orderId
                                                                                ).commentsAmount
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col md={2}>
                                                                <LogChangesContainer>
                                                                    <Button
                                                                        variant='outline-primary'
                                                                        onClick={() =>
                                                                            handleChangesLogClick(
                                                                                row.original
                                                                            )
                                                                        }
                                                                        data-cy='logChangesButton'
                                                                    >
                                                                        <LogChangesTxt>
                                                                            {t('logChanges')}
                                                                        </LogChangesTxt>
                                                                    </Button>
                                                                </LogChangesContainer>
                                                            </Col>
                                                        </Row>
                                                    </OrderCommentBlock>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </Container>
                        </CenterRow>
                        <CenterRow>
                            <Pagination
                                pageCount={pageCount}
                                handlePageChange={handlePageChange}
                                currentPageIndex={pageIndex + 1}
                            />
                        </CenterRow>
                    </>
                ) : (
                    <DarkMainColorLabel>{t('noOrders')}</DarkMainColorLabel>
                )}
            </SpinnerMask>
        </StyledOrderDataLayout>
    );
};
