import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    QualityControlInput,
    createQualityControlRequest,
    editQualityControlRequest,
    getOrderRequest,
    getOrdersRequest,
    deleteQualityControlFile,
    addQualityControlFiles,
} from 'api/qualityController';
import { AxiosError } from 'axios';
import { prepareErrorType } from 'components/shared/LocalError/helpers';
import { AppError } from 'store/types';
import { normalize } from 'normalizr';
import { orderEntity } from '../schemas';
import { OrdersRequestInput } from 'api/shared/types';

export interface CreateQualityControl {
    formData: FormData;
    orderId: number;
    orderItemId: number;
}

export interface EditQualityControl {
    qualityControlInput: QualityControlInput;
    qualityControlId: number;
    filesToDeleteIds: number[];
    filesToAddFormData: FormData | null;
    orderId: number;
}

export const getOrders = createAsyncThunk(
    'qualityController/getOrders',
    async (input: OrdersRequestInput, { rejectWithValue }) => {
        try {
            const { data } = await getOrdersRequest(input);
            const normalized = normalize(
                data.items.map((e, _id) => ({ ...e, _id })),
                [orderEntity]
            );
            return {
                totalOrders: data.total,
                data: normalized.entities,
            };
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);

export const createQualityControl = createAsyncThunk(
    'qualityController/createQualityControl',
    async (input: CreateQualityControl, { rejectWithValue }) => {
        try {
            await createQualityControlRequest(input.formData);

            const { data } = await getOrderRequest(input.orderId);
            const normalized = normalize(data, orderEntity);
            return {
                newQualityControls: normalized.entities.qualityControls,
                newOrderItems: normalized.entities.orderItems,
            };
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);

export const editQualityControl = createAsyncThunk(
    'qualityController/createQualityControl',
    async (input: EditQualityControl, { rejectWithValue }) => {
        try {
            await editQualityControlRequest(input.qualityControlInput, input.qualityControlId);

            input.filesToDeleteIds.forEach(async fileId => {
                await deleteQualityControlFile(fileId, input.qualityControlId);
            });
            if (input.filesToAddFormData) {
                await addQualityControlFiles(input.filesToAddFormData, input.qualityControlId);
            }

            const { data } = await getOrderRequest(input.orderId);
            const normalized = normalize(data, orderEntity);

            return {
                newQualityControls: normalized.entities.qualityControls,
            };
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);
