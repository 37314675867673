import { QualityControlResults, WidgetsDates } from 'api/shared/types';
import { AxiosResponse } from 'axios';
import { AutoSubmit } from 'components/shared/AutoSubmit/AutoSubmit';
import { DatePicker } from 'components/shared/DatePicker/DatePicker';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { Formik } from 'formik';
import React from 'react';
import { useAsync } from 'react-async';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    CenterRow,
    DarkMainColorLabel,
    LightMainColorLabel,
    WidgetContainer,
} from 'styles/styled-components';

interface QualityControlResultsMenuProps {
    dates: WidgetsDates;
    setOnTimeDeliveryDates: React.Dispatch<React.SetStateAction<WidgetsDates>>;
    getQualityControlResultsRequest: (
        dates: WidgetsDates
    ) => Promise<AxiosResponse<QualityControlResults, any>>;
}

export const QualityControlResultsMenu: React.FC<QualityControlResultsMenuProps> = props => {
    const { t } = useTranslation();
    const { getQualityControlResultsRequest, dates, setOnTimeDeliveryDates } = props;
    const fetchQualityControlResults = useAsync({
        deferFn: ([dateFrom, dateTo]) => getQualityControlResultsRequest({ dateFrom, dateTo }),
    });

    const qualityControlResults = fetchQualityControlResults.data?.data;

    return (
        <WidgetContainer>
            <SpinnerMask show={fetchQualityControlResults.isPending}>
                <Formik
                    initialValues={{ ...dates }}
                    onSubmit={async ({ dateFrom, dateTo }) => {
                        fetchQualityControlResults.run(dateFrom, dateTo);
                        setOnTimeDeliveryDates({ dateFrom, dateTo });
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <CenterRow className='py-4'>
                                <Col md={6}>
                                    <DatePicker name='dateFrom' showClearIcon />
                                </Col>
                                <Col md={6}>
                                    <DatePicker name='dateTo' showClearIcon />
                                </Col>
                            </CenterRow>
                            <AutoSubmit />
                        </Form>
                    )}
                </Formik>
                <Row className='py-1'>
                    <Col md={9} className='flexEnd'>
                        <DarkMainColorLabel>{t('numberOfOrdersPassed')}</DarkMainColorLabel>
                    </Col>
                    <Col md={3}>
                        <LightMainColorLabel className='font-24'>
                            {qualityControlResults?.qualityControlsApproved ?? 0}
                        </LightMainColorLabel>
                    </Col>
                </Row>
                <Row className='py-1'>
                    <Col md={9} className='flexEnd'>
                        <DarkMainColorLabel>{t('numberOfOrdersRejected')}</DarkMainColorLabel>
                    </Col>
                    <Col md={3}>
                        <LightMainColorLabel className='font-24'>
                            {qualityControlResults?.qualityControlsRejected ?? 0}
                        </LightMainColorLabel>
                    </Col>
                </Row>
                <Row className='py-1'>
                    <Col md={9} className='flexEnd'>
                        <DarkMainColorLabel>{t('totalNumberOfQualityControls')}</DarkMainColorLabel>
                    </Col>
                    <Col md={3}>
                        <LightMainColorLabel className='font-24'>
                            {qualityControlResults?.qualityControlsAll ?? 0}
                        </LightMainColorLabel>
                    </Col>
                </Row>
                <Row className='py-1'>
                    <Col md={9} className='flexEnd'>
                        <DarkMainColorLabel>{t('percentOfRejectedOrders')}</DarkMainColorLabel>
                    </Col>
                    <Col md={3}>
                        <LightMainColorLabel className='font-24'>
                            {qualityControlResults?.approvedRejectedPercentage ?? 0}
                        </LightMainColorLabel>
                    </Col>
                </Row>
            </SpinnerMask>
        </WidgetContainer>
    );
};
