import { FileType, OrdersRequestInput } from 'api/shared/types';
import { Modal } from 'components/shared/Modal/Modal';
import React, { useState } from 'react';
import { ButtonToolbar, Container } from 'react-bootstrap';
import styled from 'styled-components';
import {
    CenterRow,
    GrayLabel,
    StyledStack,
    DarkMainColorLabel,
    LightMainColorLabel,
} from 'styles/styled-components';
import attachment from 'assets/images/attachment.svg';
import download from 'assets/images/download.svg';
import { IconButton } from 'components/shared/IconButton/IconButton';
import bin from 'assets/images/bin.svg';
import addfile from 'assets/images/add-file.svg';
import variables from 'styles/variables.module.scss';
import { useTranslation } from 'react-i18next';
import {
    addQualityControlGuidelines,
    deleteQualityControlGuidelines,
    downloadQualityControlGuideline,
} from 'api/employee';
import { downloadFile, filesToFormData } from 'utils/helpers';
import { imageMimeTypes, textFileMimeTypes } from 'utils/consts';
import { FileUploader } from 'components/shared/FileUploader/FileUploader';
import { uniqBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/types';
import { addError } from 'store/errors/slice';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { getOrders } from 'store/employee/operations';
import { Button } from 'components/shared/Button/Button';

interface ModifyFilesModalProps {
    show: boolean;
    files: FileType[];
    title: string;
    setModifyFilesModal: React.Dispatch<React.SetStateAction<boolean>>;
    orderItemId: number;
    ordersRequestInput?: OrdersRequestInput;
}

const StyledLayout = styled(Container)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
`;

const ImageFileName = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 5px;
`;

const StyledQualityFileItem = styled.span`
    display: flex;
    justify-content: center;
    height: fit-content;
`;
const StyledSpan = styled.span`
    color: ${variables.darkMainColor};
    text-align: left;
`;

export const ModifyFilesModal: React.FC<ModifyFilesModalProps> = props => {
    const { show, files, title, setModifyFilesModal, orderItemId, ordersRequestInput } = props;
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(false);
    const [filesToAdd, setFilesToAdd] = useState<File[]>([]);
    const [filesToDeleteIds, setFilesToDeleteIds] = useState<number[]>([]);
    const { t } = useTranslation();

    const handleCloseModal = () => {
        setFilesToDeleteIds([]);
        setFilesToAdd([]);
        setModifyFilesModal(false);
    };

    const handleDownloadClick = async (fileId?: number) => {
        (!fileId ? files : files.filter(e => e.id === fileId)).forEach(
            async ({ id, fileName, fileType }) => {
                const response = await downloadQualityControlGuideline(id, orderItemId);
                downloadFile(response.data, fileName, fileType);
            }
        );
    };

    const handleFileChange = (newFiles: File[]) => {
        setFilesToAdd(state => uniqBy([...state, ...newFiles], 'name'));
    };

    const handleDeleteFileToAdd = (fileName: string) => {
        setFilesToAdd(state => state.filter(f => f.name !== fileName));
    };

    const handleDeleteFile = (fileId: number) => {
        setFilesToDeleteIds(state => [...state, fileId]);
    };

    const handleSaveFiles = async () => {
        try {
            setLoading(true);
            filesToDeleteIds.forEach(async f => {
                await deleteQualityControlGuidelines(f, orderItemId);
            });
            await addQualityControlGuidelines(filesToFormData(filesToAdd), orderItemId);
            setLoading(false);
            handleCloseModal();
            if (ordersRequestInput) {
                dispatch(getOrders(ordersRequestInput));
            }
        } catch (error: unknown) {
            setLoading(false);
        }
    };

    return (
        <Modal show={show} handleClose={handleCloseModal}>
            <SpinnerMask show={loading}>
                <StyledLayout>
                    <CenterRow className='pb-3'>
                        <GrayLabel>{title}</GrayLabel>
                    </CenterRow>
                    <CenterRow className='pb-3'>
                        <FileUploader
                            onFileChange={handleFileChange}
                            multiple
                            allowedMimeTypes={[textFileMimeTypes, imageMimeTypes].join(',')}
                        >
                            <IconButton image={addfile} size='lg' stopClickable dataCy='addFileButton'>
                                <StyledSpan>{t('addFile')}</StyledSpan>
                            </IconButton>
                        </FileUploader>
                    </CenterRow>
                    <CenterRow className='pb'>
                        <StyledStack className='pb-3' $maxHeight='200px'>
                            {files
                                .filter(f => !filesToDeleteIds.includes(f.id))
                                .map(f => (
                                    <DarkMainColorLabel
                                        key={f.id}
                                        className='pb-2 d-flex justify-content-between align-items-center'
                                        style={{ width: '300px' }}
                                    >
                                        <StyledQualityFileItem className='align-items-center'>
                                            <img
                                                src={attachment}
                                                alt='Attachment'
                                                width='30px'
                                                height='30px'
                                            />
                                            <ImageFileName>{f.fileName}</ImageFileName>
                                        </StyledQualityFileItem>
                                        <StyledQualityFileItem>
                                            <span className='me-2'>
                                                <IconButton
                                                    image={download}
                                                    onClick={() => handleDownloadClick(f.id)}
                                                    dataCy='downloadFileButton'
                                                />
                                            </span>
                                            <IconButton
                                                image={bin}
                                                size='xs'
                                                onClick={() => handleDeleteFile(f.id)}
                                                dataCy='removeFileButton'
                                            />
                                        </StyledQualityFileItem>
                                    </DarkMainColorLabel>
                                ))}
                            {filesToAdd.map(f => (
                                <DarkMainColorLabel
                                    key={f.name}
                                    className='pb-2 d-flex justify-content-between align-items-center'
                                    style={{ width: '300px' }}
                                >
                                    <StyledQualityFileItem>
                                        <img
                                            src={attachment}
                                            alt='Attachment'
                                            width='30px'
                                            height='30px'
                                        />
                                        <ImageFileName>{f.name}</ImageFileName>
                                    </StyledQualityFileItem>
                                    <StyledQualityFileItem>
                                        <IconButton
                                            image={bin}
                                            onClick={() => handleDeleteFileToAdd(f.name)}
                                            size='xs'
                                            dataCy='handleButton'
                                        />
                                    </StyledQualityFileItem>
                                </DarkMainColorLabel>
                            ))}
                        </StyledStack>
                    </CenterRow>
                    <CenterRow className='py-3'>
                        <LightMainColorLabel>{t('downloadAll')}</LightMainColorLabel>
                        <IconButton 
                            image={download} 
                            onClick={() => handleDownloadClick()} 
                            dataCy='downloadAllButton'
                        />
                    </CenterRow>
                    <CenterRow>
                        <ButtonToolbar className='py-3 px-8'>
                            <Button variant='primary' type='button' data-cy='saveButton' onClick={handleSaveFiles}>
                                {t('save')}
                            </Button>
                        </ButtonToolbar>
                    </CenterRow>
                </StyledLayout>
            </SpinnerMask>
        </Modal>
    );
};
