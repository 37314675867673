import { OrdersRequestInput, OrdersRequestSortingInput } from 'api/shared/types';
import { forEach } from 'lodash';
import React, { useEffect } from 'react';
import { SortingRule } from 'react-table';
import { SIZE_PER_PAGE } from 'utils/ordersTable';

export const useOrdersTableSorting = <T extends { number: string; expectedReadiness: string }>(
    sortBy: SortingRule<T>[],
    setOrdersRequestInput: React.Dispatch<React.SetStateAction<OrdersRequestInput | undefined>>,
    pageIndex: number
) => {
    useEffect(() => {
        const sorting: OrdersRequestSortingInput = {
            sortByDate: undefined,
            sortByNumber: undefined,
        };

        forEach(sortBy, sort => {
            if (sort.id === 'number') sorting.sortByNumber = sort.desc ? 1 : 0;
            if (sort.id === 'expectedReadiness') sorting.sortByDate = sort.desc ? 1 : 0;
        });

        setOrdersRequestInput(state => ({
            ...state,
            limit: SIZE_PER_PAGE,
            offset: pageIndex * SIZE_PER_PAGE,
            ...sorting,
        }));
    }, [sortBy]);
};
