import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from 'store';
import { BrowserRouter } from 'react-router-dom';
import '@fontsource/cairo';
import 'typeface-roboto';
import './index.scss';
import { injectStore } from 'api/shared/instance';
import { ErrorBoundary } from 'components/shared/ErrorBoundary/ErrorBoundary';
import i18n from 'i18next';
import en from 'assets/locales/en.json';
import pl from 'assets/locales/pl.json';
import zh from 'assets/locales/zh.json';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LNG_SYMBOL } from 'utils/consts';

i18n.use(initReactI18next).init({
    resources: {
        en,
        pl,
        zh,
    },
    lng: DEFAULT_LNG_SYMBOL,
    fallbackLng: DEFAULT_LNG_SYMBOL,
});

injectStore(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </Provider>
    </BrowserRouter>
);
