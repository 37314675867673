import { Modal } from 'components/shared/Modal/Modal';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
    CenterRow,
    DarkMainColorLabel,
    GrayLabel,
    ScrollContainer,
} from 'styles/styled-components';
import { Notification } from 'api/shared/types';
import { IconButton } from 'components/shared/IconButton/IconButton';
import bin from 'assets/images/bin.svg';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { useAsync } from 'react-async';
import { deleteNotificationRequest } from 'api/notifications';
import { OrderChangesModal } from '../OrderChangesModal/OrderChangesModal';
import { useTranslation } from 'react-i18next';

interface NotificationsModalProps {
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    notifications: Notification[];
    reloadNotifications: () => void;
    isNotificationsLoading: boolean;
}

export const NotificationsModal: React.FC<NotificationsModalProps> = props => {
    const { t } = useTranslation();
    const { show, setShowModal, notifications, reloadNotifications, isNotificationsLoading } =
        props;

    const [showOrdersChangesModal, setShowOrdersChangesModal] = useState(false);
    const [ordersChanges, setOrdersChanges] = useState<
        { orderId: number; orderNumber: string } | undefined
    >();

    const deleteNotification = useAsync({
        deferFn: ([notificationId]) => deleteNotificationRequest(notificationId),
    });

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleDeleteNotification = (id: number) => {
        deleteNotification.run(id);
    };

    useEffect(() => {
        if (deleteNotification.isFulfilled) {
            reloadNotifications();
        }
    }, [deleteNotification.isFulfilled]);

    const handleOrderChangeClick = (orderId: number, orderNumber: string) => {
        setOrdersChanges({ orderId, orderNumber });
        setShowModal(false);
        setShowOrdersChangesModal(true);
    };

    return (
        <>
            <Modal show={show} handleClose={handleCloseModal}>
                <Container>
                    <CenterRow className='pb-2'>
                        <GrayLabel className='font-24'>{t('notifications')}</GrayLabel>
                    </CenterRow>
                    <CenterRow className='pb-4'>
                        <GrayLabel>{t('listOfUserChanges')}</GrayLabel>
                    </CenterRow>

                    <SpinnerMask show={isNotificationsLoading || deleteNotification.isPending}>
                        <ScrollContainer className='my-2'>
                            {notifications.map(notification => (
                                <Row key={notification.id} className='pb-4'>
                                    <Col md={10}>
                                        <DarkMainColorLabel
                                            className='cursor-pointer'
                                            onClick={() =>
                                                handleOrderChangeClick(
                                                    notification.orderId,
                                                    notification.orderNumber
                                                )
                                            }
                                        >{`${t('orderChange')} ${notification.orderNumber} ${t(
                                            'toSupplier'
                                        )} ${notification.supplierName}`}</DarkMainColorLabel>
                                    </Col>
                                    <Col md={2} className='my-auto'>
                                        <IconButton
                                            size='xs'
                                            image={bin}
                                            onClick={() =>
                                                handleDeleteNotification(notification.id)
                                            }
                                        />
                                    </Col>
                                </Row>
                            ))}
                        </ScrollContainer>
                    </SpinnerMask>
                </Container>
            </Modal>
            {ordersChanges && (
                <OrderChangesModal
                    setShowModal={setShowOrdersChangesModal}
                    show={showOrdersChangesModal}
                    {...ordersChanges}
                />
            )}
        </>
    );
};
