import { useVirtualizer } from '@tanstack/react-virtual';
import { useField } from 'formik';
import React from 'react';
import { Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledToggleButton, StyledToggleButtonGroup } from 'styles/styled-components';

const StyledLabel = styled(Form.Label)`
    font-size: 18px;
`;

const StyledFormGroup = styled(Form.Group)`
    padding: 10px 0px;
    height: fit-content;
`;

interface RadioButtonsProps {
    radios: Array<{ value: string | number; content: string }>;
    value: string | number;
    name: string;
    onChoose: (value: string | number) => void;

    label?: string;
    vertical?: boolean;
    error?: string;
}

export const RadioButtons: React.FC<RadioButtonsProps> = props => {
    const { label, radios, name = '', vertical = false, onChoose, value, error } = props;
    const handleChange = (newValue: string | number) => {
        onChoose(newValue);
    };

    const parentRef = React.useRef();
    const rowVirtualizer = useVirtualizer({
        count: radios.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 40,
    });
    return (
        <StyledFormGroup as={Row}>
            {label && <StyledLabel>{label}</StyledLabel>}
            <StyledToggleButtonGroup
                type='radio'
                value={value}
                name={name}
                onChange={handleChange}
                vertical={vertical}
                ref={parentRef}
                style={
                    vertical
                        ? {
                              height: `400px`,
                              overflow: 'auto',
                          }
                        : {}
                }
            >
                {vertical
                    ? rowVirtualizer.getVirtualItems().map((virtualRow: any) => (
                          <StyledToggleButton
                              className='smallFont'
                              id={`toggleBtn-${radios[virtualRow.index].value}`}
                              key={virtualRow.key}
                              value={radios[virtualRow.index].value}
                              style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: `${virtualRow.size}px`,
                                  transform: `translateY(${virtualRow.start}px)`,
                              }}
                          >
                              {radios[virtualRow.index].content}
                          </StyledToggleButton>
                      ))
                    : radios.map(radio => (
                          <StyledToggleButton
                              id={`toggleBtn-${radio.value}`}
                              key={radio.content}
                              value={radio.value}
                          >
                              {radio.content}
                          </StyledToggleButton>
                      ))}
            </StyledToggleButtonGroup>
            {error && <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>}
        </StyledFormGroup>
    );
};
