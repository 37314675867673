import { Button } from 'components/shared/Button/Button';
import { useHomePathByRole } from 'hooks/useHomePathByRole';
import React from 'react';
import styled from 'styled-components';
import notFound from 'assets/images/404.svg';
import { StyledBtnErrorDiv, StyledErrorLayout, StyledErrorText } from 'styles/styled-components';
import { useTranslation } from 'react-i18next';

const StyledImg = styled.img`
    max-width: 435px;
`;

export const NotFound: React.FC = () => {
    const { t } = useTranslation();
    const homePathByRole = useHomePathByRole();
    return (
        <StyledErrorLayout>
            <StyledImg src={notFound} className='img-responsive' />
            <StyledErrorText>{t('pageNotFound')}</StyledErrorText>
            <StyledBtnErrorDiv className='uppercase-text'>
                <Button type='link' variant='outline-link' pathTo={homePathByRole}>
                    {t('backToHome')}
                </Button>
            </StyledBtnErrorDiv>
        </StyledErrorLayout>
    );
};
