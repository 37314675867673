import React from 'react';
import styled from 'styled-components';
import person from 'assets/images/person.svg';

interface Base64ImgProps {
    src: string | undefined | null;
}

const AvatarImg = styled.img`
    border-radius: 50%;
    width: 100%;
    height: auto;
    background: #009fe3;
`;

export const Base64Img: React.FC<Base64ImgProps> = ({ src }) => {
    const base64Src = src?.includes('base64') ? src : `data:image/jpeg;base64,${src}`;
    return <AvatarImg src={src ? base64Src : person} alt='avatar' />;
};
