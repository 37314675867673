import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthElement } from 'components/shared/AuthElement/AuthElement';
import Login from '../Login/Login';
import { useSelector } from 'react-redux';
import { AdminUsersTable } from 'features/desktop/admin/UsersTable/AdminUsersTable';
import { NotFound } from '../../shared/NotFound/NotFound';
import { FirstLoginPassword } from '../FirstLoginPassword/FirstLoginPassword';
import routes from 'routes-desktop.json';
import { AdminHome } from 'features/desktop/admin/AdminHome';
import { SupplierHome } from 'features/desktop/supplier/SupplierHome';
import { useHomePathByRole } from 'hooks/useHomePathByRole';
import { ResetPassword } from '../ResetPassword/ResetPassword';
import { GlobalError } from 'components/shared/GlobalError/GlobalError';
import { authSelector } from 'store/auth/selectors';
import { QualityControllerHome } from 'features/desktop/qualityController/QualityControllerHome';
import { QualityControllerDashboard } from 'features/desktop/qualityController/Dashboard/QualityControllerDashboard';
import { QualityControllerSuppliersList } from 'features/desktop/qualityController/SuppliersList/QualityControllerSuppliersList';
import { ResetPasswordRequest } from '../ResetPasswordRequest/ResetPasswordRequest';
import { EmployeeHome } from 'features/desktop/employee/EmployeeHome';
import { EmployeeDashboard } from 'features/desktop/employee/Dashboard/EmployeeDashboard';
import { EmployeeSuppliersList } from 'features/desktop/employee/SuppliersList/EmployeeSuppliersList';
import { SystemParameters } from 'features/desktop/admin/SystemParameters/SystemParameters';

const DesktopLayout: React.FC = () => {
    const auth = useSelector(authSelector);
    const homePathByRole = useHomePathByRole();
    return (
        <>
            <GlobalError />
            <Routes>
                <Route path='/' element={<Navigate to={homePathByRole} />} />
                <Route
                    path={routes.login.path}
                    element={
                        auth ? (
                            auth.firstTimeLogin ? (
                                <Navigate to={routes.firstLoginPassword.path} />
                            ) : (
                                <Navigate to={homePathByRole} />
                            )
                        ) : (
                            <Login />
                        )
                    }
                />
                <Route
                    path={routes.firstLoginPassword.path}
                    element={
                        auth ? (
                            auth.firstTimeLogin ? (
                                <FirstLoginPassword />
                            ) : (
                                <Navigate to={homePathByRole} />
                            )
                        ) : (
                            <Navigate to={routes.login.path} />
                        )
                    }
                />
                <Route
                    path={routes.admin.path}
                    element={
                        <AuthElement>
                            <AdminHome />
                        </AuthElement>
                    }
                >
                    <Route
                        path={routes.admin.children.users.path}
                        element={<AdminUsersTable />}
                    ></Route>
                    <Route
                        path={routes.admin.children.params.path}
                        element={<SystemParameters />}
                    ></Route>
                </Route>

                <Route
                    path={routes.supplier.path}
                    element={
                        <AuthElement>
                            <SupplierHome />
                        </AuthElement>
                    }
                />

                <Route
                    path={routes.qualityController.path}
                    element={
                        <AuthElement>
                            <QualityControllerHome />
                        </AuthElement>
                    }
                >
                    <Route
                        path={`${routes.qualityController.children.dashboard.path}/:supplierCardCode`}
                        element={<QualityControllerDashboard />}
                    ></Route>

                    <Route
                        path={routes.qualityController.children.suppliers.path}
                        element={<QualityControllerSuppliersList />}
                    ></Route>
                </Route>

                <Route
                    path={routes.employee.path}
                    element={
                        <AuthElement>
                            <EmployeeHome />
                        </AuthElement>
                    }
                >
                    <Route
                        path={`${routes.employee.children.dashboard.path}/:supplierCardCode`}
                        element={<EmployeeDashboard />}
                    ></Route>

                    <Route
                        path={routes.employee.children.suppliers.path}
                        element={<EmployeeSuppliersList />}
                    ></Route>
                </Route>

                <Route path={routes.resetPassword.path} element={<ResetPassword />} />
                <Route path={routes.resetPasswordRequest.path} element={<ResetPasswordRequest />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </>
    );
};

export default DesktopLayout;
