import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { Order, OrderItem, OrderRealization } from 'api/shared/types';
import { AppError, AppState, BasicStateType } from 'store/types';
import { isDefined, isNotNull } from 'utils/helpers';
import { SupplierState } from './slice';
import {
    NormalizedOrderItems,
    NormalizedOrderRealizations,
    NormalizedOrders,
} from 'store/shared/types';

export const selectSupplierErrors = (state: AppState): AppError[] =>
    Object.values(state.supplier)
        .map<AppError | null>(v => (typeof v !== 'number' && 'error' in v ? v.error : null))
        .filter(isNotNull)
        .filter(isDefined);

export const selectSupplierState = (state: AppState): SupplierState => state.supplier;

export const selectOrdersState = (state: AppState): BasicStateType<NormalizedOrders> =>
    selectSupplierState(state).orders;

export const selectOrderItemsState = (state: AppState): BasicStateType<NormalizedOrderItems> =>
    selectSupplierState(state).orderItems;

export const selectOrderRealizationsState = (
    state: AppState
): BasicStateType<NormalizedOrderRealizations> => selectSupplierState(state).realizations;

const selectOrders = (state: AppState): NormalizedOrders | null =>
    selectOrdersState(state).entities;

const selectOrderItems = (state: AppState): NormalizedOrderItems | null =>
    selectOrderItemsState(state).entities;

const selectOrderRealizations = (state: AppState): NormalizedOrderRealizations | null =>
    selectOrderRealizationsState(state).entities;

const realizationsSelector = createDraftSafeSelector(
    selectOrderRealizations,
    (state: AppState, { orderItemId }: { orderItemId: number }) => orderItemId,
    (normalizedOrderRealizations, orderItemId) => {
        if (normalizedOrderRealizations === null) return [];
        return Object.values(normalizedOrderRealizations)
            .map<OrderRealization>(o => ({ ...o }))
            .filter(o => o.orderItemId === orderItemId);
    }
);

const orderItemsSelector = createDraftSafeSelector(
    selectOrderItems,
    (state: AppState, { orderId }: { orderId: number }) => ({ orderId, state }),
    (normalizedOrderItems, { orderId, state }) => {
        if (normalizedOrderItems === null) return [];
        return Object.values(normalizedOrderItems)
            .filter(o => o.orderId === orderId)
            .map<OrderItem>(({ id, qualityControls, realizations, orderId: i, ...rest }) => ({
                ...rest,
                id,
                realizations: realizationsSelector(state, { orderItemId: id }),
                qualityControls: [],
            }));
    }
);

export const ordersSelector = createDraftSafeSelector(
    selectOrders,
    (state: AppState) => state,
    (normalizedOrders, state) => {
        if (normalizedOrders === null) return [];
        return Object.values(normalizedOrders).map<Order>(({ id, orderItems, ...rest }) => ({
            ...rest,
            id,
            orderItems: orderItemsSelector(state, { orderId: id }),
        }));
    }
);
