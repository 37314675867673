import React from 'react';
import { Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import ReactDatePicker from 'react-date-picker';
import { DATE_FORMAT } from 'utils/consts';
import calendar from 'assets/images/calendar.svg';
import close from 'assets/images/close.svg';
import { useField } from 'formik';
import variables from 'styles/variables.module.scss';
import { parseISO } from 'date-fns';

interface DatePickerProps {
    label?: string;
    name: string;
    showClearIcon?: boolean;
    dataCy?: string;
}

const StyledLabel = styled(Form.Label)`
    font-size: 1vw;
    color: ${variables.darkMainColor};
    font-weight: 600;
`;

const StyledDatePicker = styled(ReactDatePicker)`
    max-width: 100%;
    height: ${variables.defaultElementHeight};
    .react-date-picker__wrapper {
        border-radius: 40px;
        padding: 0px 10px;
        border: 1px solid ${variables.darkMainColor};
        align-items: center;
    }

    .react-date-picker__inputGroup {
        font-size: 0.8rem;
        color: ${variables.lightMainColor};
    }

    .react-date-picker__inputGroup__input {
        font-size: 0.8rem;
        color: ${variables.lightMainColor};
    }

    .react-calendar__tile--active {
        background: ${variables.darkMainColor};
    }

    .react-calendar__tile--now {
        background: ${variables.lightMainColor};
    }

    .react-calendar {
        border-radius: 20px;
    }
`;

export const DatePicker: React.FC<DatePickerProps> = props => {
    const { label, name, showClearIcon = false, dataCy } = props;
    const [field, meta, helpers] = useField<string | null>(name);
    return (
        <Form.Group as={Row} data-cy={dataCy} >
            {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
            <StyledDatePicker
                format={DATE_FORMAT}
                calendarIcon={<img src={calendar} width='90%' height='auto' />}
                dayPlaceholder='dd'
                monthPlaceholder='MM'
                yearPlaceholder='yyyy'
                locale='en-US'
                clearIcon={showClearIcon ? <img src={close} width='20px' height='auto' /> : null}
                name={name}
                onChange={(date: Date | null) => helpers.setValue(date ? date.toISOString() : null)}
                value={field.value === null ? field.value : parseISO(field.value)}
            />
            {meta.touched && meta.error && (
                <Form.Control.Feedback type='invalid'>{meta.error}</Form.Control.Feedback>
            )}
        </Form.Group>
    );
};
