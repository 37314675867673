import React, { useState } from 'react';
import { IconButton } from 'components/shared/IconButton/IconButton';
import search from 'assets/images/search.svg';
import { OrdersFiltersModal } from '../OrdersFiltersModal/OrdersFiltersModal';
import { OrderAutocompleteType } from 'api/shared/types';

interface OrdersSearchProps {
    orderAutocompleteType: OrderAutocompleteType;
    setOrderAutocompleteType: React.Dispatch<React.SetStateAction<OrderAutocompleteType>>;

    autocomplete: {
        onSearch: (value: string) => Promise<void>;
        options: Array<string>;
        loading: boolean;
        selected?: string[];
        onChange: (selected: string[]) => void;
    };
}

export const OrdersFilters: React.FC<OrdersSearchProps> = props => {
    const { autocomplete, orderAutocompleteType, setOrderAutocompleteType } = props;
    const [showModal, setShowModal] = useState(false);
    const handleClick = () => {
        setShowModal(true);
    };
    return (
        <>
            <IconButton
                ariaLabel='search'
                image={search}
                onClick={handleClick}
                dataCy='searchButton'
                size='lg'
            />
            <OrdersFiltersModal
                show={showModal}
                setShowModal={setShowModal}
                autocomplete={autocomplete}
                orderAutocompleteType={orderAutocompleteType}
                setOrderAutocompleteType={setOrderAutocompleteType}
            />
        </>
    );
};
