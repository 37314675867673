import React from 'react';
import styled from 'styled-components';
import { CenterRow, StyledStack } from 'styles/styled-components';
import { FileUploader } from '../FileUploader/FileUploader';
import { IconButton } from '../IconButton/IconButton';
import variables from 'styles/variables.module.scss';
import { FileType } from 'api/shared/types';
import { textFileMimeTypes, imageMimeTypes, compressedMimeTypes } from 'utils/consts';
import { uniqBy } from 'lodash';
import { isCompressedFile, isImageFile, isTextFile } from 'utils/helpers';
import addImage from 'assets/images/add-image.svg';
import addTextFile from 'assets/images/add-text-file.svg';
import image from 'assets/images/image.svg';
import textFile from 'assets/images/text-file.svg';
import addZip from 'assets/images/add-zip.svg';
import zip from 'assets/images/zip.svg';
import { t } from 'i18next';
import { FileToDelete } from '../FileToDelete/FileToDelete';
import { Row, Stack } from 'react-bootstrap';

const StyledSpan = styled.span`
    display: flex;
    align-items: center;
    color: ${variables.darkMainColor};
    font-weight: 500;
    font-size: 0.85rem;
    text-align: left;

    span {
        img {
            margin-left: 20px;
        }
    }
`;

export enum FileListContainerType {
    WithDeleteFileStack,
    HorizontalStack,
}

type FileListUploaderProps =
    | {
          type: FileListContainerType.WithDeleteFileStack;
          files: FileType[];
          mimeTypes: typeof textFileMimeTypes | typeof imageMimeTypes | typeof compressedMimeTypes;

          filesToDeleteIds: number[];
          setFilesToDeleteIds: React.Dispatch<React.SetStateAction<number[]>>;

          filesToAdd: File[];
          setFilesToAdd: React.Dispatch<React.SetStateAction<File[]>>;
      }
    | {
          type: FileListContainerType.HorizontalStack;
          mimeTypes: typeof textFileMimeTypes | typeof imageMimeTypes | typeof compressedMimeTypes;

          filesToAdd: File[];
          setFilesToAdd: React.Dispatch<React.SetStateAction<File[]>>;
      };

const propsPerMimeTypes = {
    [textFileMimeTypes]: {
        filterFunction: isTextFile,
        addFileIcon: addTextFile,
        fileIcon: textFile,
        getLabel: () => t('addTextFile'),
    },

    [imageMimeTypes]: {
        filterFunction: isImageFile,
        addFileIcon: addImage,
        fileIcon: image,
        getLabel: () => t('addImageFile'),
    },

    [compressedMimeTypes]: {
        filterFunction: isCompressedFile,
        addFileIcon: addZip,
        fileIcon: zip,
        getLabel: () => t('addZipFile'),
    },
};

export const FileListUploader: React.FC<FileListUploaderProps> = props => {
    const { mimeTypes, filesToAdd, setFilesToAdd, type } = props;
    const { filterFunction, addFileIcon, fileIcon, getLabel } = propsPerMimeTypes[mimeTypes];

    const handleImageFileChange = (newFiles: File[]) => {
        setFilesToAdd(state => uniqBy([...state, ...newFiles], 'name'));
    };
    const handleDeleteNewFile = (name: string) => {
        setFilesToAdd(state => state.filter(f => f.name !== name));
    };
    const handleDeleteFile = (id: number) => {
        if (props.type === FileListContainerType.WithDeleteFileStack) {
            props.setFilesToDeleteIds(state => [...state, id]);
        }
    };

    let cmp = null;

    switch (type) {
        case FileListContainerType.WithDeleteFileStack:
            cmp = (
                <CenterRow className='pb-1'>
                    <StyledStack className='pb-3' $maxHeight='200px'>
                        {props.files
                            .filter(
                                f =>
                                    filterFunction(f.fileType) &&
                                    !props.filesToDeleteIds.includes(f.id)
                            )
                            .map(f => (
                                <FileToDelete
                                    key={f.fileName}
                                    fileName={f.fileName}
                                    handleDeleteFile={() => handleDeleteFile(f.id)}
                                    fileIcon={fileIcon}
                                />
                            ))}
                        {filesToAdd
                            .filter(f => filterFunction(f.type))
                            .map(f => (
                                <FileToDelete
                                    key={f.name}
                                    fileName={f.name}
                                    handleDeleteFile={() => handleDeleteNewFile(f.name)}
                                    fileIcon={fileIcon}
                                />
                            ))}
                    </StyledStack>
                </CenterRow>
            );
            break;

        case FileListContainerType.HorizontalStack:
            cmp = filesToAdd.length > 0 && (
                <Row className='pb-3'>
                    <Stack direction='horizontal' gap={2} className='hstack-wrap'>
                        {filesToAdd
                            .filter(f => filterFunction(f.type))
                            .map(i => (
                                <img src={fileIcon} key={i.name} />
                            ))}
                    </Stack>
                </Row>
            );
    }

    return (
        <>
            <CenterRow className='pb-2'>
                <FileUploader
                    onFileChange={handleImageFileChange}
                    multiple
                    allowedMimeTypes={mimeTypes}
                >
                    <IconButton image={addFileIcon} isRow isIconToLeft={false} stopClickable>
                        <StyledSpan>{getLabel()}</StyledSpan>
                    </IconButton>
                </FileUploader>
            </CenterRow>
            {cmp}
        </>
    );
};
