import { Roles } from 'api/auth';
import { useSelector } from 'react-redux';
import { authSelector } from 'store/auth/selectors';

export const useRole = (roles?: Roles[]) => {
    const auth = useSelector(authSelector);
    const hasRole = (role: Roles) => !!roles && roles.includes(role);

    if (auth && 'roles' in auth) {
        const role = auth.roles?.[0];

        return { role, hasRole: hasRole(role) };
    }

    return { role: null };
};
