import { FileType } from 'api/shared/types';
import { Modal } from 'components/shared/Modal/Modal';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { CenterRow, GrayLabel } from 'styles/styled-components';
import { Button } from 'components/shared/Button/Button';
import { StyledButtonToolbar } from 'features/desktop/admin/AddNewUserModal/AddNewUserModal';

import { useTranslation } from 'react-i18next';
import {
    FileListContainerType,
    FileListUploader,
} from 'components/shared/FileListUploader/FileListUploader';
import { availableMimeTypes } from 'utils/consts';

interface EditFilesModalProps {
    show: boolean;
    files: FileType[];
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setFilesToAdd: React.Dispatch<React.SetStateAction<File[]>>;
    filesToDeleteIds: number[];
    setFilesToDeleteIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const StyledLayout = styled(Container)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
`;

export const EditFilesModal: React.FC<EditFilesModalProps> = props => {
    const { show, files, setShowModal, setFilesToAdd, filesToDeleteIds, setFilesToDeleteIds } =
        props;

    const { t } = useTranslation();
    const [filesToSend, setFilesToSend] = useState<File[]>([]);

    const handleCloseModal = () => {
        setFilesToAdd([]);
        setShowModal(false);
        setFilesToDeleteIds([]);
    };

    const handleSaveFiles = () => {
        setFilesToAdd(filesToSend);
        setShowModal(false);
    };

    return (
        <Modal show={show} handleClose={handleCloseModal}>
            <StyledLayout>
                <CenterRow className='pb-4'>
                    <GrayLabel>{t('editAttachments')}</GrayLabel>
                </CenterRow>
                {availableMimeTypes.map(m => (
                    <FileListUploader
                        key={m}
                        files={files}
                        filesToDeleteIds={filesToDeleteIds}
                        setFilesToDeleteIds={setFilesToDeleteIds}
                        mimeTypes={m}
                        filesToAdd={filesToSend}
                        setFilesToAdd={setFilesToSend}
                        type={FileListContainerType.WithDeleteFileStack}
                    />
                ))}
                <StyledButtonToolbar className='py-4 px-8'>
                    <Button variant='primary' type='button' onClick={handleSaveFiles}>
                        {t('save')}
                    </Button>
                </StyledButtonToolbar>
            </StyledLayout>
        </Modal>
    );
};
