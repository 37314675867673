import { AddOrderComment, OrderComment } from 'api/shared/types';
import { Base64Img } from 'components/shared/Base64Img/Base64Img';
import { MorePopover, Config } from 'components/shared/MorePopover/MorePopover';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DarkMainColorLabel, GrayLabel, GrayTextArea } from 'styles/styled-components';
import bin from 'assets/images/bin.svg';
import edit from 'assets/images/edit.svg';
import { AddComment } from '../../AddComment/AddComment';
import { ISOToFormattedStringDateTime } from 'utils/helpers';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { addCommentRequest, deleteCommentRequest, editCommentRequest } from 'api/user';
import { useSelector } from 'react-redux';
import { authSelector } from 'store/auth/selectors';

export const StyledComment = styled.div`
    display: flex;
    min-height: 130px;
    width: 100%;

    .avatar {
        width: 10%;
    }

    .content {
        display: flex;
        flex-direction: column;
        margin-left: 2%;
        width: 88%;

        .comment {
            padding: 5px;
            display: flex;
            flex-direction: column;
            background: #f6f6f6;
            border-radius: 10px;

            .user {
                display: flex;
            }
        }

        .reply {
            display: flex;
        }
    }
`;

interface CommentProps {
    comment: OrderComment;
    orderId: number;
    reloadComments: () => void;
}

export const Comment: React.FC<CommentProps> = props => {
    const {
        comment: { avatar, comment, login, id, createdAt, isVisible, userId },
        orderId,
        reloadComments,
    } = props;
    const { t } = useTranslation();
    const auth = useSelector(authSelector);
    const [showAddComment, setShowAddOrEditComment] = useState(false);
    const [commentToEdit, setCommentToEdit] = useState(comment);
    const [isEditMode, setIsEditMode] = useState(false);

    const handleDelete = async () => {
        await deleteCommentRequest(id);
        reloadComments();
    };

    const handleEdit = () => {
        setIsEditMode(state => !state);
    };

    const handleReply = () => {
        setShowAddOrEditComment(true);
    };

    const handleAddComment = async (newComment: AddOrderComment) => {
        await addCommentRequest(newComment, orderId);
        setShowAddOrEditComment(false);
        reloadComments();
    };

    const handleEditComment = async () => {
        await editCommentRequest(commentToEdit, id);
        setIsEditMode(false);
        reloadComments();
    };

    const config: Config[] = [
        {
            handleOnClick: handleEdit,
            label: t('edit'),
            image: edit,
            iconSize: 'sm',
        },
        {
            handleOnClick: handleDelete,
            label: t('delete'),
            image: bin,
            iconSize: 'sm',
        },
    ];

    const handleChangeComment = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setCommentToEdit(event.target.value);
    };

    if (!isVisible) return <GrayLabel>{t('commentWasDeleted')}</GrayLabel>;

    return (
        <div className='d-flex flex-column py-2'>
            <StyledComment>
                <div className='avatar'>
                    <Base64Img src={avatar} />
                </div>
                <div className='content'>
                    <div className='comment mb-2'>
                        {isEditMode ? (
                            <div className='d-flex flex-column'>
                                <GrayTextArea
                                    as='textarea'
                                    rows={3}
                                    value={commentToEdit}
                                    onChange={handleChangeComment}
                                ></GrayTextArea>
                                <ButtonToolbar className='my-2 px-8'>
                                    <Button variant='outline-primary' onClick={handleEditComment}>
                                        {t('save')}
                                    </Button>
                                </ButtonToolbar>
                            </div>
                        ) : (
                            <>
                                <div className='user pb-4'>
                                    <DarkMainColorLabel>{login}</DarkMainColorLabel>
                                    {auth?.userId === userId && (
                                        <span className='ms-auto'>
                                            <MorePopover config={config} />
                                        </span>
                                    )}
                                </div>
                                <GrayLabel>{comment}</GrayLabel>
                            </>
                        )}
                    </div>
                    <div className='reply'>
                        <GrayLabel
                            onClick={handleReply}
                            className='cursor-pointer fst-italic fw-light'
                        >
                            {t('reply')}
                        </GrayLabel>
                        <GrayLabel className='ms-auto fw-light'>
                            {ISOToFormattedStringDateTime(createdAt)}
                        </GrayLabel>
                    </div>
                </div>
            </StyledComment>
            {showAddComment && <AddComment handleAddComment={handleAddComment} parentId={id} />}
        </div>
    );
};
