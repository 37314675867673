import { OrdersRequestInput, WidgetsDates } from 'api/shared/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FiltersState } from 'store/filters/slice';
import { AppState } from 'store/types';
import { getFilters, SIZE_PER_PAGE } from 'utils/ordersTable';

export const useOrdersRequestInput = (openOrderDates: WidgetsDates) => {
    const filtersState = useSelector<AppState, FiltersState>(state => state.filters);
    const { supplierCardCode = '' } = useParams();

    const [ordersRequestInput, setOrdersRequestInput] = useState<OrdersRequestInput>();

    const getInitState = () => {
        const state: any = {
            limit: SIZE_PER_PAGE,
            offset: 0,
            sortByNumber: 1,
            ...getFilters(filtersState),
        };
        if (supplierCardCode) {
            state.cardCode = supplierCardCode;
        }
        return state;
    };

    useEffect(() => {
        const initState = getInitState();
        setOrdersRequestInput(initState);
    }, [filtersState, supplierCardCode]);

    useEffect(() => {
        const { dateFrom, dateTo } = openOrderDates;
        if ((dateFrom || dateTo) && filtersState.openOrdersWidget) {
            const initState = getInitState();

            if (dateFrom) {
                initState.expectedReadinessDateFrom = dateFrom;
            }
            if (dateTo) {
                initState.expectedReadinessDateTo = dateTo;
            }

            setOrdersRequestInput(initState);
        }
    }, [openOrderDates, filtersState]);

    return { ordersRequestInput, setOrdersRequestInput };
};
