import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from 'components/shared/Button/Button';
import { Outlet } from 'react-router-dom';
import addUser from 'assets/images/add-user.svg';
import styles from './AdminHome.module.scss';
import routes from 'routes-desktop.json';
import { AddNewUserModal } from 'features/desktop/admin/AddNewUserModal/AddNewUserModal';
import { IconButton } from 'components/shared/IconButton/IconButton';
import variables from 'styles/variables.module.scss';
import { Header } from 'components/desktop/Header/Header';
import { useTranslation } from 'react-i18next';

const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
`;

const StyledMain = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 8vh 18vw;
`;

const StyledFirstRow = styled(Row)`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 4vh;
`;

const StyledButton = styled(Button)`
    font-size: 1.3vw;
    line-height: 1.5vw;
    height: ${variables.defaultElementHeight};
`;

export const AdminHome: React.FC = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const handleClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <StyledLayout>
            <AddNewUserModal modal={{ show: showModal, closeModal: handleCloseModal }} />
            <Header />
            <StyledMain>
                <Container fluid>
                    <StyledFirstRow className={styles.firstRow}>
                        <Col md={{ span: 4, offset: 4 }} className={styles.addNewUserCol}>
                            <IconButton
                                size='xl'
                                image={addUser}
                                onClick={handleClick}
                                ariaLabel='add-new-user'
                                dataCy={'addUserButton'}
                            >
                                <span className={styles.text}>{t('addNewUser')}</span>
                            </IconButton>
                        </Col>
                        <Col md={4}></Col>
                    </StyledFirstRow>
                    <Row>
                        <Col md={6}>
                            <StyledButton
                                variant='link'
                                type='link'
                                pathTo={routes.admin.children.users.path}
                            >
                                {t('usersList')}
                            </StyledButton>
                        </Col>
                        <Col md={6}>
                            <StyledButton
                                variant='link'
                                type='link'
                                pathTo={routes.admin.children.params.path}
                            >
                                {t('systemParameters')}
                            </StyledButton>
                        </Col>
                    </Row>
                    <Row>
                        <Outlet />
                    </Row>
                </Container>
            </StyledMain>
        </StyledLayout>
    );
};
