import React from 'react';
import { FileType, OrderItem, QualityControl } from 'api/shared/types';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import {
    AttachmentSpan,
    BlueLine,
    DarkMainColorLabel,
    GrayLabel,
    GrayLine,
    LightMainColorLabel,
} from 'styles/styled-components';
import textFile from 'assets/images/text-file.svg';
import { Toggle } from 'components/shared/Toggle/Toggle';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import { ISOToFormattedStringDate } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { downloadQualityControlFile } from 'api/employee';
import { downloadFile } from 'utils/helpers';

interface QualityControlResultProps {
    qualityControl: QualityControl;
    orderItem: OrderItem;
}

export const StyledLabel = styled.span`
    color: ${variables.lightMainColor};
    font-weight: 300;
    padding-right: 10px;
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    border-radius: 15px;
    height: 190px;
`;

export const QualityControlResult: React.FC<QualityControlResultProps> = props => {
    const { qualityControl, orderItem } = props;
    const { t } = useTranslation();

    const handleDownloadClick = async (f: FileType) => {
        const response = await downloadQualityControlFile(f.id, qualityControl.id);
        downloadFile(response.data, f.fileName, f.fileType);
    };

    return (
        <Container fluid className='py-3'>
            <Row className='px-5'>
                <Col>
                    <StyledLabel>{t('qualityControlOfTheDay')}:</StyledLabel>
                    <LightMainColorLabel className='pl-2'>
                        {ISOToFormattedStringDate(qualityControl.createdAt)}
                    </LightMainColorLabel>
                </Col>
            </Row>
            <BlueLine />
            <div className='px-5'>
                <Row className='py-3'>
                    <Col md={6}>
                        <GrayLabel>{t('qualityControlGuidelines')}</GrayLabel>
                    </Col>
                    {/* //TODO: requirementsFiles
                    <Col md={6}>
                        <FilesList files={qualityControl.requirementsFiles} />
                    </Col> */}
                </Row>
                <GrayLine />
                <Row className='py-3'>
                    <Col md={6}>
                        <GrayLabel>{t('qualityProblem')}</GrayLabel>
                    </Col>
                    <Col md={6}>
                        <Toggle value={qualityControl.qualityProblem} name='qualityProblem' />
                    </Col>
                </Row>
                <GrayLine />
                <Row className='py-3'>
                    <Col md={6}>
                        <GrayLabel>{t('isSortingNeeded')}</GrayLabel>
                    </Col>
                    <Col md={6}>
                        <Toggle value={qualityControl.sortNeed} name='sortNeed' />
                    </Col>
                </Row>
                <Row>
                    <Wrapper className='py-5 px-5'>
                        <Stack direction='horizontal' gap={5}>
                            <div className='d-flex flex-column align-items-center'>
                                <DarkMainColorLabel className='font-responsive-lg'>
                                    {qualityControl.itemsChecked}
                                </DarkMainColorLabel>
                                <GrayLabel className='font-responsive-md'>
                                    {t('numberOfPiecesChecked')}
                                </GrayLabel>
                            </div>
                            <div className='d-flex flex-column align-items-center'>
                                <DarkMainColorLabel className='font-responsive-lg'>
                                    {qualityControl.itemsCheckedPercent / orderItem.quantity + '%'}
                                </DarkMainColorLabel>
                                <GrayLabel className='font-responsive-md'>
                                    {t('percentageOfCheckedProducts')}
                                </GrayLabel>
                            </div>
                            <div className='d-flex flex-column align-items-center'>
                                <DarkMainColorLabel className='font-responsive-lg'>
                                    {qualityControl.itemsRejected}
                                </DarkMainColorLabel>
                                <GrayLabel className='font-responsive-md'>
                                    {t('numberOfRejectedProducts')}
                                </GrayLabel>
                            </div>
                        </Stack>
                    </Wrapper>
                </Row>
                <Row className='py-3'>
                    <Col md={6}>
                        <GrayLabel>{t('attachmentsToResults')}</GrayLabel>
                    </Col>
                    <Col md={6}>
                        <Stack direction='horizontal' gap={2} className='hstack-wrap'>
                            {qualityControl.qualityControlFiles.map(f => (
                                <AttachmentSpan
                                    key={f.id}
                                    className='pb-2 cursor-pointer'
                                    onClick={() => handleDownloadClick(f)}
                                >
                                    <img
                                        src={textFile}
                                        alt='Text file'
                                        width='30px'
                                        height='30px'
                                    />
                                    {f.fileName}
                                </AttachmentSpan>
                            ))}
                        </Stack>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};
