import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Auth } from '../auth';
import { EnhancedStore } from '@reduxjs/toolkit';
import { Env } from 'env';
import { set } from 'lodash';
import { isBefore, parseISO } from 'date-fns';
import { refreshToken } from 'store/auth/operations';
import { addError } from 'store/errors/slice';
import { prepareErrorType } from 'components/shared/LocalError/helpers';
import { AppError } from 'store/types';

export const api = axios.create({
    baseURL: Env.API_URL,
});

let store: EnhancedStore;

export const injectStore = (_store: EnhancedStore) => {
    store = _store;
};

const addAuthorizationToHeaders = (config: AxiosRequestConfig<any>) => {
    const auth = store.getState().auth.entities as Auth | null;
    const authToken = auth?.token;
    if (!authToken) {
        throw new Error('No token error');
    }

    set(config, 'headers.Authorization', 'Bearer ' + authToken);

    return config;
};

const dispatchRefreshToken = async (config: AxiosRequestConfig<any>) => {
    const auth = store.getState().auth.entities as Auth | null;
    if (auth) {
        const tokenExpDate = parseISO(auth.expiration);
        const currentDate = new Date();
        if (isBefore(tokenExpDate, currentDate)) {
            await (store as any).dispatch(
                refreshToken({ token: auth.token, refreshToken: auth.refreshToken })
            );
        }
    }
};

const handleResponseError = (error: AxiosError) => {
    const appError: AppError = {
        error,
        type: prepareErrorType(error),
    };
    (store as any).dispatch(addError(appError));
    return Promise.reject(error);
};

const noAuthUrls = ['/Password/RequestReset', '/RefreshToken', '/login', '/Password/Reset'];
api.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        if (noAuthUrls.some(u => config.url?.includes(u))) return config;
        await dispatchRefreshToken(config);
        return addAuthorizationToHeaders(config);
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use((response: AxiosResponse) => response, handleResponseError);
api.interceptors.response.use((response: AxiosResponse) => response, handleResponseError);
