import { getOrderCommentsStatsRequest } from 'api/user';
import { isEmpty, isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { useAsync } from 'react-async';
import { usePrevious } from './usePrevious';

export const useOrdersCommentsStats = (ordersIds: number[]) => {
    const getOrderCommentsStats = useAsync({
        deferFn: ([orderCommentsIds]) => getOrderCommentsStatsRequest(orderCommentsIds),
    });

    const previousOrdersIds = usePrevious(ordersIds);

    const ordersCommentsStats = getOrderCommentsStats.data?.data ?? [];

    const getCommentsStats = (orderId: number) => {
        const commentsStats = ordersCommentsStats?.find(c => c.orderId === orderId);
        return commentsStats ? commentsStats : { isCommentsRead: true, commentsAmount: undefined };
    };

    useEffect(() => {
        if (!isEmpty(ordersIds) && !isEqual(ordersIds, previousOrdersIds)) {
            getOrderCommentsStats.run(ordersIds);
        }
    }, [ordersIds, previousOrdersIds]);

    return [getCommentsStats, getOrderCommentsStats.reload] as const;
};
