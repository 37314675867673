import React from 'react';
import { FilterTagList } from 'components/shared/FilterTagList/FilterTagList';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/types';
import { FiltersState, removeFilter } from 'store/filters/slice';
import { DarkMainColorLabel, LightMainColorLabel } from 'styles/styled-components';
import { isArray } from 'lodash';

export const OrdersFiltersList: React.FC = props => {
    const filtersState = useSelector<AppState, FiltersState>(state => state.filters);
    const dispatch = useDispatch();

    const handleRemoveFilter = (filterKey: keyof FiltersState, valueIndex?: number) => {
        dispatch(removeFilter({ filterKey, valueIndex }));
    };

    const filters: { content: React.ReactNode; onExit: () => void }[] = [];
    Object.keys(filtersState).forEach(key => {
        const filter = filtersState[key as keyof FiltersState];
        if (filter) {
            let filtersCmp = [<LightMainColorLabel>{filter.value}</LightMainColorLabel>];
            if (isArray(filter.value)) {
                filtersCmp = filter.value.map(v => (
                    <LightMainColorLabel key={v}>{v}</LightMainColorLabel>
                ));
            }
            if (key === 'areOrdersCompleted') {
                filtersCmp = [<DarkMainColorLabel>{filter.value}</DarkMainColorLabel>];
            }

            filtersCmp.forEach((e, index) => {
                filters.push({
                    content: e,
                    onExit: () =>
                        handleRemoveFilter(
                            key as keyof FiltersState,
                            isArray(filter.value) ? index : undefined
                        ),
                });
            });
        }
    });

    return <>{filters.length > 0 ? <FilterTagList filters={filters} /> : null}</>;
};
