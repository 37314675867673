import { format, parseISO } from 'date-fns';
import { isArray, last } from 'lodash';
import {
    compressedMimeTypes,
    DATE_FORMAT,
    DATE_TIME_FORMAT,
    imageMimeTypes,
    textFileMimeTypes,
} from './consts';
import { Buffer } from 'buffer';

export const ISOToFormattedStringDate = (isoDate: string | null) =>
    isoDate ? format(parseISO(isoDate), DATE_FORMAT) : '';

export const ISOToFormattedStringDateTime = (isoDate: string | null) =>
    isoDate ? format(parseISO(isoDate), DATE_TIME_FORMAT) : '';

export const isDefined = <T>(argument: T | undefined): argument is T => {
    return argument !== undefined;
};

export const isNotNull = <T>(argument: T | null): argument is T => {
    return argument !== null;
};

export function downloadFile(data: BlobPart, filename: string, mime: string) {
    const blob = new Blob([data], { type: mime });
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        window.URL.revokeObjectURL(blobURL);
    }, 100);
}

export const filesToFormData = (files: File[], formDataName = 'files') => {
    const formData = new FormData();
    files.forEach(f => {
        formData.append(formDataName, f);
    });

    return formData;
};

export const isTextFile = (mimeType: string) => textFileMimeTypes.includes(mimeType);
export const isImageFile = (mimeType: string) => imageMimeTypes.includes(mimeType);
export const isCompressedFile = (mimeType: string) => compressedMimeTypes.includes(mimeType);
export const bytesToMB = (bytes: number) => Number((bytes / (1024 * 1024)).toFixed(4));

export const fileToBase64 = (file: File) =>
    new Promise<string | undefined>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString());
        reader.onerror = error => reject(error);
    });

export const encodeBase64 = (data: string) => {
    return Buffer.from(data).toString('base64');
};

export const searchTextToBase64 = (searchText: string | string[] | undefined) => {
    const result = isArray(searchText) ? searchText.join(';') : searchText;
    const additionalProps: { searchText?: string } = {};
    if (result) {
        additionalProps.searchText = encodeBase64(result);
    }

    return additionalProps;
};

export const getByRootItemId = <T extends { id: number; parentId: number }>(
    array: T[],
    rootItemId: number
) => {
    const firstChild = array.filter(x => x.parentId === rootItemId);
    return array.reduce<T[]>((accumulator, currentValue) => {
        const child = last(accumulator);
        if (child) {
            const nextChild = array.filter(e => e.parentId === child.id);
            return nextChild ? [...accumulator, ...nextChild] : accumulator;
        }
        return accumulator;
    }, firstChild);
};
