import React from 'react';
import { Modal } from '../Modal/Modal';
import { ButtonToolbar, Container } from 'react-bootstrap';
import { CenterRow, DarkMainColorLabel } from 'styles/styled-components';
import styled from 'styled-components';
import { Button } from '../Button/Button';
import { useTranslation } from 'react-i18next';

interface MessageModalProps {
    show: boolean;
    message: string;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledButtonToolbar = styled(ButtonToolbar)`
    padding: 0px 20%;
`;

export const MessageModal: React.FC<MessageModalProps> = ({ show, message, setShowModal }) => {
    const { t } = useTranslation();
    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <Modal show={show} centered size='sm' handleClose={handleClose}>
            <Container fluid>
                <CenterRow>
                    <DarkMainColorLabel>{message}</DarkMainColorLabel>
                </CenterRow>
                <CenterRow className='py-4'>
                    <StyledButtonToolbar>
                        <Button variant='outline-primary' onClick={handleClose}>
                            {t('ok')}
                        </Button>
                    </StyledButtonToolbar>
                </CenterRow>
            </Container>
        </Modal>
    );
};
