import { LanguageEnum } from 'api/shared/types';
import i18next from 'i18next';
import { findKey } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectPersonalDataState } from 'store/user/selectors';
import { DEFAULT_LNG_SYMBOL } from 'utils/consts';
import { useLocalStorage } from './useLocalStorage';

const languages = {
    pl: LanguageEnum.Polish,
    en: LanguageEnum.English,
    zh: LanguageEnum.Chinese,
};

export const useChosenLanguage = () => {
    const { entities: personalData } = useSelector(selectPersonalDataState);
    const chosenLngEnum = personalData?.preferedLanguage;

    const [storageLng, setStorageLng] = useLocalStorage<LanguageEnum>(
        'lng',
        languages[DEFAULT_LNG_SYMBOL]
    );

    const lngEnum = !chosenLngEnum || chosenLngEnum.valueOf() === 0 ? storageLng : chosenLngEnum;
    const lngSymbol = findKey(languages, lng => lng === lngEnum);

    useEffect(() => {
        if (lngSymbol) {
            i18next.changeLanguage(lngSymbol);
        }
    }, [lngSymbol]);

    return {
        lngSymbol,
        lngEnum,
        setStorageLng,
    };
};
