import { WidgetsDates } from 'api/shared/types';
import { useState } from 'react';

export const useWidgetsDates = () => {
    const [onTimeDeliveryDates, setOnTimeDeliveryDates] = useState<WidgetsDates>({
        dateFrom: null,
        dateTo: null,
    });

    const [qualityControlResultsDates, setQualityControlResultsDates] = useState<WidgetsDates>({
        dateFrom: null,
        dateTo: null,
    });

    const [openOrderDates, setOpenOrderDates] = useState<WidgetsDates>({
      dateFrom: null,
      dateTo: null,
  });

    return {
        onTimeDeliveryDates,
        setOnTimeDeliveryDates,

        qualityControlResultsDates,
        setQualityControlResultsDates,

        openOrderDates,
        setOpenOrderDates,

    };
};
