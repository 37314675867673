import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledSpinnerMask = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    height: 100%;
`;

export const SpinnerMaskWrapper = styled.div`
    position: relative;
`;

interface SpinnerMaskProps {
    children: React.ReactNode;
    show: boolean;
}

export const SpinnerMask: React.FC<SpinnerMaskProps> = props => {
    const { children, show } = props;
    return (
        <>
            {show && (
                <StyledSpinnerMask>
                    <SpinnerMaskWrapper>
                        <Spinner animation='border' variant='primary' />
                    </SpinnerMaskWrapper>
                </StyledSpinnerMask>
            )}
            {children}
        </>
    );
};
