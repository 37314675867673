import { OnTimeDelivery, WidgetsDates } from 'api/shared/types';
import { AxiosResponse } from 'axios';
import { AutoSubmit } from 'components/shared/AutoSubmit/AutoSubmit';
import { DatePicker } from 'components/shared/DatePicker/DatePicker';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { Formik } from 'formik';
import React from 'react';
import { useAsync } from 'react-async';
import { Col, Form, Row } from 'react-bootstrap';
import {
    CenterRow,
    DarkMainColorLabel,
    LightMainColorLabel,
    WidgetContainer,
} from 'styles/styled-components';
import { useTranslation } from 'react-i18next';

interface OnTimeDeliveryMenuProps {
    dates: WidgetsDates;
    setOnTimeDeliveryDates: React.Dispatch<React.SetStateAction<WidgetsDates>>;
    getOnTimeDeliveryRequest: (dates: WidgetsDates) => Promise<AxiosResponse<OnTimeDelivery, any>>;
}

export const OnTimeDeliveryMenu: React.FC<OnTimeDeliveryMenuProps> = props => {
    const { t } = useTranslation();
    const { getOnTimeDeliveryRequest, dates, setOnTimeDeliveryDates } = props;
    const fetchOnTimeDelivery = useAsync({
        deferFn: ([newDates]) => getOnTimeDeliveryRequest(newDates),
    });

    const onTimeDelivery = fetchOnTimeDelivery.data?.data;

    return (
        <WidgetContainer>
            <SpinnerMask show={fetchOnTimeDelivery.isPending}>
                <Formik
                    initialValues={{ ...dates }}
                    onSubmit={async (values, { setSubmitting }) => {
                        fetchOnTimeDelivery.run(values);
                        setOnTimeDeliveryDates(values);
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <CenterRow className='py-4'>
                                <Col md={6}>
                                    <DatePicker name='dateFrom' showClearIcon />
                                </Col>
                                <Col md={6}>
                                    <DatePicker name='dateTo' showClearIcon />
                                </Col>
                            </CenterRow>
                            <AutoSubmit />
                        </Form>
                    )}
                </Formik>
                <Row className='py-1'>
                    <Col md={9} className='flexEnd'>
                        <DarkMainColorLabel>{t('ordersDelayed')}</DarkMainColorLabel>
                    </Col>
                    <Col md={3}>
                        <LightMainColorLabel className='font-24'>
                            {onTimeDelivery?.ordersDelayed ?? 0}
                        </LightMainColorLabel>
                    </Col>
                </Row>
                <Row className='py-1'>
                    <Col md={9} className='flexEnd'>
                        <DarkMainColorLabel>{t('onTimeOrders')}</DarkMainColorLabel>
                    </Col>
                    <Col md={3}>
                        <LightMainColorLabel className='font-24'>
                            {onTimeDelivery?.ordersOnTime ?? 0}
                        </LightMainColorLabel>
                    </Col>
                </Row>
                <Row className='py-1'>
                    <Col md={9} className='flexEnd'>
                        <DarkMainColorLabel>{t('ordersInTotal')}</DarkMainColorLabel>
                    </Col>
                    <Col md={3}>
                        <LightMainColorLabel className='font-24'>
                            {onTimeDelivery?.ordersTotal ?? 0}
                        </LightMainColorLabel>
                    </Col>
                </Row>
                <Row className='py-1'>
                    <Col md={9} className='flexEnd'>
                        <DarkMainColorLabel>{t('percentOfDelayedOrders')}</DarkMainColorLabel>
                    </Col>
                    <Col md={3}>
                        <LightMainColorLabel className='font-24'>
                            {onTimeDelivery?.ordersDelayedOrdersPercent ?? 0}
                        </LightMainColorLabel>
                    </Col>
                </Row>
            </SpinnerMask>
        </WidgetContainer>
    );
};
