import React, { useState } from 'react';
import styled from 'styled-components';
import { bytesToMB, fileToBase64, isImageFile } from 'utils/helpers';
import { Base64Img } from '../Base64Img/Base64Img';

import addPhoto from 'assets/images/add-photo.svg';
import { imageMimeTypes } from 'utils/consts';
import { IconButton } from '../IconButton/IconButton';
import { FileUploader } from '../FileUploader/FileUploader';

interface AvatarEditorProps {
    avatar?: string;
    setAvatar: React.Dispatch<React.SetStateAction<string | undefined>>;
    setIsInvalidImage: React.Dispatch<React.SetStateAction<boolean>>;
    setAvatarFile: React.Dispatch<React.SetStateAction<File | undefined>>;
    position?: 'absolute' | 'relative';
}

const AvatarContainer = styled.div<{ $position: 'absolute' | 'relative' }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: ${({ $position }) => $position};
    width: 74px;
    height: 74px;
    left: calc(50% - 37px);
    top: 14%;
`;

const AddPhotoContainer = styled.div`
    position: absolute;
    left: 70%;
    top: 65%;
    background: white;
    border-radius: 50%;
    cursor: pointer;
`;

export const AvatarEditor: React.FC<AvatarEditorProps> = props => {
    const { avatar, setAvatar, setAvatarFile, setIsInvalidImage, position = 'absolute' } = props;

    const handleImageFileChange = async (file: File) => {
        const isImage = isImageFile(file.type);
        const bytesSize = bytesToMB(file.size);
        const invalidFile = !isImage || bytesSize > 0.5;
        setIsInvalidImage(invalidFile);
        if (invalidFile) return;

        setAvatarFile(file);
        const newAvatar = await fileToBase64(file);
        setAvatar(newAvatar);
    };
    return (
        <AvatarContainer $position={position}>
            <Base64Img src={avatar} />
            <AddPhotoContainer>
                <FileUploader
                    onFileChange={handleImageFileChange}
                    allowedMimeTypes={imageMimeTypes}
                >
                    <IconButton image={addPhoto} stopClickable />
                </FileUploader>
            </AddPhotoContainer>
        </AvatarContainer>
    );
};
