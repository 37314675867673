import { AddOrderComment } from 'api/shared/types';
import { Button } from 'components/shared/Button/Button';
import { Modal } from 'components/shared/Modal/Modal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonToolbar, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DarkMainColorLabel, GrayLabel, GrayLine } from 'styles/styled-components';
import { AddComment } from './AddComment/AddComment';
import { CommentsList } from './CommentsList/CommentsList';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { useAsync } from 'react-async';
import {
    addCommentRequest,
    getOrderCommentsRequest,
    updateOrderCommentsReadStatusRequest,
} from 'api/user';
import { useSelector } from 'react-redux';
import { authSelector } from 'store/auth/selectors';
import { isEmpty } from 'lodash';

interface CommentsModalProps {
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;

    number: string;
    orderId: number;
    allCommentsRead: boolean;
    reloadCommentsStats: () => void;
}

export const CommentsModal: React.FC<CommentsModalProps> = props => {
    const { show, setShowModal, number, orderId, allCommentsRead, reloadCommentsStats } = props;
    const { t } = useTranslation();
    const [showAddComment, setShowAddComment] = useState(false);
    const auth = useSelector(authSelector);

    const getOrderComments = useAsync({
        deferFn: ([orderCommentsId]) => getOrderCommentsRequest(orderCommentsId),
    });

    const updateOrderCommentsReadStatus = useAsync({
        deferFn: ([userId, orderCommentsId, commentsIds]) =>
            updateOrderCommentsReadStatusRequest(userId, orderCommentsId, commentsIds),
    });

    useEffect(() => {
        if (show) {
            getOrderComments.run(orderId);
        }
    }, [show]);

    const orderComments = getOrderComments.data?.data ?? [];

    useEffect(() => {
        if (!isEmpty(orderComments) && auth?.userId && !allCommentsRead) {
            const commentsIds = orderComments.map(o => o.id);
            updateOrderCommentsReadStatus.run(auth.userId, orderId, commentsIds);
        }
    }, [getOrderComments.data, allCommentsRead]);

    const handleCloseModal = () => {
        reloadCommentsStats();
        setShowModal(false);
    };

    const handleShowAddComment = () => {
        setShowAddComment(state => !state);
    };

    const handleAddComment = async (comment: AddOrderComment) => {
        await addCommentRequest(comment, orderId);
        getOrderComments.reload();
        setShowAddComment(false);
    };

    return (
        <Modal show={show} handleClose={handleCloseModal}>
            <SpinnerMask show={getOrderComments.isPending}>
                <Container>
                    <Row>
                        <Col>
                            <GrayLabel className='fw-bold me-2'>{t('orderNumber')}</GrayLabel>
                            <DarkMainColorLabel className='fw-bold'>{number}</DarkMainColorLabel>
                        </Col>
                    </Row>
                    <GrayLine className='mb-2' />
                    <CommentsList
                        comments={orderComments}
                        orderId={orderId}
                        reloadComments={getOrderComments.reload}
                    />
                    <ButtonToolbar className='py-3 px-8'>
                        <Button variant='primary' onClick={handleShowAddComment}>
                            {t('addComment')}
                        </Button>
                    </ButtonToolbar>
                    {showAddComment && (
                        <AddComment handleAddComment={handleAddComment} parentId={0} />
                    )}
                </Container>
            </SpinnerMask>
        </Modal>
    );
};
