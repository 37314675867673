import { OrderRealization } from 'api/shared/types';
import React from 'react';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { AddedRealization } from './AddedRealization/AddedRealization';
import { v4 as uuidv4 } from 'uuid';
import { StyledLine } from 'styles/styled-components';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectOrderRealizationsState } from 'store/supplier/selectors';

interface RealizationFormProps {
    realizations: Array<OrderRealization>;
    orderItemId: number;
    orderId: number;
}

export const RealizationLabel = styled.div`
    display: flex;
    font-size: 1vw;
    color: ${variables.darkMainColor};
    font-weight: 500;
`;

const StyledLayout = styled(Container)`
    background-color: #ebeaea;
    width: 80.4%;
    margin-left: 19.6%;
    border-radius: 0 0 20px 20px;
`;

export const RealizationList: React.FC<RealizationFormProps> = props => {
    const { realizations, orderId } = props;
    const { loading } = useSelector(selectOrderRealizationsState);

    return (
        <SpinnerMask show={loading}>
            <StyledLayout fluid className='px-4'>
                {realizations.map((addedRealization, index) => (
                    <React.Fragment key={uuidv4()}>
                        <AddedRealization
                            index={index + 1}
                            realization={addedRealization}
                            orderId={orderId}
                        />
                        {realizations.length > 1 && <StyledLine />}
                    </React.Fragment>
                ))}
            </StyledLayout>
        </SpinnerMask>
    );
};
