import { IconButton } from 'components/shared/IconButton/IconButton';
import React, { useRef, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import more from 'assets/images/more.svg';
import roundAdd from 'assets/images/round-add.svg';
import edit from 'assets/images/edit.svg';
import { Popover } from 'components/shared/Popover/Popover';
import { ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import { Row as TableRow } from 'react-table';
import { useDispatch } from 'react-redux';
import { addQualityControl } from 'store/qualityController/slice';
import { useTranslation } from 'react-i18next';

const StyledListGroupItem = styled(ListGroup.Item)`
    display: flex;
    align-items: center;
    border-bottom: none;
    padding: 5px;
    width: 100%;
`;

const StyledSpan = styled.span`
    color: ${variables.darkMainColor};
    font-weight: 500;
    font-size: 0.85rem;
    text-align: left;
`;

const StyledListGroup = styled(ListGroup)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    padding: 10px;
`;

export interface Config {
    handleOnClick: () => void;
    label: string;
    image: string;
    dataCy?: string;
    iconSize: 'sm' | 'lg';
}

interface MorePopoverProps {
    config: Config[];
    dataCyIndex?: number;
}

export const MorePopover: React.FC<MorePopoverProps> = props => {
    const { config, dataCyIndex } = props;
    const [showPopover, setShowPopover] = useState(false);
    const handleIconClick = () => {
        setShowPopover(state => !state);
    };

    const handleClickItem = (handleOnClick: () => void) => {
        setShowPopover(false);
        handleOnClick();
    };

    const popover = (
        <Popover>
            <StyledListGroup variant='flush'>
                {config.map(c => (
                    <StyledListGroupItem key={c.label}>
                        <IconButton
                            image={c.image}
                            isRow
                            size={c.iconSize}
                            dataCy={c.dataCy}
                            onClick={() => handleClickItem(c.handleOnClick)}
                        >
                            <StyledSpan>{c.label}</StyledSpan>
                        </IconButton>
                    </StyledListGroupItem>
                ))}
            </StyledListGroup>
        </Popover>
    );
    return (
        <OverlayTrigger show={showPopover} placement='bottom' overlay={popover}>
            <IconButton
                ariaLabel='more'
                image={more}
                onClick={handleIconClick}
                dataCy={`trippleDotButton-${dataCyIndex}`}
            />
        </OverlayTrigger>
    );
};
