import React, { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MAX_FILE_SIZE_IN_MB } from 'utils/consts';
import { bytesToMB } from 'utils/helpers';
import { v4 as uuidv4 } from 'uuid';

export const StyledFileUploaderWrapper = styled.div`
    input[type='file'] {
        display: none;
    }
`;

interface FileUploaderCommonProps {
    children: React.ReactNode;
    name?: string;
    allowedMimeTypes?: string;
}

type FileUploaderProps =
    | ({
          multiple: true;
          onFileChange: (files: File[]) => void;
      } & FileUploaderCommonProps)
    | ({
          multiple?: false;
          onFileChange: (file: File) => void;
      } & FileUploaderCommonProps);

const someFileIsTooBig = (rawFiles: File[]) => {
    const filesValid = rawFiles.every(file => {
        const bytesSize = bytesToMB(file.size);
        return bytesSize <= MAX_FILE_SIZE_IN_MB;
    });

    return !filesValid;
};

export const FileUploader: React.FC<FileUploaderProps> = props => {
    const { multiple, onFileChange, children, name, allowedMimeTypes } = props;
    const { t } = useTranslation();
    const [isInvalidFile, setIsInvalidFile] = useState(false);

    const inputId = allowedMimeTypes;

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        const files = Array.from(event.target.files);
        const filesInvalid = someFileIsTooBig(files);
        setIsInvalidFile(filesInvalid);

        if (!filesInvalid) {
            if (multiple) {
                onFileChange(files);
            } else {
                onFileChange(files[0]);
            }
        }
    };
    return (
        <>
            <StyledFileUploaderWrapper>
                <label htmlFor={inputId} style={{ cursor: 'pointer' }}>
                    {children}
                </label>
                <input
                    aria-label='file-uploader'
                    id={inputId}
                    type='file'
                    name={name}
                    onChange={handleFileChange}
                    accept={allowedMimeTypes}
                    multiple={multiple}
                />
            </StyledFileUploaderWrapper>
            {isInvalidFile && (
                <div className='text-center'>
                    <Form.Control.Feedback type='invalid'>
                        {t('fileSizeValidation')}
                    </Form.Control.Feedback>
                </div>
            )}
        </>
    );
};
