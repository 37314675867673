import React, { useEffect, useState } from 'react';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import editUser from 'assets/images/edit-user.svg';
import userStatistics from 'assets/images/user-statistics.svg';
import activeUser from 'assets/images/active-user.svg';
import inactiveUser from 'assets/images/inactive-user.svg';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { AdminUser } from 'api/admin';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from 'store/types';
import { adminUsersSelector } from 'store/admin/selectors';
import { getUsers } from 'store/admin/operations';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import styles from './AdminUsersTable.module.scss';
import { IconButton } from 'components/shared/IconButton/IconButton';
import { Base64Img } from 'components/shared/Base64Img/Base64Img';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { UserStatisticsModal } from '../UserStatisticsModal/UserStatisticsModal';
import { EditUserModal } from '../EditUserModal/EditUserModal';

const StyledDataLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 1%;
`;

const StatusCmp: React.FC<{ row: AdminUser }> = ({ row }) => {
    const { t } = useTranslation();
    return (
        <span className={styles.status}>
            {t('status')}
            <span className={styles.smallIcon}>
                <img src={row.isActive && !row.lockoutEnabled ? activeUser : inactiveUser} />
            </span>
        </span>
    );
};

const StatisticsCmp: React.FC<{ row: AdminUser }> = ({ row }) => {
    const [showModal, setShowModal] = useState(false);
    const handleClick = () => {
        setShowModal(true);
    };
    return (
        <>
            <div className={styles.iconStatisticsDiv}>
                <IconButton
                    image={userStatistics}
                    onClick={handleClick}
                    ariaLabel='user-statistics'
                />
            </div>
            <UserStatisticsModal setShowModal={setShowModal} show={showModal} userId={row.id} />
        </>
    );
};

const EditUserCmp: React.FC<{ row: AdminUser }> = ({ row }) => {
    const [showModal, setShowModal] = useState(false);
    const handleClick = () => {
        setShowModal(true);
    };
    return (
        <>
            <div className={styles.iconEditDiv}>
                <IconButton image={editUser} onClick={handleClick} ariaLabel='edit-user' />
            </div>
            <EditUserModal show={showModal} setShowModal={setShowModal} user={row} />
        </>
    );
};

const UserNameCmp: React.FC<{ row: AdminUser }> = ({ row }) => {
    return (
        <Container fluid>
            <Row className={styles.user}>
                <Col md={3} className={styles.avatar}>
                    <Base64Img src={row.avatar} />
                </Col>
                <Col md={9} className={styles.nameLogin}>
                    <p className={styles.name}>{row.userName}</p>
                    <span className={styles.login}>{row.login}</span>
                </Col>
            </Row>
        </Container>
    );
};

const getColumns = (): Array<ColumnDescription<AdminUser>> => [
    {
        dataField: 'userName',
        text: `${i18n.t('userName')}`,
        headerStyle: () => {
            return { width: '50%', visibility: 'hidden', textTransform: 'capitalize' };
        },
        formatter: (cell: string, row: AdminUser) => <UserNameCmp row={row} />,
    },
    {
        dataField: 'status',
        text: `${i18n.t('status')}`,
        headerStyle: () => {
            return { width: '20%', visibility: 'hidden', textTransform: 'capitalize' };
        },
        formatter: (cell: boolean, row: AdminUser) => <StatusCmp row={row} />,
    },
    {
        dataField: 'edit',
        text: `${i18n.t('edit')}`,
        headerStyle: () => {
            return { width: '10%', visibility: 'hidden', textTransform: 'capitalize' };
        },
        formatter: (cell: boolean, row: AdminUser) => <EditUserCmp row={row} />,
    },
    {
        dataField: 'statistics',
        text: `${i18n.t('statistics')}`,
        headerStyle: () => {
            return { width: '20%', visibility: 'hidden', textTransform: 'capitalize' };
        },
        formatter: (cell: boolean, row: AdminUser) => <StatisticsCmp row={row} />,
    },
];

const SIZE_PER_PAGE = 10;

export const AdminUsersTable: React.FC = () => {
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const users = useSelector<AppState, Array<AdminUser> | []>(adminUsersSelector);
    const { loading } = useSelector((state: AppState) => state.admin.users);
    const fetchUsers = async () => {
        await dispatch(getUsers());
    };
    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <StyledDataLayout>
            <SpinnerMask show={loading}>
                <BootstrapTable
                    keyField='id'
                    columns={getColumns()}
                    data={users}
                    bordered={false}
                    noDataIndication={() => t('noUsers')}
                    pagination={paginationFactory({
                        sizePerPage: SIZE_PER_PAGE,
                        hidePageListOnlyOnePage: true,
                        hideSizePerPage: true,
                    })}
                />
            </SpinnerMask>
        </StyledDataLayout>
    );
};
