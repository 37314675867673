import React from 'react';
import { Order, OrderComment, OrderRealization } from 'api/shared/types';
import { ISOToFormattedStringDate } from 'utils/helpers';
import { flatMap, map } from 'lodash';
import greenTick from 'assets/images/green-tick.svg';
import redCross from 'assets/images/red-cross.svg';
import addBatch from 'assets/images/add-batch.svg';
import dash from 'assets/images/dash.svg';
import collapseRow from 'assets/images/collapse-row.svg';
import { IconButton } from 'components/shared/IconButton/IconButton';
import { Column } from 'react-table';
import { Row as TableRow } from 'react-table';
import i18n from 'i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/types';
import { addRealization } from 'store/supplier/slice';

export interface OrderTableItem {
    orderId: number;
    orderItemId: number;
    number: string;
    supplierOrderNumber: string;
    index: string;
    name: string;
    quantity: number;
    expectedReadiness?: string;
    destination: string;
    orderFullfillment: number;
    orderFullfillmentPercent: string;
    qualityResult: boolean | null;
    realizations: Array<OrderRealization>;
}

export const columns = [
    {
        Header: () => i18n.t('orderNumber'),
        id: 'number',
        accessor: (row: OrderTableItem, rowIndex: number) => row.number,
        width: 1,
        sortDescFirst: true,
    },
    {
        Header: () => i18n.t('supplierOrderNumber'),
        id: 'supplierOrderNumber',
        accessor: (row: OrderTableItem, rowIndex: number) => row.supplierOrderNumber,
        width: 2,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('index'),
        id: 'index',
        accessor: 'index',
        width: 1,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('name'),
        id: 'name',
        accessor: 'name',
        width: 3,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('quantityOrdered'),
        id: 'quantity',
        accessor: 'quantity',
        width: 1,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('expectedReadiness'),
        id: 'expectedReadiness',
        accessor: (row: OrderTableItem, rowIndex: number) =>
            row.expectedReadiness ? ISOToFormattedStringDate(row.expectedReadiness) : '',
        width: 1,
        sortDescFirst: true,
    },
    {
        Header: () => i18n.t('destination'),
        id: 'destination',
        accessor: 'destination',
        width: 1,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('qualityControl'),
        id: 'qualityResult',
        accessor: (row: OrderTableItem, rowIndex: number) => (
            <span>
                <img
                    src={
                        row.qualityResult ? greenTick : row.qualityResult === null ? dash : redCross
                    }
                />
            </span>
        ),
        width: 1,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('orderFulfillment'),
        id: 'orderFullfillment',
        accessor: 'orderFullfillment',
        disableSortBy: true,
        width: 1,
    },
    {
        Header: () => i18n.t('percentOfFulfillment'),
        id: 'orderFullfillmentPercent',
        accessor: (row: OrderTableItem) => row.orderFullfillmentPercent,
        disableSortBy: true,
        width: 1,
    },
    {
        Header: () => i18n.t('addBatch'),
        id: 'expander',
        Cell: ({ row }: { row: TableRow<OrderTableItem> }) => {
            const someRealizations = row.original.realizations.length > 0;
            const dispatch = useDispatch<AppDispatch>();

            const handleClick = () => {
                if (!someRealizations) {
                    dispatch(addRealization(row.original.orderItemId));
                }
                row.toggleRowExpanded();
            };
            return (
                <IconButton
                    dataCy={`rowButton-${row.id}`}
                    size={row.original.realizations.length > 0 ? 'xs' : 'lg'}
                    image={row.original.realizations.length > 0 ? collapseRow : addBatch}
                    onClick={handleClick}
                    rotation={row.isExpanded ? 0 : 180}
                />
            );
        },
        disableSortBy: true,
        width: 1,
    },
] as Column<OrderTableItem>[];

export const getFlattenData = (orders: Array<Order>): Array<OrderTableItem> => {
    return flatMap(orders, ({ number, supplierOrderNumber, id, orderItems }) =>
        map(orderItems, orderItem => ({
            number,
            supplierOrderNumber,
            ...orderItem,
            orderId: id,
            orderItemId: orderItem.id,
        }))
    );
};
