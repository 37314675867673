import React, { useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import search from 'assets/images/search.svg';
import { StyledInput } from '../Input/Input';
import styled from 'styled-components';
import { IconButton } from '../IconButton/IconButton';
import { useTranslation } from 'react-i18next';

interface SearchInputProps {
    onSearch: (value: string) => void;
    iconAlignment?: 'center' | 'flex-end' | 'flex-start';
}

const StyledInputGroupText = styled(InputGroup.Text)`
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledInputGroup = styled(InputGroup)<{
    $iconAlignment: 'center' | 'flex-end' | 'flex-start';
}>`
    align-items: center;
    justify-content: ${props => props.$iconAlignment};
`;

export const SearchInput: React.FC<SearchInputProps> = props => {
    const { t } = useTranslation();
    const { onSearch, iconAlignment = 'center' } = props;
    const [value, setValue] = useState('');
    const [showInput, setShowInput] = useState(false);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        setValue(newValue);
        onSearch(newValue);
    };

    const handleClick = () => {
        setShowInput(state => !state);
    };

    return (
        <StyledInputGroup $iconAlignment={iconAlignment}>
            <StyledInputGroupText>
                <IconButton image={search} onClick={handleClick} size='lg' />
            </StyledInputGroupText>
            {showInput && (
                <StyledInput
                    autoFocus
                    type='text'
                    placeholder={t('typeToSearch')}
                    value={value}
                    onChange={handleChange}
                />
            )}
        </StyledInputGroup>
    );
};
