import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/esm/types';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import { v4 as uuidv4 } from 'uuid';
import DropdownButton from 'react-bootstrap/DropdownButton';

interface DropdownMenuProps {
    label: string;
    variant?: ButtonVariant;
    children?: React.ReactNode;
}

const StyledDropdown = styled(Dropdown)`
    .dropdown-toggle.btn.btn-primary {
        color: #fff;
    }
`;

const StyledDropdownToggle = styled(Dropdown.Toggle)<{ variant: ButtonVariant }>`
    display: flex;
    background-image: ${variables.mainGradientColor};
    color: ${props =>
        props.variant === 'outline-primary' || props.variant === 'link'
            ? variables.darkMainColor
            : props.variant === 'primary'
            ? '#FFF'
            : variables.darkMainColor};
    width: 100%;
    border-radius: 44px;
    height: ${variables.defaultElementHeight};
    justify-content: center;
    align-items: center;
    text-decoration: none;
    &:active {
        background-color: ${props =>
            props.variant === 'link'
                ? variables.lightTransparentMainColor
                : variables.darkMainColor};
    }
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
    padding: 0;
    border: none;
    background-color: transparent;
`;

export const DropdownMenu: React.FC<DropdownMenuProps> = props => {
    const { label, variant = 'primary', children } = props;
    const [key, setKey] = useState('');
    const handleToggle = () => {
        setKey(uuidv4());
    };

    return (
        <StyledDropdown onToggle={handleToggle}>
            <StyledDropdownToggle id={label} variant={variant}>
                {label}
            </StyledDropdownToggle>
            <StyledDropdownMenu key={key}>{children}</StyledDropdownMenu>
        </StyledDropdown>
    );
};
