import React, { useState } from 'react';
import {
    FileType,
    Order,
    OrderComment,
    OrderItem,
    OrderRealization,
    OrdersRequestInput,
    QualityControl,
} from 'api/shared/types';
import { downloadFile, filesToFormData, ISOToFormattedStringDate } from 'utils/helpers';
import { flatMap, map } from 'lodash';
import greenTick from 'assets/images/green-tick.svg';
import redCross from 'assets/images/red-cross.svg';
import dash from 'assets/images/dash.svg';
import collapseRow from 'assets/images/collapse-row.svg';
import { IconButton } from 'components/shared/IconButton/IconButton';
import { Column } from 'react-table';
import { Row as TableRow } from 'react-table';
import { DarkMainColorLabel } from 'styles/styled-components';
import { Stack } from 'react-bootstrap';
import addAttachment from 'assets/images/add-attachment.svg';
import attachment from 'assets/images/attachment.svg';
import { QualityControlResultsModal } from '../../QualityControlResultsModal/QualityControlResultsModal';
import { useDispatch, useSelector } from 'react-redux';
import { orderItemSelector } from 'store/employee/selectors';
import { AppDispatch, AppError, AppState } from 'store/types';
import { AddFilesModal } from 'components/desktop/AddFilesModal/AddFilesModal';
import { addQualityControlGuidelines } from 'api/employee';
import { getOrders } from 'store/employee/operations';
import { ModifyFilesModal } from '../../ModifyFilesModal/ModifyFilesModal';
import i18n, { t } from 'i18next';

export interface OrderTableItem {
    orderId: number;
    orderItemId: number;
    number: string;
    supplierOrderNumber: string;
    index: string;
    name: string;
    quantity: number;
    expectedReadiness?: string;
    destination: string;
    orderFullfillment: number;
    orderFullfillmentPercent: string;
    qualityResult: boolean | null;
    realizations: Array<OrderRealization>;
    qualityControls: Array<QualityControl>;
    orderItemFiles: Array<FileType>;
}

export const columns = [
    {
        Header: () => i18n.t('orderNumber'),
        id: 'number',
        accessor: (row: OrderTableItem, rowIndex: number) => row.number,
        width: 1,
    },
    {
        Header: () => i18n.t('supplierOrderNumber'),
        id: 'supplierOrderNumber',
        accessor: (row: OrderTableItem, rowIndex: number) => row.supplierOrderNumber,
        width: 2,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('index'),
        id: 'index',
        accessor: 'index',
        width: 1,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('name'),
        id: 'name',
        accessor: 'name',
        width: 3,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('quantityOrdered'),
        id: 'quantity',
        accessor: 'quantity',
        width: 1,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('expectedReadiness'),
        id: 'expectedReadiness',
        accessor: (row: OrderTableItem, rowIndex: number) =>
            row.expectedReadiness ? ISOToFormattedStringDate(row.expectedReadiness) : '',
        width: 1,
    },
    {
        Header: () => i18n.t('destination'),
        id: 'destination',
        accessor: 'destination',
        width: 1,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('qualityControlGuidelines'),
        id: 'orderItemFiles',
        Cell: ({
            row,
            ordersRequestInput,
        }: {
            row: TableRow<OrderTableItem>;
            ordersRequestInput?: OrdersRequestInput;
        }) => {
            const dispatch = useDispatch<AppDispatch>();
            const [showModal, setShowModal] = useState(false);
            const [modifyFilesModal, setModifyFilesModal] = useState(false);
            const handleOnAddFileClick = () => {
                setShowModal(true);
            };

            const handleSaveFiles = async (newFiles: File[]) => {
                await addQualityControlGuidelines(
                    filesToFormData(newFiles),
                    row.original.orderItemId
                );
                if (ordersRequestInput) {
                    dispatch(getOrders(ordersRequestInput));
                }
            };
            const filesLength = row.original.orderItemFiles?.length ?? 0;

            const handleShowFiles = () => {
                setModifyFilesModal(true);
            };

            if (filesLength > 0)
                return (
                    <>
                        <Stack direction='horizontal' gap={1}>
                            <IconButton
                                image={attachment}
                                onClick={handleShowFiles}
                                size='xs'
                                dataCy={`addGuidelinesButton-${row.index}`}
                            />
                            {filesLength > 0 && filesLength}
                        </Stack>
                        <ModifyFilesModal
                            files={row.original.orderItemFiles}
                            show={modifyFilesModal}
                            setModifyFilesModal={setModifyFilesModal}
                            title={t('showQualityControlGuidelines')}
                            orderItemId={row.original.orderItemId}
                            ordersRequestInput={ordersRequestInput}
                        />
                    </>
                );
            return (
                <>
                    <Stack direction='horizontal' gap={2}>
                        <IconButton
                            image={addAttachment}
                            onClick={handleOnAddFileClick}
                            size='xs'
                            dataCy={`addGuidelinesButton-${row.index}`}
                        />
                    </Stack>
                    <AddFilesModal
                        show={showModal}
                        onSaveFiles={handleSaveFiles}
                        setShowModal={setShowModal}
                        title={t('addQualityControlGuideline')}
                    />
                </>
            );
        },
        disableSortBy: true,
        width: 1,
    },
    {
        Header: () => i18n.t('qualityControl'),
        id: 'qualityResult',
        Cell: ({ row }: { row: TableRow<OrderTableItem> }) => {
            const orderItem = useSelector<AppState, OrderItem | null>(state =>
                orderItemSelector(state, { orderItemId: row.original.orderItemId })
            );
            const [showModal, setShowModal] = useState(false);
            const handleShowModal = () => {
                setShowModal(true);
            };

            if (!orderItem) return null;

            if (row.original.qualityResult === null)
                return (
                    <span>
                        <img src={dash} />
                    </span>
                );
            return (
                <>
                    <IconButton
                        image={row.original.qualityResult ? greenTick : redCross}
                        onClick={handleShowModal}
                        size='xs'
                        dataCy='qualityResultButton'
                    />
                    <QualityControlResultsModal
                        show={showModal}
                        setShowModal={setShowModal}
                        orderItem={orderItem}
                        qualityControls={row.original.qualityControls}
                    />
                </>
            );
        },
        width: 1,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('orderFulfillment'),
        id: 'orderFullfillment',
        accessor: 'orderFullfillment',
        disableSortBy: true,
        width: 1,
    },
    {
        Header: () => i18n.t('percentOfFulfillment'),
        id: 'orderFullfillmentPercent',
        accessor: (row: OrderTableItem) => row.orderFullfillmentPercent,
        disableSortBy: true,
        width: 1,
    },
    {
        Header: () => i18n.t('quantityOfShipment'),
        id: 'expander',
        Cell: ({ row }: { row: TableRow<OrderTableItem> }) => {
            const handleClick = () => {
                row.toggleRowExpanded();
            };

            return (
                <Stack direction='horizontal' gap={2}>
                    <DarkMainColorLabel>{row.original.realizations.length}</DarkMainColorLabel>
                    <IconButton
                        size='xs'
                        image={collapseRow}
                        onClick={handleClick}
                        rotation={row.isExpanded ? 0 : 180}
                    />
                </Stack>
            );
        },
        disableSortBy: true,
        width: 1,
    },
] as Column<OrderTableItem>[];

export const getFlattenData = (orders: Array<Order>): Array<OrderTableItem> => {
    return flatMap(orders, ({ number, supplierOrderNumber, id, orderItems }) =>
        map(orderItems, orderItem => ({
            number,
            supplierOrderNumber,
            ...orderItem,
            orderId: id,
            orderItemId: orderItem.id,
        }))
    );
};
