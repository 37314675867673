import React, { useMemo, useState } from 'react';
import { Button } from 'components/shared/Button/Button';
import { Input } from 'components/shared/Input/Input';
import { Formik } from 'formik';
import { ButtonToolbar, Container, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import * as yup from 'yup';
import { Auth, LoginData, Roles } from 'api/auth';
import { useDispatch, useSelector } from 'react-redux';
import { login } from 'store/auth/operations';
import { AppDispatch, AppState } from 'store/types';
import { LocalError } from 'components/shared/LocalError/LocalError';
import { MainPage } from '../MainPage/MainPage';
import { AuthState } from 'store/auth/slice';
import { getRoles } from 'store/admin/operations';
import { GrayLabel } from 'styles/styled-components';
import { Link } from 'react-router-dom';
import routes from 'routes-desktop.json';
import { useTranslation } from 'react-i18next';
import { LanguageEnum } from 'api/shared/types';
import { ChooseLanguage } from '../ChooseLanguage/ChooseLanguage';
import { useChosenLanguage } from 'hooks/useChosenLanguage';

const StyledLogin = styled(Container)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    position: absolute;
    width: 28%;
    min-height: 50%;
    left: 22%;
    top: 33%;
    background: #fefefe;
    border-radius: 34px;
    border: 2px solid ${variables.darkMainColor};
    padding: 3vh 3vw;
`;

const StyledButtonToolbar = styled(ButtonToolbar)`
    padding: 2vh 3vw 0px;
`;

const Login: React.FC = () => {
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const { error } = useSelector<AppState, AuthState>(state => state.auth);
    const { lngEnum, setStorageLng } = useChosenLanguage();
    const handleChooseLanguage = (language: LanguageEnum) => {
        setStorageLng(language);
    };

    const handleOnSubmit = async (values: LoginData) => {
        const response = await dispatch(login(values));
        if ((response.payload as Auth).roles?.[0] === Roles.Admin) {
            await dispatch(getRoles());
        }
    };

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                login: yup.string().required(t('theFieldIsRequired')),
                password: yup.string().required(t('theFieldIsRequired')),
            }),
        []
    );

    return (
        <MainPage>
            <StyledLogin>
                <Row className='pb-3'>
                    <ChooseLanguage
                        chosenLanguage={lngEnum}
                        handleChooseLanguage={handleChooseLanguage}
                    />
                </Row>
                <Formik
                    initialValues={{ login: '', password: '' }}
                    onSubmit={handleOnSubmit}
                    validationSchema={validationSchema}
                >
                    {({ handleSubmit }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <div className='py-2'>
                                <Input name='login' label={t('login')} />
                            </div>
                            <div className='py-2'>
                                <Input name='password' label={t('password')} type='password' />
                            </div>
                            <GrayLabel>
                                {`${t('doNotRememberPassword')} `}
                                <Link to={routes.resetPasswordRequest.path}>
                                    {t('resetPassword')}
                                </Link>
                            </GrayLabel>
                            <LocalError appError={error} />
                            <StyledButtonToolbar>
                                <Button variant='primary' type='submit'>
                                    {t('logIn')}
                                </Button>
                            </StyledButtonToolbar>
                        </Form>
                    )}
                </Formik>
            </StyledLogin>
        </MainPage>
    );
};

export default Login;
