import React, { useEffect, useMemo, useState } from 'react';
import { Order, QualityControl } from 'api/shared/types';
import { AppDispatch, AppState } from 'store/types';
import { useDispatch, useSelector } from 'react-redux';
import {
    ordersSelector,
    qualityControlSelector,
    selectOrdersState,
    selectQualityControllerState,
    selectQualityControlsState,
} from 'store/qualityController/selectors';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { getOrders } from 'store/qualityController/operations';
import { Col, Container, Form, Row } from 'react-bootstrap';
import {
    CenterRow,
    DarkMainColorLabel,
    OrderCommentBlock,
    OrderHeader,
    OrderHeaderCell,
    RotatedImg,
    StyledOrderDataLayout,
} from 'styles/styled-components';
import {
    Cell,
    Row as TableRow,
    useFlexLayout,
    usePagination,
    useSortBy,
    useTable,
} from 'react-table';
import { IconButton } from 'components/shared/IconButton/IconButton';
import sortArrow from 'assets/images/sort-arrow.svg';
import { Pagination } from 'components/shared/Pagination/Pagination';
import { getFlattenData, OrderTableItem, columns, RowToEdit, CellProps } from './helpers';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import {
    getFilters,
    getFontColorPerColumn,
    getFontSizePerColumn,
    getFontWeightPerColumn,
    isEmptyCell,
    isRowSpanColumn,
    isTheLastProduct,
    OrdersTableProps,
    SIZE_PER_PAGE,
} from 'utils/ordersTable';
import { FiltersState } from 'store/filters/slice';
import { useTranslation } from 'react-i18next';
import { useOrdersTableSorting } from 'hooks/useOrdersTableSorting';

const OrderTableCell = styled.div<{
    $columnId: string;
    $hideBorder?: boolean;
    $backgroundColor?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => getFontColorPerColumn(props.$columnId, columns)};
    font-weight: ${props => getFontWeightPerColumn(props.$columnId, columns)};
    font-size: ${props => getFontSizePerColumn(props.$columnId, columns)};
    margin: 0;
    padding: 0.5rem;
    border-bottom: ${prop => (prop.$hideBorder ? 'none' : `1px solid ${variables.lightGrayColor}`)};
    background: ${prop =>
        prop.$backgroundColor ? variables.lightTransparentMainColor : 'transparent'};
`;

export const OrdersTable: React.FC<OrdersTableProps> = ({
    ordersRequestInput,
    setOrdersRequestInput,
}) => {
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const orders = useSelector<AppState, Array<Order> | []>(ordersSelector);

    const { totalOrders } = useSelector(selectQualityControllerState);
    const { loading } = useSelector(selectOrdersState);
    const { loading: qualityControlsLoading } = useSelector(selectQualityControlsState);
    const pageCount = Math.ceil(totalOrders / SIZE_PER_PAGE);
    const { supplierCardCode = '' } = useParams();
    const [files, setFiles] = useState<File[]>([]);
    const [filesToDeleteIds, setFilesToDeleteIds] = useState<number[]>([]);

    const ordersTable = useMemo(() => getFlattenData(orders), [orders]);
    const [rowToEdit, setRowToEdit] = useState<RowToEdit>();

    const qualityControl = useSelector<AppState, QualityControl | null>(state =>
        qualityControlSelector(state, { qualityControlId: rowToEdit?.qualityControlId })
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        gotoPage,
        state: { pageIndex, sortBy },
    } = useTable<OrderTableItem>(
        {
            columns,
            data: ordersTable,
            initialState: { pageIndex: 0, sortBy: [{ id: 'number', desc: true }] },
            manualPagination: true,
            manualSortBy: true,
            pageCount,
            autoResetPage: false,
            autoResetSortBy: false,
        },
        useSortBy,
        usePagination,
        useFlexLayout
    );

    useOrdersTableSorting(sortBy, setOrdersRequestInput, pageIndex);

    useEffect(() => {
        if (ordersRequestInput) {
            dispatch(getOrders(ordersRequestInput));
            gotoPage(ordersRequestInput.offset / SIZE_PER_PAGE);
        }
    }, [ordersRequestInput]);

    const handlePageChange = async (pageNr: number) => {
        setOrdersRequestInput(state => ({
            ...state,
            limit: SIZE_PER_PAGE,
            offset: (pageNr - 1) * SIZE_PER_PAGE,
            supplierCardCode,
        }));
    };

    const getRows = (row: TableRow<OrderTableItem>) => {
        const isRowEditable = rowToEdit?.rowIndex === row.index;
        const initialValues = qualityControl
            ? {
                  qualityProblem: qualityControl.qualityProblem,
                  sortNeed: qualityControl.sortNeed,
                  itemsChecked: qualityControl.itemsChecked,
                  itemsRejected: qualityControl.itemsRejected,
              }
            : {
                  qualityProblem: false,
                  sortNeed: false,
                  itemsChecked: 0,
                  itemsRejected: 0,
              };

        const rowsCmp = (
            <div {...row.getRowProps()}>
                <SpinnerMask show={qualityControlsLoading && isRowEditable}>
                    {row.cells.map(cell => {
                        return (
                            <OrderTableCell
                                $columnId={cell.column.id?.toString() ?? ''}
                                $hideBorder={isTheLastProduct(row, rows) || isRowSpanColumn(cell)}
                                $backgroundColor={row.original.qualityControls.some(
                                    q => q.qualityProblem
                                )}
                                {...cell.getCellProps()}
                            >
                                {cell.render('Cell', {
                                    setRowToEdit,
                                    rowToEdit,
                                    files,
                                    setFiles,
                                    filesToDeleteIds,
                                    setFilesToDeleteIds,
                                })}
                            </OrderTableCell>
                        );
                    })}
                </SpinnerMask>
            </div>
        );

        return (
            <>
                {!isRowEditable ? (
                    rowsCmp
                ) : (
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={(values, actions) => {
                            actions.setSubmitting(false);
                        }}
                    >
                        {({ handleSubmit }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {rowsCmp}
                            </Form>
                        )}
                    </Formik>
                )}
            </>
        );
    };

    return (
        <StyledOrderDataLayout>
            <SpinnerMask show={loading}>
                {orders.length > 0 ? (
                    <>
                        <CenterRow>
                            <Container
                                {...getTableProps()}
                                fluid
                                style={{ maxHeight: '58vh', overflowY: 'auto' }}
                            >
                                <OrderHeader>
                                    {headerGroups.map(headerGroup => (
                                        <Col {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <OrderHeaderCell
                                                    {...column.getHeaderProps(
                                                        column.getSortByToggleProps()
                                                    )}
                                                >
                                                    {column.render('Header')}
                                                    <span>
                                                        {column.isSorted ? (
                                                            <RotatedImg
                                                                src={sortArrow}
                                                                $rotation={
                                                                    column.isSortedDesc ? 180 : 0
                                                                }
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </span>
                                                </OrderHeaderCell>
                                            ))}
                                        </Col>
                                    ))}
                                </OrderHeader>
                                <div {...getTableBodyProps()}>
                                    {rows.map(row => {
                                        prepareRow(row);
                                        return (
                                            <React.Fragment key={`${row.id}-dummyRow`}>
                                                {getRows(row)}
                                                {isTheLastProduct(row, rows) && (
                                                    <OrderCommentBlock
                                                        key={`${row.id}-commentBlock`}
                                                        fluid
                                                    >
                                                        <Row>
                                                            <Col md={{ span: 1, offset: 2 }}>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                ></div>
                                                            </Col>
                                                        </Row>
                                                    </OrderCommentBlock>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </Container>
                        </CenterRow>
                        <CenterRow>
                            <Pagination
                                pageCount={pageCount}
                                handlePageChange={handlePageChange}
                                currentPageIndex={pageIndex + 1}
                            />
                        </CenterRow>
                    </>
                ) : (
                    <DarkMainColorLabel>{t('noOrders')}</DarkMainColorLabel>
                )}
            </SpinnerMask>
        </StyledOrderDataLayout>
    );
};
