import { AppError, AppState } from 'store/types';
import { isDefined, isNotNull } from 'utils/helpers';

const selectUserState = (state: AppState) => state.user;
export const selectUserSuppliersState = (state: AppState) => selectUserState(state).suppliers;
export const selectUserErrors = (state: AppState): AppError[] =>
    Object.values(selectUserState(state))
        .map<AppError | null>(v => v.error)
        .filter(isNotNull)
        .filter(isDefined);

export const selectPersonalDataState = (state: AppState) => selectUserState(state).personalData;
