import React, { useState } from 'react';
import { Modal } from 'components/shared/Modal/Modal';
import styled from 'styled-components';
import { Container, ButtonToolbar } from 'react-bootstrap';
import { AddNewUserBasicForm, AdminSapSupplier, ModalState } from 'api/shared/types';
import { SuppliersModalBody } from '../SuppliersModalBody/SuppliersModalBody';
import { BasicDataModalBody } from './BasicDataModalBody/BasicDataModalBody';
import { ContractorsFilesModalBody } from './ContractorsFilesModalBody/ContractorsFilesModalBody';
import { ContractorsNamesModalBody } from './ContractorsNamesModalBody/ContractorsNamesModalBody';
import { IconButton } from 'components/shared/IconButton/IconButton';
import back from 'assets/images/back.svg';
import { useDispatch } from 'react-redux';
import { resetUserError } from 'store/admin/slice';

const StyledLayout = styled(Container)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
`;

export const StyledButtonToolbar = styled(ButtonToolbar)`
    width: 100%;
    padding: 0px 15%;
`;

export type ViewMode =
    | {
          type: 'FIRST_FORM';
      }
    | {
          type: 'SUPPLIERS';
      }
    | {
          type: 'CONTRACTORS_FILES';
      }
    | {
          type: 'CONTRACTORS_NAMES';
      };

interface AddNewUserModalProps {
    modal: ModalState;
}

export const AddNewUserModal: React.FC<AddNewUserModalProps> = ({ modal }) => {
    const [viewMode, setViewMode] = useState<ViewMode>({ type: 'FIRST_FORM' });
    const [basicPersonalData, setBasicPersonalData] = useState<AddNewUserBasicForm | undefined>();
    const [supplier, setSupplier] = useState<AdminSapSupplier | undefined>();
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        dispatch(resetUserError());
        modal.closeModal();
        setViewMode({ type: 'FIRST_FORM' });
    };

    let body = null;
    switch (viewMode.type) {
        case 'FIRST_FORM': {
            body = (
                <BasicDataModalBody
                    setBasicPersonalData={setBasicPersonalData}
                    setViewMode={setViewMode}
                />
            );
            break;
        }
        case 'SUPPLIERS': {
            body = (
                <SuppliersModalBody
                    basicPersonalData={basicPersonalData}
                    handleCloseModal={handleCloseModal}
                />
            );
            break;
        }
        case 'CONTRACTORS_FILES': {
            body = (
                <ContractorsFilesModalBody setSupplier={setSupplier} setViewMode={setViewMode} />
            );
            break;
        }
        case 'CONTRACTORS_NAMES': {
            body = (
                <ContractorsNamesModalBody
                    basicPersonalData={basicPersonalData}
                    supplier={supplier}
                    handleCloseModal={handleCloseModal}
                />
            );
            break;
        }
    }
    const handleBackClick = () => {
        dispatch(resetUserError());
        switch (viewMode.type) {
            case 'SUPPLIERS':
                setViewMode({ type: 'FIRST_FORM' });
                break;
            case 'CONTRACTORS_FILES':
                setViewMode({ type: 'FIRST_FORM' });
                break;
            case 'CONTRACTORS_NAMES':
                setViewMode({ type: 'CONTRACTORS_FILES' });
                break;
        }
    };
    const header =
        viewMode.type === 'FIRST_FORM' ? null : (
            <IconButton image={back} onClick={handleBackClick} ariaLabel='go-back' />
        );
    return (
        <Modal show={modal.show} handleClose={handleCloseModal} size='sm' headerChildren={header}>
            <StyledLayout>{body}</StyledLayout>
        </Modal>
    );
};
