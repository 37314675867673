import React, { useContext, useState } from 'react';
import { OrderTableItem, RowToEdit } from '../helpers';
import { Row as TableRow } from 'react-table';
import { OrderItem, QualityControl } from 'api/shared/types';
import dash from 'assets/images/dash.svg';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import { IconButton } from 'components/shared/IconButton/IconButton';
import addAttachment from 'assets/images/add-attachment.svg';
import attachment from 'assets/images/attachment.svg';
import { Toggle } from 'components/shared/Toggle/Toggle';
import { Input } from 'components/shared/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { orderItemSelector } from 'store/qualityController/selectors';
import { AppDispatch, AppState } from 'store/types';
import { Stack } from 'react-bootstrap';
import { ShowFilesModal } from 'components/desktop/ShowFilesModal/ShowFilesModal';
import { AddFilesModal } from 'components/desktop/AddFilesModal/AddFilesModal';
import { FormikContext, FormikContextType, useFormikContext } from 'formik';
import { Button } from 'components/shared/Button/Button';
import { addQualityControl, removeQualityControl } from 'store/qualityController/slice';
import { createQualityControl, editQualityControl } from 'store/qualityController/operations';
import { EditFilesModal } from 'features/desktop/qualityController/EditFilesModal/EditFilesModal';
import { downloadQualityControlFile, QualityControlInput } from 'api/qualityController';
import { downloadFile, filesToFormData } from 'utils/helpers';
import { getItemsCheckedPercent } from 'utils/ordersTable';
import { useTranslation } from 'react-i18next';
import { Config, MorePopover } from 'components/shared/MorePopover/MorePopover';
import roundAdd from 'assets/images/round-add.svg';
import edit from 'assets/images/edit.svg';
import { t as translation } from 'i18next';

interface StyledQualityControlItemProps {
    $borderBottomNeeded?: boolean;
}

const StyledQualityControlItem = styled.div<StyledQualityControlItemProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 10%;
    border-bottom: ${prop =>
        prop.$borderBottomNeeded ? `1px solid ${variables.lightGrayColor}` : 'none'};
`;

const StyledQualityFileItem = styled.span`
    display: flex;
    justify-content: center;
    height: fit-content;
    font-size: 1vw;
`;

interface QualityControlCellCommonProps {
    row: TableRow<OrderTableItem>;
    files: File[];
    setFiles: React.Dispatch<React.SetStateAction<File[]>>;
    rowToEdit?: RowToEdit;
    filesToDeleteIds: number[];
    setFilesToDeleteIds: React.Dispatch<React.SetStateAction<number[]>>;
}
type QualityControlCellProps =
    | (QualityControlCellCommonProps & {
          cellId: keyof QualityControl;
      })
    | (QualityControlCellCommonProps & {
          cellId: 'actions';
          setRowToEdit: React.Dispatch<React.SetStateAction<RowToEdit | undefined>>;
      });

interface QualityControlItemCommonProps {
    row: TableRow<OrderTableItem>;
    qualityControl: QualityControl;
    orderItem: OrderItem;
    rowToEdit?: RowToEdit;
    files: File[];
    setFiles: React.Dispatch<React.SetStateAction<File[]>>;
    filesToDeleteIds: number[];
    setFilesToDeleteIds: React.Dispatch<React.SetStateAction<number[]>>;
}

type QualityControlItemProps =
    | (QualityControlItemCommonProps & {
          cellId: keyof QualityControl;
      })
    | (QualityControlItemCommonProps & {
          cellId: 'actions';
          setRowToEdit: React.Dispatch<React.SetStateAction<RowToEdit | undefined>>;
      });

const getConfig = (
    handleEditQualityControl: () => void,
    handleAddQualityControl: () => void
): Config[] => [
    {
        handleOnClick: handleEditQualityControl,
        label: translation('edit'),
        dataCy: 'editButton',
        image: edit,
        iconSize: 'sm',
    },
    {
        handleOnClick: handleAddQualityControl,
        label: translation('addQualityControl'),
        dataCy: 'addQualityControlButton',
        image: roundAdd,
        iconSize: 'lg',
    },
];

const QualityControlItem: React.FC<QualityControlItemProps> = props => {
    const dispatch = useDispatch<AppDispatch>();
    const {
        qualityControl,
        cellId,
        orderItem,
        row,
        files,
        setFiles,
        rowToEdit,
        filesToDeleteIds,
        setFilesToDeleteIds,
    } = props;
    const [showFilesModal, setShowFilesModal] = useState(false);
    const [addFilesModal, setAddFilesModal] = useState(false);
    const [editFilesModal, setEditFilesModal] = useState(false);
    const { t } = useTranslation();

    const formikContext = useContext<FormikContextType<QualityControlInput>>(FormikContext);
    const isEditingQualityControl =
        rowToEdit?.qualityControlId === qualityControl.id && rowToEdit?.rowIndex === row.index;
    const isAddingQualityControl = qualityControl.id < 0 && rowToEdit?.rowIndex === row.index;
    const isQualityControlEditable = isAddingQualityControl || isEditingQualityControl;

    const handleOnShowFileClick = () => {
        setShowFilesModal(true);
    };
    const handleOnAddFileClick = () => {
        setAddFilesModal(isAddingQualityControl);
        setEditFilesModal(isEditingQualityControl);
    };
    const handleSaveFiles = (newFiles: File[]) => {
        setFiles(newFiles);
        setAddFilesModal(false);
    };

    const handleDownloadClick = async () => {
        qualityControl.qualityControlFiles.forEach(async ({ id, fileName, fileType }) => {
            const response = await downloadQualityControlFile(id, qualityControl.id);
            downloadFile(response.data, fileName, fileType);
        });
    };

    switch (cellId) {
        case 'qualityControlFiles': {
            const qualityControlFiles = qualityControl.qualityControlFiles;
            const filesLength =
                files.length +
                qualityControl.qualityControlFiles.filter(f => !filesToDeleteIds.includes(f.id))
                    .length;
            if (isQualityControlEditable)
                return (
                    <>
                        {isAddingQualityControl && (
                            <AddFilesModal
                                show={addFilesModal}
                                onSaveFiles={handleSaveFiles}
                                setShowModal={setAddFilesModal}
                                title={t('addQualityControlFiles')}
                            />
                        )}
                        {isEditingQualityControl && (
                            <EditFilesModal
                                show={editFilesModal}
                                setShowModal={setEditFilesModal}
                                files={qualityControl.qualityControlFiles}
                                setFilesToAdd={setFiles}
                                filesToDeleteIds={filesToDeleteIds}
                                setFilesToDeleteIds={setFilesToDeleteIds}
                            />
                        )}
                        <StyledQualityFileItem>
                            <IconButton
                                image={addAttachment}
                                onClick={handleOnAddFileClick}
                                size='xs'
                                dataCy='attachFileButton'
                            />
                            {filesLength > 0 && filesLength}
                        </StyledQualityFileItem>
                    </>
                );
            return (
                <StyledQualityFileItem>
                    <ShowFilesModal
                        show={showFilesModal}
                        files={qualityControlFiles}
                        title={t('showQualityControlFiles')}
                        handleDownloadClick={handleDownloadClick}
                        setShowFilesModal={setShowFilesModal}
                    />
                    <IconButton
                        image={attachment}
                        onClick={handleOnShowFileClick}
                        size='xs'
                        disabled={qualityControlFiles.length === 0}
                        dataCy='attachedFiles'
                    />
                    {qualityControlFiles.length}
                </StyledQualityFileItem>
            );
        }
        case 'qualityProblem':
            return (
                <Toggle
                    value={
                        isQualityControlEditable && formikContext
                            ? formikContext.values.qualityProblem
                            : qualityControl.qualityProblem
                    }
                    onChange={
                        isQualityControlEditable && formikContext
                            ? value => formikContext.setFieldValue('qualityProblem', value)
                            : undefined
                    }
                    name='qualityProblem'
                    disabled={!isQualityControlEditable}
                />
            );
        case 'sortNeed':
            return (
                <Toggle
                    value={
                        isQualityControlEditable && formikContext
                            ? formikContext.values.sortNeed
                            : qualityControl.sortNeed
                    }
                    onChange={
                        isQualityControlEditable && formikContext
                            ? value => formikContext.setFieldValue('sortNeed', value)
                            : undefined
                    }
                    name='sortNeed'
                    disabled={!isQualityControlEditable}
                />
            );
        case 'itemsChecked': {
            if (isQualityControlEditable)
                return <Input name='itemsChecked' height='50%' type='number' />;
            return <>{qualityControl.itemsChecked}</>;
        }
        case 'itemsCheckedPercent': {
            if (isQualityControlEditable) return <img src={dash}></img>;
            return <>{getItemsCheckedPercent(qualityControl, orderItem) + '%'}</>;
        }
        case 'itemsRejected': {
            if (isQualityControlEditable)
                return <Input name='itemsRejected' height='50%' type='number' />;
            return <>{qualityControl.itemsRejected}</>;
        }
        case 'actions': {
            const handleOnSubmit = async () => {
                await formikContext.submitForm();
                const formData = filesToFormData(files);
                if (isAddingQualityControl) {
                    formData.append(
                        'qualityControlDto',
                        JSON.stringify({ ...formikContext.values, orderItemId: orderItem.id })
                    );
                    dispatch(
                        removeQualityControl({
                            qualityControlId: qualityControl.id,
                            orderItemId: orderItem.id,
                        })
                    );
                    await dispatch(
                        createQualityControl({
                            orderId: row.original.orderId,
                            orderItemId: orderItem.id,
                            formData,
                        })
                    );
                }

                if (isEditingQualityControl) {
                    await dispatch(
                        editQualityControl({
                            qualityControlInput: formikContext.values,
                            filesToAddFormData: formData.get('files') ? formData : null,
                            filesToDeleteIds,
                            qualityControlId: qualityControl.id,
                            orderId: row.original.orderId,
                        })
                    );
                }

                props.setRowToEdit(undefined);
                setFiles([]);
                setFilesToDeleteIds([]);
            };
            if (isQualityControlEditable)
                return (
                    <Button type='button' data-cy='saveButton' onClick={handleOnSubmit}>
                        Save
                    </Button>
                );

            const handleAddQualityControl = () => {
                dispatch(addQualityControl(row.original.orderItemId));
                props.setRowToEdit({ rowIndex: row.index });
            };

            const handleEditQualityControl = () => {
                props.setRowToEdit({ rowIndex: row.index, qualityControlId: qualityControl.id });
            };

            return (
                <MorePopover
                    config={getConfig(handleEditQualityControl, handleAddQualityControl)}
                    dataCyIndex={qualityControl.id}
                />
            );
        }
    }

    return null;
};

export const QualityControlCell: React.FC<QualityControlCellProps> = props => {
    const { row, cellId, files, setFiles, rowToEdit, filesToDeleteIds, setFilesToDeleteIds } =
        props;
    const qualityControls = row.original.qualityControls;
    const dispatch = useDispatch();
    const orderItem = useSelector<AppState, OrderItem | null>(state =>
        orderItemSelector(state, { orderItemId: row.original.orderItemId })
    );

    if (cellId === 'actions' && row.original.qualityControls.length === 0) {
        const handleAddQualityControl = () => {
            dispatch(addQualityControl(row.original.orderItemId));
            props.setRowToEdit({ rowIndex: row.index });
        };

        const handleEditQualityControl = () => {
            props.setRowToEdit({ rowIndex: row.index });
        };

        return (
            <MorePopover
                config={getConfig(handleEditQualityControl, handleAddQualityControl)}
                dataCyIndex={row.index}
            />
        );
    }

    if (row.original.qualityControls.length === 0 || !orderItem) return <img src={dash}></img>;

    const getCellProps = (qualityControl: QualityControl) => {
        let cellProps: any = {
            qualityControl,
            cellId,
            orderItem,
            rowToEdit,
            row,
            files,
            setFiles,
            filesToDeleteIds,
            setFilesToDeleteIds,
        };
        if (cellProps.cellId === 'actions' && cellId == 'actions') {
            const setRowToEdit = props.setRowToEdit;
            cellProps = { ...cellProps, setRowToEdit };
        }
        return cellProps as QualityControlItemProps;
    };

    return (
        <Stack gap={3}>
            {qualityControls.map(q => (
                <StyledQualityControlItem key={q.id}>
                    <QualityControlItem {...getCellProps(q)} key={q.id} />
                </StyledQualityControlItem>
            ))}
        </Stack>
    );
};
