import { api } from './shared/instance';
import { AdminSapSupplier, EditAdminUserForm, Supplier } from './shared/types';

export interface AdminUser {
    id: number;
    avatar: string;
    userName: string;
    login: string;
    email: string;
    isActive: boolean;
    roleId: number;
    lockoutEnabled: boolean;
}

export interface AdminContactPerson {
    contactPersonId: number;
    cardCode: string;
    contactPersonName: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface Role {
    id: number;
    name: string;
}

export interface SystemParams {
    emailNotificationsSendingTime?: number;
    maximumIncorrectLoginsNumber?: number;
}

export interface UserStatistics {
    loginsTotalNumber: number;
    lastTimeLogin: string;
    userChanges: Array<UserModification>;
}

export interface UserModification {
    id: number;
    changedAt: string;
    orderNumber: string;
    supplierOrderNumber: string;
    index: string;
    orderId: number;
}

export interface UserModificationDetail {
    oldValue: string;
    newValue: string;
    field: string;
    description: string;
    createTime: string;
}

const baseUrl = 'admin';

export const getUsersRequest = async () => {
    return api.get<Array<AdminUser>>(`${baseUrl}/users`);
};

export const getRolesRequest = async () => {
    return api.get<Array<Role>>(`${baseUrl}/roles`);
};

export const getSapSuppliersRequest = async () => {
    return api.get<Array<AdminSapSupplier>>(`${baseUrl}/sap/suppliers`);
};

export const getSuppliersRequest = async () => {
    return api.get<Array<Supplier>>(`${baseUrl}/suppliers`);
};

export const getSapContactPersonsRequest = async (cardCode: string) => {
    return api.get<Array<AdminContactPerson>>(`${baseUrl}/sap/contactPersons/${cardCode}`);
};

export const editSystemParamsRequest = async (input: SystemParams) => {
    return api.patch(`${baseUrl}/systemParameters`, { ...input });
};

export const getSystemParamsRequest = async () => {
    return api.get<SystemParams>(`${baseUrl}/systemParamters`);
};
export const getUserStatisticsRequest = async (userId: number) => {
    return api.get<UserStatistics>(`${baseUrl}/userModifications`, { params: { userId } });
};

export const getUserModificationDetailsRequest = async (userId: number, orderId: number) => {
    return api.get<Array<UserModificationDetail>>(`${baseUrl}/userDetailModification`, {
        params: { userId, orderId },
    });
};

export const editUserRequest = async (input: EditAdminUserForm) => {
    return api.patch<EditAdminUserForm>(`${baseUrl}/user`, { ...input });
};

export const updateAvatarRequest = async (newAvatar: FormData, userId: number) => {
    return api.patch<string>(`${baseUrl}/userAvatar?userId=${userId}`, newAvatar, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
