import { getUserStatisticsRequest } from 'api/admin';
import { Modal } from 'components/shared/Modal/Modal';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import React, { useEffect } from 'react';
import { useAsync } from 'react-async';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CenterRow, DarkMainColorLabel, GrayLabel, GrayLine } from 'styles/styled-components';
import { ISOToFormattedStringDate } from 'utils/helpers';
import { UserChangesTable } from './UserChangesTable/UserChangesTable';

interface UserStatisticsModalProps {
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;

    userId: number;
}

export const UserStatisticsModal: React.FC<UserStatisticsModalProps> = props => {
    const { show, setShowModal, userId } = props;
    const { t } = useTranslation();

    const getUserStatistics = useAsync({
        deferFn: ([id]) => getUserStatisticsRequest(id),
    });

    useEffect(() => {
        if (show) {
            getUserStatistics.run(userId);
        }
    }, [show]);

    const userStatistics = getUserStatistics.data?.data;
    const lastTimeLogin = userStatistics?.lastTimeLogin;

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <Modal show={show} handleClose={handleCloseModal} size='lg'>
            <SpinnerMask show={getUserStatistics.isPending}>
                <Container>
                    <div className='pb-2'>
                        <CenterRow>
                            <GrayLabel>{t('userStatistics')}</GrayLabel>
                        </CenterRow>
                        <Row>
                            <Col md={6}>
                                <DarkMainColorLabel>{t('lastTimeLogin')}</DarkMainColorLabel>
                            </Col>
                            {lastTimeLogin && (
                                <Col md={6} className='text-end'>
                                    <DarkMainColorLabel>
                                        {ISOToFormattedStringDate(lastTimeLogin)}
                                    </DarkMainColorLabel>
                                </Col>
                            )}
                        </Row>
                        <GrayLine />
                        <Row>
                            <Col md={6}>
                                <DarkMainColorLabel>{t('loginsTotalNumber')}</DarkMainColorLabel>
                            </Col>
                            <Col md={6} className='text-end'>
                                <DarkMainColorLabel>
                                    {userStatistics?.loginsTotalNumber}
                                </DarkMainColorLabel>
                            </Col>
                        </Row>
                    </div>
                    <CenterRow className='pb-2'>
                        <GrayLabel>{t('listOfUserChanges')}</GrayLabel>
                    </CenterRow>
                    <Row>
                        {userStatistics && (
                            <UserChangesTable
                                userChanges={userStatistics.userChanges}
                                userId={userId}
                            />
                        )}
                    </Row>
                </Container>
            </SpinnerMask>
        </Modal>
    );
};
