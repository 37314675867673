import axios, { AxiosError } from 'axios';
import { AppErrorType, AuthErrorType, ErrorType, noneErrorType } from 'store/types';
import toString from 'lodash/toString';
import errorsMsgs from 'assets/resources/errors.json';
import { isDefined } from 'utils/helpers';

export const prepareErrorType = (
    error: unknown | AxiosError | Error
): AppErrorType | AppErrorType[] => {
    if (axios.isAxiosError(error)) {
        if (
            error.response?.status === 401 &&
            error.response?.data === 'User Blocked : Anauthorized'
        )
            return AuthErrorType.BLOCKED_ACCOUNT;
        if (error.response?.status === 401) return AuthErrorType.WRONG_PASSWORD;
        const responseMsg = toString(error.response?.data);
        if (responseMsg.includes(',')) {
            const responseMsgs = responseMsg.split(',');
            const errorTypes = responseMsgs
                .map(r => {
                    const errorType = Object.keys(errorsMsgs).find(e => r.includes(e)) as
                        | ErrorType
                        | undefined;
                    return errorType;
                })
                .filter(isDefined);
            return errorTypes.length > 0 ? errorTypes : noneErrorType;
        }
        const errorType = Object.keys(errorsMsgs).find(e => responseMsg.includes(e)) as
            | ErrorType
            | undefined;
        if (errorType) return errorType;
    }

    return noneErrorType;
};
