import { LanguageEnum } from 'api/shared/types';
import { IconButton } from 'components/shared/IconButton/IconButton';
import React from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { DarkMainColorLabel } from 'styles/styled-components';
import flagEngland from 'assets/images/flag-england.svg';
import flagChina from 'assets/images/flag-china.svg';
import flagPoland from 'assets/images/flag-poland.svg';
import tick from 'assets/images/tick.svg';
import { useTranslation } from 'react-i18next';

interface ChooseLanguageProps {
    handleChooseLanguage: (language: LanguageEnum) => void;
    chosenLanguage?: LanguageEnum;
}

const LanguageSpan = styled.span`
    width: 24px;
    height: 18px;
`;

export const ChooseLanguage: React.FC<ChooseLanguageProps> = props => {
    const { handleChooseLanguage, chosenLanguage } = props;
    const { t } = useTranslation();

    return (
        <>
            <Col md={4}>
                <div className='d-flex flex-column align-items-center'>
                    <LanguageSpan className='pb-4'>
                        {chosenLanguage === LanguageEnum.Polish && <img src={tick} alt='Tick' />}
                    </LanguageSpan>
                    <IconButton
                        ariaLabel='Polish'
                        size='lg'
                        image={flagPoland}
                        onClick={() => handleChooseLanguage(LanguageEnum.Polish)}
                    >
                        <DarkMainColorLabel>{t('Polish')}</DarkMainColorLabel>
                    </IconButton>
                </div>
            </Col>

            <Col md={4}>
                <div className='d-flex flex-column align-items-center'>
                    <LanguageSpan className='pb-4'>
                        {chosenLanguage === LanguageEnum.English && <img src={tick} alt='Tick' />}
                    </LanguageSpan>
                    <IconButton
                        ariaLabel='English'
                        size='lg'
                        image={flagEngland}
                        onClick={() => handleChooseLanguage(LanguageEnum.English)}
                    >
                        <DarkMainColorLabel>{t('English')}</DarkMainColorLabel>
                    </IconButton>
                </div>
            </Col>
            <Col md={4}>
                <div className='d-flex flex-column align-items-center'>
                    <LanguageSpan className='pb-4'>
                        {chosenLanguage === LanguageEnum.Chinese && <img src={tick} alt='Tick' />}
                    </LanguageSpan>
                    <IconButton
                        ariaLabel='Chinese'
                        size='lg'
                        image={flagChina}
                        onClick={() => handleChooseLanguage(LanguageEnum.Chinese)}
                    >
                        <DarkMainColorLabel>{t('Chinese')}</DarkMainColorLabel>
                    </IconButton>
                </div>
            </Col>
        </>
    );
};
