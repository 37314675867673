import React from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';

export const StyledCheck = styled(Form.Switch)`
    .form-check-input:checked {
        background-color: ${variables.darkMainColor};
    }

    .form-check-input {
        width: 2em;
        height: 1em;
    }
`;

interface ToggleProps {
    value: boolean;
    name: string;
    onChange?: (value: boolean) => void;
    disabled?: boolean;
    label?: string;
}

export const Toggle: React.FC<ToggleProps> = props => {
    const { value, disabled, label, name, onChange } = props;
    return (
        <StyledCheck
            id={name}
            label={label}
            disabled={disabled}
            name={name}
            checked={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange?.(Boolean(event.target.checked))
            }
        />
    );
};
