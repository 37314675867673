import React, { useState } from 'react';
import { FileType, Order, OrderComment, QualityControl } from 'api/shared/types';
import { flatMap, map } from 'lodash';
import { Column } from 'react-table';
import { Row as TableRow } from 'react-table';
import { QualityControlCell } from './QualityControlCell/QualityControlCell';
import file from 'assets/images/file.svg';
import { Stack } from 'react-bootstrap';
import { IconButton } from 'components/shared/IconButton/IconButton';
import i18n, { t } from 'i18next';
import { ShowFilesModal } from 'components/desktop/ShowFilesModal/ShowFilesModal';
import { downloadQualityControlGuideline } from 'api/employee';
import { downloadFile } from 'utils/helpers';

export interface OrderTableItem {
    orderId: number;
    orderItemId: number;
    number: string;
    supplierOrderNumber: string;
    index: string;
    name: string;
    quantity: number;
    qualityControls: QualityControl[];
    orderItemFiles: Array<FileType>;
}

export interface CellProps {
    row: TableRow<OrderTableItem>;
    files: File[];
    setFiles: React.Dispatch<React.SetStateAction<File[]>>;
    rowToEdit: RowToEdit | undefined;
    filesToDeleteIds: number[];
    setFilesToDeleteIds: React.Dispatch<React.SetStateAction<number[]>>;
}

export type RowToEdit = { rowIndex: number; qualityControlId?: number } | undefined;

export const columns = [
    {
        Header: () => i18n.t('orderNumber'),
        id: 'number',
        accessor: (row: OrderTableItem, rowIndex: number) => row.number,
        width: 1,
    },
    {
        Header: () => i18n.t('supplierOrderNumber'),
        id: 'supplierOrderNumber',
        accessor: (row: OrderTableItem, rowIndex: number) => row.supplierOrderNumber,
        width: 2,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('index'),
        id: 'index',
        accessor: 'index',
        width: 1,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('name'),
        id: 'name',
        accessor: 'name',
        width: 3,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('quantityOrdered'),
        id: 'quantity',
        accessor: 'quantity',
        width: 1,
        disableSortBy: true,
    },
    {
        Header: () => i18n.t('qualityControlGuidelines'),
        id: 'orderItemFiles',
        Cell: (props: CellProps) => {
            const orderItem = props.row.original;
            const filesLength = orderItem.orderItemFiles?.length ?? 0;
            const [showFilesModal, setShowFilesModal] = useState(false);
            const handleShowFiles = () => {
                setShowFilesModal(true);
            };

            const handleDownloadClick = async () => {
                orderItem.orderItemFiles.forEach(async ({ id, fileName, fileType }) => {
                    const response = await downloadQualityControlGuideline(
                        id,
                        orderItem.orderItemId
                    );
                    downloadFile(response.data, fileName, fileType);
                });
            };
            return (
                <>
                    <Stack direction='horizontal' gap={1}>
                        <IconButton
                            image={file}
                            onClick={handleShowFiles}
                            size='xs'
                            dataCy='attachGuidelinesButton'
                        />
                        {filesLength > 0 && filesLength}
                    </Stack>
                    <ShowFilesModal
                        files={orderItem.orderItemFiles}
                        show={showFilesModal}
                        setShowFilesModal={setShowFilesModal}
                        title={t('showQualityControlGuidelines')}
                        handleDownloadClick={handleDownloadClick}
                    />
                </>
            );
        },
        disableSortBy: true,
        width: 1,
    },
    {
        Header: () => i18n.t('qualityIssueOccurred'),
        id: 'qualityProblem',
        Cell: (props: CellProps) => <QualityControlCell {...props} cellId='qualityProblem' />,
        disableSortBy: true,
        width: 1,
    },
    {
        Header: () => i18n.t('sortingNeeded'),
        id: 'sortNeed',
        Cell: (props: CellProps) => <QualityControlCell {...props} cellId='sortNeed' />,
        disableSortBy: true,
        width: 0.5,
    },
    {
        Header: () => i18n.t('numberOfPiecesChecked'),
        id: 'itemsChecked',
        Cell: (props: CellProps) => <QualityControlCell {...props} cellId='itemsChecked' />,
        disableSortBy: true,
        width: 1,
    },
    {
        Header: () => i18n.t('percentageOfCheckedProducts'),
        id: 'itemsCheckedPercent',
        Cell: (props: CellProps) => <QualityControlCell {...props} cellId='itemsCheckedPercent' />,
        disableSortBy: true,
        width: 1,
    },
    {
        Header: () => i18n.t('numberOfRejectedProducts'),
        id: 'itemsRejected',
        Cell: (props: CellProps) => <QualityControlCell {...props} cellId='itemsRejected' />,
        disableSortBy: true,
        width: 1,
    },
    {
        Header: () => i18n.t('attachmentsToResults'),
        id: 'qualityControlFiles',
        Cell: (props: CellProps) => <QualityControlCell {...props} cellId='qualityControlFiles' />,
        disableSortBy: true,
        width: 1,
    },
    {
        id: 'actions',
        Cell: ({
            setRowToEdit,
            ...restProps
        }: {
            setRowToEdit: React.Dispatch<React.SetStateAction<RowToEdit | undefined>>;
        } & CellProps) => (
            <QualityControlCell {...restProps} cellId='actions' setRowToEdit={setRowToEdit} />
        ),
        disableSortBy: true,
        width: 0.5,
    },
] as Column<OrderTableItem>[];

export const getFlattenData = (orders: Array<Order>): Array<OrderTableItem> => {
    return flatMap(orders, ({ number, supplierOrderNumber, id, orderItems }) =>
        map(orderItems, orderItem => ({
            number,
            supplierOrderNumber,
            ...orderItem,
            orderId: id,
            orderItemId: orderItem.id,
        }))
    );
};
