import React, { useMemo } from 'react';
import { Button } from 'components/shared/Button/Button';
import { Input } from 'components/shared/Input/Input';
import { Formik } from 'formik';
import { ButtonToolbar, Container, Form } from 'react-bootstrap';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppError, AppState } from 'store/types';
import { MainPage } from '../MainPage/MainPage';
import { changePassword } from 'store/auth/operations';
import { ChangePasswordData } from 'api/user';
import { LocalError } from 'components/shared/LocalError/LocalError';
import { useTranslation } from 'react-i18next';

const StyledFirstLoginPassword = styled(Container)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    position: absolute;
    width: 28%;
    height: 60%;
    left: 22%;
    top: 14%;
    background: #fefefe;
    border-radius: 34px;
    border: 2px solid ${variables.darkMainColor};
    padding: 0 3vw;
`;

const StyledForm = styled(Form)`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 70%;
`;

const StyledButtonToolbar = styled(ButtonToolbar)`
    padding: 2vh 3vw 0px;
`;

export const FirstLoginPassword: React.FC = () => {
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const error = useSelector<AppState, AppError | null>(state => state.auth.error);

    const handleOnSubmit = async (values: ChangePasswordData) => {
        await dispatch(changePassword(values));
    };

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                passwordOld: yup.string().required(t('theFieldIsRequired')),
                password: yup
                    .string()
                    .required(t('pleaseEnterNewPassword'))
                    .min(8, t('PasswordTooShort')),
                passwordConfirm: yup
                    .string()
                    .required(t('pleaseRetypeYourPassword'))
                    .min(8, t('PasswordTooShort'))
                    .oneOf([yup.ref('password')], t('yourPasswordsDoNotMatch')),
            }),
        []
    );

    return (
        <MainPage>
            <StyledFirstLoginPassword>
                <Formik
                    initialValues={{
                        password: '',
                        passwordOld: '',
                        passwordConfirm: '',
                    }}
                    onSubmit={handleOnSubmit}
                    validationSchema={validationSchema}
                >
                    {({ handleSubmit }) => (
                        <StyledForm noValidate onSubmit={handleSubmit}>
                            <div className='py-2'>
                                <Input
                                    name='passwordOld'
                                    label={t('oldPassword')}
                                    type='password'
                                />
                            </div>
                            <div className='py-2'>
                                <Input name='password' label={t('newPassword')} type='password' />
                            </div>
                            <div className='py-2'>
                                <Input
                                    name='passwordConfirm'
                                    label={t('repeatNewPassword')}
                                    type='password'
                                />
                            </div>
                            <StyledButtonToolbar>
                                <Button variant='primary' type='submit'>
                                    {t('logIn')}
                                </Button>
                            </StyledButtonToolbar>
                        </StyledForm>
                    )}
                </Formik>
                <LocalError appError={error} />
            </StyledFirstLoginPassword>
        </MainPage>
    );
};
