import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Supplier } from 'api/shared/types';
import {
    NormalizedOrderItems,
    NormalizedOrderRealizations,
    NormalizedOrders,
    NormalizedQualityControls,
    NormalizedStateData,
} from 'store/shared/types';
import { AppError, BasicArrayStateType, BasicStateType } from 'store/types';
import { getOrders, getSuppliers } from './operations';

export type EmployeeState = {
    orders: BasicStateType<NormalizedOrders>;
    orderItems: BasicStateType<NormalizedOrderItems>;
    qualityControls: BasicStateType<NormalizedQualityControls>;
    suppliers: BasicArrayStateType<Supplier>;
    realizations: BasicStateType<NormalizedOrderRealizations>;
    totalOrders: number;
};

const initialState: EmployeeState = {
    totalOrders: 0,
    orders: {
        entities: null,
        loading: false,
        error: null,
    },
    orderItems: {
        entities: null,
        loading: false,
        error: null,
    },
    qualityControls: {
        entities: null,
        loading: false,
        error: null,
    },
    suppliers: {
        entities: [],
        loading: false,
        error: null,
    },
    realizations: {
        entities: null,
        loading: false,
        error: null,
    },
};

export const employerStateSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {},
    extraReducers: {
        [getOrders.pending.type]: (state: EmployeeState) => {
            state.orders.loading = true;
            state.orders.error = null;
        },
        [getOrders.fulfilled.type]: (
            state: EmployeeState,
            action: PayloadAction<{
                totalOrders: number;
                data: NormalizedStateData;
            }>
        ) => {
            const { data, totalOrders } = action.payload;
            state.totalOrders = totalOrders;
            state.orders.entities = data.orders ?? null;
            state.orderItems.entities = data.orderItems ?? null;
            state.qualityControls.entities = data.qualityControls ?? null;
            state.realizations.entities = data.realizations ?? null;
            state.orders.loading = false;
            state.orders.error = null;
        },
        [getOrders.rejected.type]: (state: EmployeeState, action: PayloadAction<AppError>) => {
            state.orders.entities = {};
            state.orderItems.entities = {};
            state.qualityControls.entities = {};
            state.realizations.entities = {};
            state.orders.loading = false;
            state.orders.error = action.payload;
        },

        [getSuppliers.pending.type]: (state: EmployeeState) => {
            state.suppliers.loading = true;
            state.suppliers.error = null;
        },
        [getSuppliers.fulfilled.type]: (
            state: EmployeeState,
            action: PayloadAction<Supplier[]>
        ) => {
            state.suppliers.entities = action.payload;
            state.suppliers.loading = false;
            state.suppliers.error = null;
        },
        [getSuppliers.rejected.type]: (state: EmployeeState, action: PayloadAction<AppError>) => {
            state.suppliers.entities = [];
            state.suppliers.loading = false;
            state.suppliers.error = action.payload;
        },
    },
});

export default employerStateSlice.reducer;
