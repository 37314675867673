import { Col, Container, Form, Row, Stack, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';

export const GrayLabel = styled.span`
    color: ${variables.lightBlackColor};
`;

export const DarkMainColorLabel = styled.span`
    color: ${variables.darkMainColor};
`;

export const LightMainColorLabel = styled.span`
    color: ${variables.lightMainColor};
    font-weight: 700;
`;

export const LightTransparentMainColorLabel = styled.span`
    color: rgba(0, 159, 227, 0.5);
    font-weight: 700;
`;

export const CenterRow = styled(Row)<{ $height?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: ${props => props.$height ?? '100%'};
`;

export const EndRow = styled(Row)`
    display: flex;
    align-items: flex-end;
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
    padding: 0;
`;

export const StyledToggleButton = styled(ToggleButton)`
    display: flex;
    background-color: #fff;
    color: ${variables.darkMainColor};
    width: 100%;
    height: ${variables.defaultElementHeight};
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 15px !important;
    margin: 5px 0;
    &:hover {
        color: ${variables.darkMainColor};
        background-color: ${variables.lightTransparentMainColor};
    }
    &:focus {
        color: ${variables.darkMainColor};
        background-color: ${variables.lightTransparentMainColor};
    }
`;

export const RotatedImg = styled.img<{ $rotation: number }>`
    transform: rotate(${props => props.$rotation}deg);
`;

export const StyledLine = styled.hr`
    height: 2px !important;
    margin: 0;
    background-image: ${variables.mainGradientColor};
`;

export const StyledErrorLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: ${variables.darkMainColor};
    color: #fff;
    font-weight: 700;
`;

export const StyledErrorText = styled.p`
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 67px;
    color: ${variables.lightMainColor};
`;

export const StyledBtnErrorDiv = styled.div`
    display: flex;
    align-items: center;
    width: 25%;
    margin-top: 50px;
`;

export const StyledOrderDataLayout = styled(Container)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const OrderHeader = styled.div`
    display: flex;
    background: #f6f6f6;
    font-weight: 700;
    font-size: 0.85vw;
    line-height: 21px;
    color: ${variables.lightBlackColor};
    text-align: center;
    height: 77px;
    border-radius: 22px 22px 0px 0px;
    position: sticky;
    top: 0;
    z-index: 1;
`;

export const OrderHeaderCell = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0.5rem;
`;

export const OrderCommentBlock = styled(Container)`
    display: flex;
    flex-direction: column;
    background: #f6f6f6;
    height: 40px;
    box-shadow: 0 1px 0 ${variables.darkMainColor};
    color: ${variables.darkMainColor};
    padding: 5px 0;
`;

export const StyledStack = styled(Stack)`
    max-width: fit-content;
    justify-content: flex-start;
`;

export const WidgetContainer = styled(Container)`
    border: 1.33px solid ${variables.lightMainColor};
    border-radius: 13px;
    width: 440px;
    background-color: white;
`;

export const BlueLine = styled.hr`
    height: 2px !important;
    margin: 0;
    background-color: ${variables.lightMainColor};
    opacity: 1;
`;

export const AttachmentSpan = styled.span`
    color: ${variables.darkMainColor};
    font-weight: 500;
    font-size: 0.85rem;
    text-align: left;
`;

export const GrayLine = styled.hr`
    height: 1px !important;
    margin: 0;
    background-color: ${variables.lightGrayColor};
    opacity: 1;
`;

export const ScrollContainer = styled(Container)`
    max-height: 70vh;
    overflow-y: auto;
`;

export const GrayTextArea = styled(Form.Control)`
    resize: none;
    background: #f6f6f6;
    border-radius: 10px;
    border: 2px solid ${variables.darkMainColor};

    &:focus {
        outline: none !important;
        border: 2px solid ${variables.darkMainColor};
    }
`;

export const BlueDotCommentOverlay = styled.div`
    position: absolute;
    left: 68%;
    right: 0%;
    top: 0%;

    height: 12px;
    width: 12px;

    background-color: ${variables.lightMainColor};
    border-radius: 50%;
`;
