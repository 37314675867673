import { searchTextToBase64 } from 'utils/helpers';
import { api } from './shared/instance';
import {
    OnTimeDelivery,
    OpenOrder,
    OrderAutocompleteType,
    OrderChange,
    OrdersRequestInput,
    OrdersResponse,
    QualityControlResults,
    Supplier,
    WidgetsDates,
} from './shared/types';

const baseUrl = 'Employee';

export const getOrdersRequest = async (input: OrdersRequestInput) => {
    if ('cardCode' in input) {
        const { cardCode, searchText, ...rest } = input;
        const searchTextBase64 = searchTextToBase64(searchText);

        return api.get<OrdersResponse>(`${baseUrl}/Supplier/${cardCode}/Order`, {
            params: {
                ...rest,
                ...searchTextBase64,
            },
        });
    }

    throw new Error('No cardCode in input params');
};

export const getQualityControlResultsRequest = async (dates: WidgetsDates) => {
    return api.get<QualityControlResults>(`${baseUrl}/Order/QualityControlResults`, {
        params: {
            ...dates,
        },
    });
};

export const getOnTimeDeliveryRequest = async (dates: WidgetsDates) => {
    return api.get<OnTimeDelivery>(`${baseUrl}/Order/OnTimeDelivery`, {
        params: {
            ...dates,
        },
    });
};

export const getOpenOrderRequest = async (dates: WidgetsDates) => {
    return api.get<OpenOrder>(`${baseUrl}/Order/Open`, {
        params: {
            ...dates,
        },
    });
};

export const getAutocompleteResultsRequest = async (
    searchQuery: string,
    autocompleteType: OrderAutocompleteType,
    cardCode: string
) => {
    return api.get<string[]>(`${baseUrl}/${cardCode}/Order/Autocomplete`, {
        params: {
            searchQuery,
            autocompleteType,
        },
    });
};

export const getSuppliersRequest = async () => {
    return api.get<Array<Supplier>>(`${baseUrl}/Supplier`);
};

export const getOrderChangesRequest = async (orderId: number) => {
    return api.get<OrderChange[]>(`${baseUrl}/realizationModifications`, {
        params: {
            orderId,
        },
    });
};

export const deleteQualityControlGuidelines = async (fileId: number, orderItemId: number) => {
    return api.delete(`${baseUrl}/${orderItemId}/File/${fileId}`);
};

export const addQualityControlGuidelines = async (formDataFiles: FormData, orderItemId: number) => {
    return api.post(`${baseUrl}/${orderItemId}/File`, formDataFiles, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const downloadQualityControlGuideline = async (fileId: number, orderItemId: number) => {
    return api.get<Blob>(`${baseUrl}/${orderItemId}/File/${fileId}`, { responseType: 'blob' });
};

export const downloadQualityControlFile = async (fileId: number, qualityControlId: number) => {
    return api.get<Blob>(`${baseUrl}/QualityControl/${qualityControlId}/File/${fileId}`, {
        responseType: 'blob',
    });
};

export const exportToExcelRequest = async (input: OrdersRequestInput) => {
    if (!('cardCode' in input)) {
        throw new Error('No cardCode in params');
    }
    return api.get<Blob>(`${baseUrl}/${input.cardCode}/Order/Export`, {
        responseType: 'blob',
        params: {
            ...input,
        },
    });
};
