import React, { forwardRef } from 'react';
import BootstrapPopover from 'react-bootstrap/Popover';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import { OverlayInjectedProps } from 'react-bootstrap/OverlayTrigger';

export const StyledBootstrapPopover = styled(BootstrapPopover)`
    background-color: white;
    border: 1.15px solid ${variables.darkMainColor};
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);

    .popover-body {
        color: ${variables.darkMainColor};
        padding: 5px;
    }

    .popover-arrow::before {
        border-bottom-color: ${variables.darkMainColor};
    }
`;

export const StyledBootstrapPopoverHeader = styled(BootstrapPopover.Header)`
    background-color: white;
    color: ${variables.lightMainColor};
    border-bottom: 1px solid ${variables.darkMainColor};
`;

type PopoverProps = OverlayInjectedProps & {
    headerChildren?: React.ReactNode;
    children: React.ReactNode;
};

export const Popover = forwardRef<HTMLDivElement, PopoverProps>((props, ref) => {
    const { headerChildren, children, ...rest } = props;
    return (
        <StyledBootstrapPopover {...rest} ref={ref}>
            {headerChildren && (
                <StyledBootstrapPopoverHeader>{headerChildren}</StyledBootstrapPopoverHeader>
            )}
            <BootstrapPopover.Body>{children}</BootstrapPopover.Body>
        </StyledBootstrapPopover>
    );
});

Popover.displayName = 'Popover';
