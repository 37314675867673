import { OrderAutocompleteType } from 'api/shared/types';
import { Autocomplete } from 'components/shared/Autocomplete/Autocomplete';
import { Modal } from 'components/shared/Modal/Modal';
import { RoundedRadio } from 'components/shared/RoundedRadio/RoundedRadio';
import { SearchButton } from 'components/shared/SearchButton/SearchButton';
import { Toggle } from 'components/shared/Toggle/Toggle';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { FiltersState, setFilters } from 'store/filters/slice';
import { GrayLabel } from 'styles/styled-components';
import { Roles } from 'api/auth';
import { IfRole } from 'components/shared/IfRole/IfRole';
import { useTranslation } from 'react-i18next';
import { last } from 'lodash';

interface OrdersSearchModalProps {
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;

    orderAutocompleteType: OrderAutocompleteType;
    setOrderAutocompleteType: React.Dispatch<React.SetStateAction<OrderAutocompleteType>>;

    autocomplete: {
        onSearch: (value: string) => Promise<void>;
        options: Array<string>;
        loading: boolean;
        selected?: string[];
        onChange: (selected: string[]) => void;
    };
}

export const OrdersFiltersModal: React.FC<OrdersSearchModalProps> = props => {
    const { t } = useTranslation();
    const { show, setShowModal, autocomplete, orderAutocompleteType, setOrderAutocompleteType } =
        props;
    const [areOrdersCompleted, setAreOrdersCompleted] = useState(false);
    const phrases = autocomplete.selected;
    const newPhrase = phrases;
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        setAreOrdersCompleted(false);
        setOrderAutocompleteType(OrderAutocompleteType.Orders);
        autocomplete.onChange([]);
        setShowModal(false);
    };

    const handleChangeAutocompleteType = (value: number) => {
        const enumValue: OrderAutocompleteType = value;
        setOrderAutocompleteType(enumValue);
    };

    const handleToggleChange = (value: boolean) => {
        setAreOrdersCompleted(value);
    };

    const handleSetFilters = () => {
        const filters: FiltersState = {
            phrase: newPhrase ? { value: newPhrase, type: orderAutocompleteType } : undefined,
        };
        if (areOrdersCompleted) {
            filters.areOrdersCompleted = {
                type: true,
                value: t('ordersToBeCompleted'),
            };
        }
        dispatch(setFilters(filters));
        handleCloseModal();
    };

    useEffect(() => {
        if (newPhrase) {
            const lastSelected = last(newPhrase) ?? '';
            autocomplete.onSearch(lastSelected);
        }
    }, [orderAutocompleteType]);

    return (
        <Modal show={show} handleClose={handleCloseModal} size='lg'>
            <Container fluid className='pb-5'>
                <Row className='px-5'>
                    <GrayLabel className='no-padding'>{t('selectScopeSearch')}:</GrayLabel>
                </Row>
                <Row className='py-3 px-5'>
                    <Col md={4} className='no-padding'>
                        <RoundedRadio
                            name='autocompleteType'
                            label={t('orderNr')}
                            dataCy='orderRadio'
                            value={OrderAutocompleteType.Orders}
                            onChange={handleChangeAutocompleteType}
                            checked={orderAutocompleteType === OrderAutocompleteType.Orders}
                        />
                    </Col>
                    <Col md={4} className='no-padding'>
                        <RoundedRadio
                            name='autocompleteType'
                            label={t('productIndex')}
                            dataCy='productIndexRadio'
                            value={OrderAutocompleteType.ProductIndex}
                            onChange={handleChangeAutocompleteType}
                            checked={orderAutocompleteType === OrderAutocompleteType.ProductIndex}
                        />
                    </Col>
                    <Col md={4} className='no-padding'>
                        <RoundedRadio
                            name='autocompleteType'
                            label={t('productName')}
                            dataCy='productNameRadio'
                            value={OrderAutocompleteType.ProductName}
                            onChange={handleChangeAutocompleteType}
                            checked={orderAutocompleteType === OrderAutocompleteType.ProductName}
                        />
                    </Col>
                </Row>
                <IfRole roles={[Roles.GtvEmployee]} isNot>
                    <Row className='py-3 px-5'>
                        <Toggle
                            label={t('ordersToBeCompleted')}
                            name='areOrdersCompleted'
                            value={areOrdersCompleted}
                            onChange={handleToggleChange}
                        />
                    </Row>
                </IfRole>
                <Row className='py-3'>
                    <Col md={11} className='no-padding'>
                        <Autocomplete {...autocomplete} multiple />
                    </Col>
                    <Col md={1} className='no-padding'>
                        <SearchButton
                            onSubmit={handleSetFilters}
                            disabled={!newPhrase || newPhrase.length === 0}
                        />
                    </Col>
                </Row>
            </Container>
        </Modal>
    );
};
