import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import xls from 'assets/images/xls.svg';
import { IconButton } from 'components/shared/IconButton/IconButton';
import { DropdownMenu } from 'components/shared/DropdownMenu/DropdownMenu';
import { OrdersTable } from './OrdersTable/OrdersTable';
import { CenterRow } from 'styles/styled-components';
import { Header } from 'components/desktop/Header/Header';
import { OrdersFilters } from 'components/desktop/OrdersFilters/OrdersFilters';
import { useAutocomplete } from 'hooks/useAutocomplete';
import {
    getAutocompleteResultsRequest,
    getOnTimeDeliveryRequest,
    getQualityControlResultsRequest,
    getOpenOrderRequest,
    exportToExcelRequest,
} from 'api/supplier';
import { OrderAutocompleteType } from 'api/shared/types';
import { OrdersFiltersList } from 'components/desktop/OrdersFiltersList/OrdersFiltersList';
import { QualityControlResultsMenu } from 'components/desktop/Widgets/QualityControlResultsMenu/QualityControlResultsMenu';
import { OpenOrderMenu } from 'components/desktop/Widgets/OpenOrderMenu/OpenOrderMenu';
import { OnTimeDeliveryMenu } from 'components/desktop/Widgets/OnTimeDeliveryMenu/OnTimeDeliveryMenu';
import { useWidgetsDates } from 'hooks/useWidgetsDates';
import { downloadFile } from 'utils/helpers';
import { useOrdersRequestInput } from 'hooks/useOrdersRequestInput';
import { useTranslation } from 'react-i18next';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { addError } from 'store/errors/slice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/types';
import { selectSupplierState } from 'store/supplier/selectors';

const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
`;

const StyledMain = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 3% 2%;
`;

export const SupplierHome: React.FC = () => {
    const [orderAutocompleteType, setOrderAutocompleteType] = useState<OrderAutocompleteType>(
        OrderAutocompleteType.Orders
    );
    const { t } = useTranslation();

    const {
        onTimeDeliveryDates,
        setOnTimeDeliveryDates,
        openOrderDates,
        setOpenOrderDates,
        qualityControlResultsDates,
        setQualityControlResultsDates,
    } = useWidgetsDates();

    const { ordersRequestInput, setOrdersRequestInput } = useOrdersRequestInput(openOrderDates);
    const [loadingXLS, setLoading] = useState(false);
    const { totalOrders } = useSelector(selectSupplierState);

    const getAutocompleteResults = useCallback(
        async (searchQuery: string) => {
            const { data } = await getAutocompleteResultsRequest(
                searchQuery,
                orderAutocompleteType
            );
            return data;
        },
        [orderAutocompleteType]
    );

    const autocompleteProps = useAutocomplete(getAutocompleteResults);

    const handleXlsExport = async () => {
        try {
            if (ordersRequestInput) {
                setLoading(true);
                const { data } = await exportToExcelRequest({
                    ...ordersRequestInput,
                    limit: totalOrders,
                });
                downloadFile(data, 'orders-export.xlsx', data.type);
                setLoading(false);
            }
        } catch (error: unknown) {
            setLoading(false);
        }
    };

    return (
        <StyledLayout>
            <SpinnerMask show={loadingXLS}>
                <Header />
                <StyledMain>
                    <Container fluid>
                        <Row>
                            <Col md={3}>
                                <DropdownMenu label={t('openOrders')}>
                                    <OpenOrderMenu
                                        dates={openOrderDates}
                                        setOnTimeDeliveryDates={setOpenOrderDates}
                                        getOpenOrderRequest={getOpenOrderRequest}
                                    />
                                </DropdownMenu>
                            </Col>
                            <Col md={3}>
                                <DropdownMenu label={t('onTimeDelivery')}>
                                    <OnTimeDeliveryMenu
                                        dates={onTimeDeliveryDates}
                                        setOnTimeDeliveryDates={setOnTimeDeliveryDates}
                                        getOnTimeDeliveryRequest={getOnTimeDeliveryRequest}
                                    />
                                </DropdownMenu>
                            </Col>
                            <Col md={3}>
                                <DropdownMenu label={t('qualityControlResults')}>
                                    <QualityControlResultsMenu
                                        dates={qualityControlResultsDates}
                                        setOnTimeDeliveryDates={setQualityControlResultsDates}
                                        getQualityControlResultsRequest={
                                            getQualityControlResultsRequest
                                        }
                                    />
                                </DropdownMenu>
                            </Col>
                        </Row>
                        <Row className='py-4'>
                            <Col md={9}>
                                <OrdersFiltersList />
                            </Col>
                            <Col md={{ offset: 1, span: 1 }}>
                                <OrdersFilters
                                    autocomplete={autocompleteProps}
                                    orderAutocompleteType={orderAutocompleteType}
                                    setOrderAutocompleteType={setOrderAutocompleteType}
                                />
                            </Col>
                            <Col md={1}>
                                <IconButton
                                    ariaLabel='export-excel'
                                    image={xls}
                                    onClick={handleXlsExport}
                                    size='xl'
                                />
                            </Col>
                        </Row>
                        <CenterRow $height='80%'>
                            <OrdersTable
                                ordersRequestInput={ordersRequestInput}
                                setOrdersRequestInput={setOrdersRequestInput}
                            />
                        </CenterRow>
                    </Container>
                </StyledMain>
            </SpinnerMask>
        </StyledLayout>
    );
};
