import { OrderRealization } from 'api/shared/types';
import React, { useState } from 'react';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';
import { SpinnerMask } from 'components/shared/SpinnerMask/SpinnerMask';
import { AddedRealization } from './AddedRealization/AddedRealization';
import { v4 as uuidv4 } from 'uuid';
import { StyledLine } from 'styles/styled-components';
import { RealizationToAddList } from './RealizationToAddList/RealizationToAddList';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrderRealizationsState } from 'store/supplier/selectors';
import { CreateOrderRealization } from 'store/supplier/operations';
import { last } from 'lodash';
import { AppDispatch } from 'store/types';
import { addRealization } from 'store/supplier/slice';

interface RealizationFormProps {
    realizations: Array<OrderRealization>;
    orderItemId: number;
    orderId: number;
}

export const RealizationLabel = styled.div`
    display: flex;
    font-size: 1vw;
    color: ${variables.darkMainColor};
    font-weight: 500;
`;

const StyledLayout = styled(Container)`
    background-color: #ebeaea;
    width: 79%;
    margin-left: 21%;
    border-radius: 0 0 20px 20px;
`;

export const RealizationList: React.FC<RealizationFormProps> = props => {
    const { realizations, orderItemId, orderId } = props;
    const { loading } = useSelector(selectOrderRealizationsState);
    const dispatch = useDispatch<AppDispatch>();

    const realizationsToAdd = realizations.filter(r => r.id < 0);
    const addedRealizations = realizations.filter(r => r.id > 0);
    const lastRealizationIndex = addedRealizations.length;

    const handleAddRealization = () => {
        dispatch(addRealization(orderItemId));
    };

    return (
        <SpinnerMask show={loading}>
            <StyledLayout fluid className='px-4'>
                {addedRealizations.map((addedRealization, index) => (
                    <React.Fragment key={uuidv4()}>
                        <AddedRealization
                            index={index + 1}
                            lastRealizationIndex={lastRealizationIndex}
                            realization={addedRealization}
                            orderId={orderId}
                            handleAddRealization={handleAddRealization}
                            realizationsToAdd={realizationsToAdd}
                        />
                        <StyledLine />
                    </React.Fragment>
                ))}
                <RealizationToAddList
                    index={realizations.length}
                    handleAddRealization={handleAddRealization}
                    realizationsToAdd={realizationsToAdd}
                    orderId={orderId}
                    orderItemId={orderItemId}
                />
            </StyledLayout>
        </SpinnerMask>
    );
};
