import { api } from './shared/instance';
import { Notification } from './shared/types';

const baseUrl = 'Notifications';

export const getNotificationsRequest = async () => {
    return api.get<Notification[]>(`${baseUrl}`);
};

export const deleteNotificationRequest = async (notificationId: number) => {
    return api.delete(`${baseUrl}/${notificationId}`);
};
