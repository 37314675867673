import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    createRealizationRequest,
    deleteRealizationRequest,
    editRealizationRequest,
    getOrdersRequest,
    getOrderRequest,
} from 'api/supplier';
import { AxiosError } from 'axios';
import { prepareErrorType } from 'components/shared/LocalError/helpers';
import { AppError } from 'store/types';
import { normalize } from 'normalizr';
import { orderEntity } from '../schemas';
import { OrderRealization, OrdersRequestInput } from 'api/shared/types';

export const getOrders = createAsyncThunk(
    'supplier/getOrders',
    async (input: OrdersRequestInput, { rejectWithValue }) => {
        try {
            const { data } = await getOrdersRequest(input);
            const normalized = normalize(
                data.items.map((e, _id) => ({ ...e, _id })),
                [orderEntity]
            );
            return {
                totalOrders: data.total,
                data: normalized.entities,
            };
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);

export type CreateOrderRealization = OrderRealization & {
    orderId: number;
};

export const createRealization = createAsyncThunk(
    'supplier/createRealization',
    async (input: CreateOrderRealization, { rejectWithValue }) => {
        try {
            await createRealizationRequest(input);
            const { data } = await getOrderRequest(input.orderId);
            const normalized = normalize(data, orderEntity);
            return {
                newRealizations: normalized.entities.realizations,
                newOrderItems: normalized.entities.orderItems,
            };
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);

export const editRealization = createAsyncThunk(
    'supplier/editRealization',
    async ({ orderId, orderItemId, ...rest }: CreateOrderRealization, { rejectWithValue }) => {
        try {
            await editRealizationRequest({ ...rest, orderItemId });

            const { data } = await getOrderRequest(orderId);
            const normalized = normalize(data, orderEntity);
            return {
                newRealizations: normalized.entities.realizations,
                newOrderItems: normalized.entities.orderItems,
            };
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);

export const deleteRealization = createAsyncThunk(
    'supplier/deleteRealization',
    async (
        { realizationId, orderId }: { realizationId: number; orderId: number },
        { rejectWithValue }
    ) => {
        try {
            await deleteRealizationRequest(realizationId);
            const { data } = await getOrderRequest(orderId);
            const normalized = normalize(data, orderEntity);
            return {
                realizationId,
                newOrderItems: normalized.entities.orderItems,
            };
        } catch (error: unknown | AxiosError | Error) {
            const appError: AppError = {
                error,
                type: prepareErrorType(error),
            };
            return rejectWithValue(appError);
        }
    }
);
