import React, { forwardRef } from 'react';
import { Button, Stack } from 'react-bootstrap';
import styled from 'styled-components';
import { RotatedImg } from 'styles/styled-components';

type Size = 'xs' | 'sm' | 'lg' | 'xl';

const getWidthBySize = (size: Size) => {
    switch (size) {
        case 'xs':
            return '20px';
        case 'sm':
            return '30px';
        case 'lg':
            return '45px';
        case 'xl':
            return '65px';
    }
    return 0;
};

const IconWrapper = styled.span<{ size: Size }>`
    width: ${props => getWidthBySize(props.size)};
    position: relative;
`;

interface StyledButtonProps {
    $isRow?: boolean;
    $stopClickable: boolean;
}

export const StyledButton = styled(Button)<StyledButtonProps>`
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: 0;
    padding: 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: ${props => (props.$isRow ? 'row' : 'column')};
    align-items: center;
    ${props => (props.$stopClickable ? 'pointer-events:none' : '')};

    &:disabled {
        background-color: transparent;
        border: none;
    }

    &:focus {
        background-color: transparent;
        border: none;
        box-shadow: none;
        outline: 0;
    }

    &:hover {
        background-color: transparent;
        border: none;
        box-shadow: none;
        outline: 0;
    }

    &:active {
        background-color: transparent;
        border: none;
        box-shadow: none;
        outline: 0;
    }
`;

interface IconButtonCommonProps {
    image: string;
    onClick?: () => void;
    size?: Size;
    disabled?: boolean;
    rotation?: number;
    stopClickable?: boolean;
    overlay?: React.ReactNode;
    ariaLabel?: string;
    dataCy?: string;
}

type IconButtonProps =
    | (IconButtonCommonProps & {
          children?: React.ReactNode;
      })
    | (IconButtonCommonProps & {
          children: React.ReactNode;
          isRow: true;
          isIconToLeft?: boolean;
      });

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const IconButton = forwardRef<HTMLDivElement, IconButtonProps>((props, ref) => {
    const {
        size = 'sm',
        image,
        onClick,
        children,
        disabled = false,
        rotation = 0,
        stopClickable = false,
        overlay,
        ariaLabel,
        dataCy
    } = props;

    if ('isRow' in props && props.isRow) {
        const isIconToLeft = props.isIconToLeft ?? true;
        return (
            <StyledButton
                className='shadow-none'
                disabled={disabled}
                ref={ref}
                onClick={onClick}
                data-cy={dataCy}
                $isRow={true}
                $stopClickable={stopClickable}
                aria-label={ariaLabel}
            >
                <Stack gap={3} direction='horizontal'>
                    {!isIconToLeft && children}
                    <IconWrapper size={size}>
                        <RotatedImg src={image} $rotation={rotation} className='img-responsive' />
                        {overlay && overlay}
                    </IconWrapper>
                    {isIconToLeft && children}
                </Stack>
            </StyledButton>
        );
    }
    return (
        <StyledContainer ref={ref}>
            <StyledButton
                className='shadow-none'
                onClick={onClick}
                disabled={disabled}
                $stopClickable={stopClickable}
                aria-label={ariaLabel}
                data-cy={dataCy}
            >
                <IconWrapper size={size}>
                    <RotatedImg src={image} $rotation={rotation} className='img-responsive' />
                    {overlay && overlay}
                </IconWrapper>
            </StyledButton>
            {children && <>{children}</>}
        </StyledContainer>
    );
});

IconButton.displayName = 'IconButton';
