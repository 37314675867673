import { useField } from 'formik';
import React, { HTMLInputTypeAttribute } from 'react';
import { Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import variables from 'styles/variables.module.scss';

export const StyledInput = styled(Form.Control)<{
    $variant: 'primary' | 'transparent';
    $height?: string;
}>`
    border-radius: 40px;
    height: ${props => (props.$height ? props.$height : variables.defaultElementHeight)};
    background-color: ${props => (props.$variant === 'transparent' ? 'transparent' : 'white')};
    border: ${props =>
        props.$variant === 'transparent'
            ? `1px solid ${variables.darkMainColor}`
            : '1px solid #ced4da'};
`;

const StyledLabel = styled(Form.Label)<{ $variant: 'primary' | 'transparent' }>`
    font-size: 1vw;
    color: ${props => (props.$variant === 'transparent' ? variables.darkMainColor : 'black')};
    font-weight: ${props => (props.$variant === 'transparent' ? 600 : 400)};
`;

const StyledFormGroup = styled(Form.Group)`
    border-radius: 40px;
`;

interface InputProps {
    name: string;
    label?: string;
    placeholder?: string;
    type?: HTMLInputTypeAttribute;
    variant?: 'primary' | 'transparent';
    height?: string;
    dataCy?: string;
}

export const Input: React.FC<InputProps> = props => {
    const { name, label, type = 'text', variant = 'primary', height, placeholder, dataCy } = props;
    const [field, meta] = useField(name);
    let optionalProps = {};
    if (type === 'number') {
        optionalProps = { min: 0 };
    }
    return (
        <StyledFormGroup as={Row}>
            {label && (
                <StyledLabel $variant={variant} htmlFor={name}>
                    {label}
                </StyledLabel>
            )}
            <StyledInput
                id={name}
                {...field}
                type={type}
                isInvalid={!!meta.error}
                $variant={variant}
                $height={height}
                {...optionalProps}
                placeholder={placeholder}
                data-cy={dataCy}
            />
            {meta.touched && meta.error && (
                <Form.Control.Feedback type='invalid'>{meta.error}</Form.Control.Feedback>
            )}
        </StyledFormGroup>
    );
};
